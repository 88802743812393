import React, { Component } from "react";
import {
  EditorState,
  AtomicBlockUtils,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import Editor, { createEditorStateWithText } from "draft-js-plugins-editor";
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "draft-js-mention-plugin";
import "./editorStyles.css";
import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js-hashtag-plugin/lib/plugin.css";
import "draft-js-emoji-plugin/lib/plugin.css";
import "draft-js-linkify-plugin/lib/plugin.css";
import createImagePlugin from "draft-js-image-plugin";
import createHashtagPlugin from "draft-js-hashtag-plugin";
import createEmojiPlugin from "draft-js-emoji-plugin";
import createLinkifyPlugin from "draft-js-linkify-plugin";
import createHighlighPlugin from "./highlighPlugin";
import { GetMentionName } from "../../../api/UserAPI";
import { getLocalToken } from "../../../context/AuthContext";
import { mySwalAlertFailed } from "../../../components/Misc";
import { REST_API_URL } from "../../../components/ConstantAPI";
import createDndFileUploadPlugin from "@mikeljames/draft-js-drag-n-drop-upload-plugin";
import mockUpload from "./mockUpload";
import debounce from "lodash/debounce";

export default class Project extends Component {
  constructor(props) {
    super(props);

    this.mentionPlugin = createMentionPlugin();
    this.imagePlugin = createImagePlugin();
    this.hashtagPlugin = createHashtagPlugin();
    this.emojiPlugin = createEmojiPlugin();
    this.dndFileUploadPlugin = createDndFileUploadPlugin({
      handleUpload: () => {},
      // handleUpload: (data, success, failed, progress) => {
      //   console.log(data);
      //   console.log(success);
      //   console.log(failed);
      //   console.log(progress);
      // },
      // mockUpload(data, success, failed, progress),
      // addImage: this.imagePlugin.addImage,
      addImage: (editorState, file) => {
        return this.insertDroppedFile(editorState, file);
        // return this.imagePlugin.addImage(editorState, file);
        // return this.insertImageTest(editorState, file);
      },
    });
    this.linkifyPlugin = createLinkifyPlugin({
      component: (props) => (
        // eslint-disable-next-line no-alert, jsx-a11y/anchor-has-content
        <a
          href={props.href}
          {...props}
          onClick={() => window.open(props.href, "_blank")}
        />
      ),
    });
    this.highlightPlugin = createHighlighPlugin();

    const content = window.localStorage.getItem("content");

    if (content) {
      this.state.editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(content))
      );
    } else {
      this.state.editorState = EditorState.createEmpty();
    }
  }

  state = {
    editorState: createEditorStateWithText(""),
    suggestions: [],
  };

  onChange = (editorState) => {
    const contentState = editorState.getCurrentContent();
    this.saveContent(contentState);

    this.setState({
      editorState,
    });
  };

  saveContent = debounce((content) => {
    console.log(convertToRaw(content));
    window.localStorage.setItem(
      "content",
      JSON.stringify(convertToRaw(content))
    );
  }, 1000);

  onSearchChange = ({ value }) => {
    GetMentionName(this.doGetDataCB.bind(this), {}, getLocalToken(), value);
  };

  doGetDataCB(jsonObj, value) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      this.setState({
        suggestions: defaultSuggestionsFilter(value, temp),
      });
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  onAddMention = () => {
    // get the mention object selected
  };

  focus = () => {
    this.editor.focus();
  };

  handlePastedFiles(files) {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("Username", "KarHong");
    formData.append("Folder", "FolderA");
    fetch(REST_API_URL + "/users/upload", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.file);
        if (data.file) {
          this.setState({
            editorState: this.insertImage(data.file),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  insertDroppedFile(editorState, file) {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "IMMUTABLE",
      { src: file }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, file);
  }

  insertImageTest(editorState, url) {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  }

  insertImage(url) {
    const contentState = this.state.editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(this.state.editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  }

  handleDroppedFiles(selection, files) {
    const filteredFiles = files.filter(
      (file) => file.type.indexOf("image/") === 0
    ); // <-- [1]

    console.log("handleDroppedFiles");
    console.log(files[0]);
    console.log(selection);
  }

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const { EmojiSuggestions, EmojiSelect } = this.emojiPlugin;
    const plugins = [
      this.mentionPlugin,
      this.imagePlugin,
      this.hashtagPlugin,
      this.emojiPlugin,
      this.dndFileUploadPlugin,
      this.linkifyPlugin,
      this.highlightPlugin,
    ];

    return (
      <div id="main-content">
        <div className="editor" onClick={this.focus}>
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            handlePastedFiles={this.handlePastedFiles.bind(this)}
            // handleDroppedFiles={this.handleDroppedFiles.bind(this)}
            plugins={plugins}
            ref={(element) => {
              this.editor = element;
            }}
          />
          <EmojiSuggestions />
          <MentionSuggestions
            onSearchChange={this.onSearchChange}
            suggestions={this.state.suggestions}
            // onAddMention={this.onAddMention}
          />
        </div>
        <div className="options">
          <EmojiSelect />
        </div>
      </div>
    );
  }
}
