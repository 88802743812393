import React, { useState, useRef } from "react";
import {
  GetAllProjectSetting,
  CreateProjectSetting,
  DeleteProjectSetting,
  GetProjectSetting,
} from "../../../api/AdminAPI";
import { getLocalToken, getLocalUsername } from "../../../context/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { textValidation } from "../../../components/Validation";
import { mySwalAlertFailed, loadingpage } from "../../../components/Misc";
import { Modal, Button, Form } from "react-bootstrap";
import Pagination from "react-js-pagination";

const ProjectStatus = (props) => {
  const [searchCode, setSearchCode] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);
  const [IsUpdate, setIsUpdate] = useState(false);
  const [data, setData] = React.useState([]);
  const MySwal = withReactContent(Swal);
  const [projectName, setProjectName] = useState("");
  const [dialogHeader, setDialogHeader] = useState("Create Setting");
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [sequence, setSequence] = useState(0.0);
  const [nameInvalid, setNameInvalid] = React.useState("");
  const [codeInvalid, setCodeInvalid] = React.useState("");
  const [sequenceInvalid, setSequenceInvalid] = React.useState("");
  const [showDialog, setShowDialog] = React.useState(false);
  const [totalPages, setTotalPages] = React.useState(0);
  const [itemCountPerPage, setItemCountPerPage] = React.useState(50);
  const [firstTime, setFirstTime] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const codeRef = useRef();

  React.useEffect(() => {
    GetSettings();
  }, []);

  React.useEffect(() => {
    if (
      codeRef.current !== undefined &&
      codeRef.current !== null &&
      firstTime === 0
    ) {
      codeRef.current.focus();
      setFirstTime(1);
    }
  });

  //hook to check selectedPage changed before refresh
  React.useEffect(() => {
    GetSettings();
  }, [selectedPage]);

  const handleClose = () => {
    setShowDialog(false);
    resetDialogData();
  };

  function GetSettings() {
    setIsLoading(true);
    GetAllProjectSetting(
      doGetDataCB.bind(this),
      {
        FilterCode: searchCode,
        SelectedPage: selectedPage,
      },
      getLocalToken()
    );
  }

  function doGetDataCB(jsonObj) {
    setIsLoading(false);
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      setTotalPages(itemCountPerPage * jsonObj.page); //some kind of hack so that API doesn't need to return total count
      setData(temp);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function OnCreateSetting() {
    var nameInv = textValidation("Name", name, 2);
    var codeInv = textValidation("Code", code, 2);
    setNameInvalid(nameInv);
    setCodeInvalid(codeInv);

    if (nameInv === null && codeInv === null) {
      CreateProjectSetting(
        doCreateCB.bind(this),
        {
          StatusCode: code,
          StatusName: name,
          Sequence: sequence === "" ? "0" : sequence,
          IsUpdate: IsUpdate,
          UpdatedBy: getLocalUsername(),
        },
        getLocalToken()
      );
    }
  }

  function doCreateCB(jsonObj) {
    if (jsonObj.message === "Success") {
      resetDialogData();
      GetSettings();
      //dismiss the dialog
      handleClose();
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function resetDialogData() {
    setName("");
    setCode("");
    setSequence(0);
    setProjectName("");
  }

  function onEdit(e, usrn) {
    GetProjectSetting(
      doEditCB.bind(this),
      {
        FilterCode: usrn,
      },
      getLocalToken()
    );
  }

  function doEditCB(jsonObj) {
    console.log(jsonObj);
    if (jsonObj.message === "Success") {
      setName(jsonObj.data.statusName);
      setCode(jsonObj.data.statusCode);
      setSequence(jsonObj.data.sequence);
      setDialogHeader("Edit Status");
      setShowDialog(true);
      setIsUpdate(true);
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function onDelete(e, usrn) {
    MySwal.fire({
      title: "Confirmation",
      text: "Are you sure you want to delete this setting ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        DeleteProjectSetting(
          doDeleteCB.bind(this),
          {
            StatusCode: usrn,
          },
          getLocalToken()
        );
      }
    });
  }

  function doDeleteCB(jsonObj) {
    console.log(jsonObj);
    if (jsonObj.message === "Success") {
      GetSettings();
      return MySwal.fire({
        title: "Deleted!",
        text: "Your setting has been deleted.",
        type: "success",
        confirmButtonText: "OK",
      });
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    setSelectedPage(pageNumber);
  }

  function renderTableData() {
    return data.map((settings) => {
      const {
        Id,
        Number,
        StatusCode,
        StatusName,
        UpdatedBy,
        Sequence,
      } = settings;

      return (
        <tr key={Id}>
          <td>
            <span>{Number}</span>
          </td>
          <td>
            <span>{StatusName}</span>
          </td>
          <td>
            <span>{Sequence}</span>
          </td>
          <td>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary"
              title="Edit"
              onClick={(e) => onEdit(e, StatusCode)}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-danger js-sweetalert"
              title="Delete"
              data-type="confirm"
              onClick={(e) => onDelete(e, StatusCode)}
            >
              <i className="fa fa-trash-o"></i>
            </button>
          </td>
        </tr>
      );
    });
  }

  return (
    <div id="main-content">
      {/* {isLoading ? loadingpage() : ""} */}
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2>Project Status</h2>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="header">
                <ul className="header-dropdown">
                  <li>
                    <a
                      // href=";"
                      className="btn btn-info"
                      // data-toggle="modal"
                      // data-target="#addcontact"
                      onClick={() => {
                        setDialogHeader("Create New Status");
                        setShowDialog(true);
                        setIsUpdate(false);
                      }}
                    >
                      Create New Status
                    </a>
                  </li>
                </ul>
              </div>
              <div className="body">
                <div className="table-responsive">
                  <table className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list">
                    <thead className="thead-dark">
                      <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Sequence</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderTableData()}</tbody>
                  </table>
                  <div>
                    {data.length === 0 ? (
                      <div>No Records</div>
                    ) : (
                      <div>
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={selectedPage}
                          itemsCountPerPage={itemCountPerPage}
                          totalItemsCount={totalPages}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showDialog} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{dialogHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Code</Form.Label>
            <Form.Control
              placeholder="Code"
              ref={codeRef}
              value={code}
              disabled={IsUpdate}
              onChange={(e) => {
                setCodeInvalid("");
                setCode(e.target.value);
              }}
            />
            <span style={{ color: "red" }}>{codeInvalid}</span>
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Name</Form.Label>
            <Form.Control
              placeholder="Name"
              value={name}
              onChange={(e) => {
                setNameInvalid("");
                setName(e.target.value);
              }}
            />
            <span style={{ color: "red" }}>{nameInvalid}</span>
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Sequence</Form.Label>
            <Form.Control
              placeholder="Display Sequence"
              value={sequence}
              onChange={(e) => {
                setSequenceInvalid("");

                const re = /^[0-9\b]+$/;
                if (
                  re.test(e.target.value) ||
                  (e.target.value.includes(".") &&
                    e.target.value.split(".").length <= 2) ||
                  e.target.value === ""
                ) {
                  setSequence(e.target.value);
                }
              }}
            />
            <span style={{ color: "red" }}>{sequenceInvalid}</span>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={OnCreateSetting}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProjectStatus;
