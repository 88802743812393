import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { ResetPasswordMethod, UpdateNewPassword } from "../../api/UserAPI";
import queryString from "query-string";
import { mySwalAlertFailed, mySwalAlertSuccess } from "../../components/Misc";
import { useAuth, setLocalRole } from "../../context/AuthContext";

const ResetPassword = (props) => {
  const [backToHome, setRedirectBackToHome] = React.useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = React.useState("");
  const { setAuthTokens } = useAuth();

  React.useEffect(() => {
    let params = queryString.parse(window.location.search);
    let verificationCode = params.verificationCode;
    let username = params.username;
    setUsername(username);
    ValidateResetCode(username, verificationCode);
  });

  function ValidateResetCode(username, veriCode) {
    ResetPasswordMethod(doGetDataCB, {
      Username: username,
      ResetPassword: veriCode,
    });
  }

  function setUpdatePassword() {
    if (password !== confirmPassword) {
      mySwalAlertFailed("Your Password and Confirm Password does not match");
      return;
    }

    UpdateNewPassword(doGetUpdatePasswordCB, {
      Username: username,
      Password: password,
    });
  }

  function doGetUpdatePasswordCB(jsonObj) {
    if (jsonObj.message === "Success") {
      mySwalAlertSuccess("Update Successfully");

      setAuthTokens();
      setLocalRole("undefined");

      setRedirectBackToHome(true);
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function doGetDataCB(jsonObj) {
    if (jsonObj.message !== "Success") {
      //mySwalAlertFailed(jsonObj.message);
      setRedirectBackToHome(true);
    }
  }

  if (backToHome) {
    return <Redirect to="/" />;
  } else {
    return (
      <div>
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div className="top">
                <img src="../assets/images/login.png" alt="Brainy" />
              </div>
              <div className="card">
                <div class="header">
                  <p class="lead">Change Password</p>
                </div>
                <div className="body">
                  <div className="form-group">
                    <label for="signin-email">New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="signin-email"
                      placeholder="New Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label for="signin-email">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="signin-email"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    class="btn btn-primary btn-lg btn-block"
                    onClick={() => setUpdatePassword()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ResetPassword;
