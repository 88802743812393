// export const REST_API_URL = "http://localhost:9708"; //desktop
// export const REST_API_URL = "http://localhost:9706";//macbook
// export const REST_API_URL = "https://testapi.brainyentertainment.com";//brainy TEST SITE
export const REST_API_URL = "https://apiservice.brainyentertainment.com"; //brainy LIVE SITE
// export const REST_API_URL = "http://apiservicebypass.brainyentertainment.com"; //brainy LIVE SITE [bypass] Failed as brainy hosted in https - <URL>'. This request has been blocked; the content must be served over HTTPS
export const FILES_API_URL = "https://filesapiservice.brainyentertainment.com";
// export const FILES_API_URL = "http://localhost:9708";//desktop
export const FILES_BASE_URL = "uploads";
export const WEBSITE_URL = "https://www.brainyentertainment.com";
export const DEFAULT_IMAGE =
  "https://filesapiservice.brainyentertainment.com/uploads/admin/defaultimage.jpeg";

export const doingTasks = [
  { id: 1, text: "Prepare 2019 Financial" },
  { id: 2, text: "Prepare 2019 Marketing Plan" },
  { id: 3, text: "Update Personnel Files" },
  {
    id: 4,
    text: "Review Health Insurance Options Under the Affordable Care Act",
  },
];

export const plannedTasks = [
  { id: 5, text: "New Brochures" },
  { id: 6, text: "2019 Brochure Designs" },
  { id: 7, text: "Brochure Design Review" },
  { id: 8, text: "Website Re-Design Plan" },
  { id: 9, text: "Rollout of New Website and Marketing Brochures" },
  { id: 10, text: "Create 2018 Sales Report" },
  { id: 11, text: "Direct vs Online Sales Comparison Report" },
  { id: 12, text: "Review 2018 Sales Report and Approve 2019 Plans" },
  { id: 13, text: "Submit Signed NDA" },
  { id: 14, text: "Update Revenue Projections" },
  { id: 15, text: "Review Revenue Projections" },
  { id: 16, text: "Comment on Revenue Projections" },
  { id: 17, text: "Scan Health Insurance Forms" },
  { id: 18, text: "Sign Health Insurance Forms" },
  { id: 19, text: "Follow up with West Coast Stores" },
  { id: 20, text: "Follow up with East Coast Stores" },
  { id: 21, text: "Submit Refund Report for 2019 Recall" },
  { id: 22, text: "Give Final Approval for Refunds" },
  { id: 23, text: "Prepare Product Recall Report" },
  { id: 24, text: "Review Product Recall Report by Engineering Team" },
  { id: 25, text: "Review Training Course for any Ommissions" },
  { id: 26, text: "Review Overtime Report" },
  { id: 27, text: "Submit Overtime Request Forms" },
  { id: 28, text: "Overtime Approval Guidelines" },
  { id: 29, text: "Create Report on Customer Feedback" },
  { id: 30, text: "Review Customer Feedback Report" },
];
