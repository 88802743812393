import React, { useState, useRef } from "react";
import { GetAllUsers, CreateUser, DeleteUser } from "../../../api/AdminAPI";
import { GetUserInfoByUsername, UploadImage } from "../../../api/UserAPI";
import { getLocalToken, getLocalUsername } from "../../../context/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  textValidation,
  emailValidation,
  numberValidation,
} from "../../../components/Validation";
import {
  mySwalAlertFailed,
  baseURL,
  getArrRoles,
  getArrTeams,
  getArrEmploymentStatus,
  loadingpage,
  massageFileName,
} from "../../../components/Misc";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import "../../../custom.css";
import moment from "moment";
import Pagination from "react-js-pagination";
import {
  REST_API_URL,
  FILES_API_URL,
  FILES_BASE_URL,
  DEFAULT_IMAGE,
} from "../../../components/ConstantAPI";
import "./Employee.css";
import Datetime from "react-datetime";

const Employee = (props) => {
  const [searchUsername, setSearchUsername] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);
  const [data, setData] = React.useState([]);
  const MySwal = withReactContent(Swal);
  const [username, setUsername] = useState("");
  const [dialogHeader, setDialogHeader] = useState("Create Employee");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [salary, setSalary] = useState(0);
  const [travelAllowance, setTravelAllowance] = useState(0);
  const [onboardingDate, setOnboardingDate] = useState(new Date());
  const [arrTeam, setArrTeam] = React.useState([]);
  const [selectedTeam, setSelectedTeam] = React.useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [arrEmployementStatus, setArrEmployementStatus] = React.useState([]);
  const [selectedEmpStatus, setSelectedEmpStatus] = React.useState("");
  const [arrRoles, setArrRoles] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState("");
  const [nameInvalid, setNameInvalid] = React.useState("");
  const [emailInvalid, setEmailInvalid] = React.useState("");
  const [phoneInvalid, setPhoneInvalid] = React.useState("");
  const [onboardingInvalid, setOnboardingInvalid] = React.useState("");
  const [jobTitleInvalid, setJobTitleInvalid] = React.useState("");
  const [salaryInvalid, setSalaryInvalid] = React.useState("");
  const [allowanceInvalid, setAllowanceInvalid] = React.useState("");
  const [showDialog, setShowDialog] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const [alias, setAlias] = useState("");
  const [course, setCourse] = useState("");
  const [college, setCollege] = useState("");
  const [interest, setInterest] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDOB] = useState(new Date("2000-01-01"));
  const [activePage, setActivePage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [itemCountPerPage, setItemCountPerPage] = React.useState(50);
  const [picImage, setPicImage] = React.useState("");
  const [picObject, setPicObject] = React.useState(null);
  const [shortNameInvalid, setShortNameInvalid] = React.useState("");
  const [shortName, setShortName] = React.useState("");
  const [firstTime, setFirstTime] = React.useState(0);
  const focusRef = useRef();
  const hiddenFileInput = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    GetAllUser();

    let arr = getArrRoles();
    if (arr.length !== 0 && setSelectedRole !== "") {
      setSelectedRole(arr[0].label);
    }
    setArrRoles(arr);

    arr = getArrTeams();
    if (arr.length !== 0 && setSelectedTeam !== "") {
      setSelectedTeam(arr[0].label);
    }
    setArrTeam(arr);

    arr = getArrEmploymentStatus();
    if (arr.length !== 0 && setArrEmployementStatus !== "") {
      setSelectedEmpStatus(arr[0].label);
    }
    setArrEmployementStatus(arr);
  }, [selectedPage]);

  React.useEffect(() => {
    if (
      focusRef.current !== undefined &&
      focusRef.current !== null &&
      firstTime === 0
    ) {
      focusRef.current.focus();
      setFirstTime(1);
    }
  });

  const handleClose = () => {
    setShowDialog(false);
    resetDialogData();
  };

  const handleSelectRole = (e) => {
    setSelectedRole(e);
  };

  const handleSelectTeam = (e) => {
    setSelectedTeam(e);
  };

  const handleSelectEmploymentStatus = (e) => {
    setSelectedEmpStatus(e);
  };

  function GetAllUser() {
    setIsLoading(true);

    GetAllUsers(
      doGetDataCB.bind(this),
      {
        FilterUsername: searchUsername,
        SelectedPage: selectedPage,
      },
      getLocalToken()
    );
  }

  function doGetDataCB(jsonObj) {
    setIsLoading(false);

    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      setTotalPages(itemCountPerPage * jsonObj.page); //some kind of hack so that API doesn't need to return total count

      setData(temp);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function OnCreateUser() {
    var onboardDT = moment(onboardingDate).format("MM/DD/yyyy"); ////to set datepicker, need use this format + DB take this format
    var nameInv = textValidation("Name", name, 3);
    var emailInv = emailValidation(email);
    var phoneInv = textValidation("Phone", phone, 9);
    var jobTitleInv = textValidation("Job Title", jobTitle, 1);
    var salaryInv = numberValidation("Salary", salary);
    var allowanceInv = numberValidation("Allowance", travelAllowance);

    // var shortNameInv = textValidation("Preferred Name", shortName, 1);

    var onboardingInv = onboardDT === "Invalid date" ? "Invalid date" : null;

    setNameInvalid(nameInv);
    setEmailInvalid(emailInv);
    setPhoneInvalid(phoneInv);
    setOnboardingInvalid(onboardingInv);
    setJobTitleInvalid(jobTitleInv);
    setSalaryInvalid(salaryInv);
    setAllowanceInvalid(allowanceInv);
    // setShortNameInvalid(shortNameInv);

    if (
      nameInv === null &&
      emailInv === null &&
      phoneInv === null &&
      jobTitleInv === null
      // shortNameInv === null
    ) {
      //create/update also call this. The API will decide whether to create/update based on Username

      if (picObject !== null) {
        uploadDroppedFilesToServer(picObject, name);
      } else {
        CreateNewAccount("");
      }
    }
  }

  function CreateNewAccount(filePath) {
    var onboardDT = moment(onboardingDate).format("MM/DD/yyyy"); //to set datepicker, need use this format + DB take this format
    // var onboardDT = moment(onboardingDate).format("DD/MM/yyyy"); //Windows when debugging local needed this

    var dobDT =
      dob === "" || dob === null
        ? new Date("2000-01-01")
        : moment(dob).format("MM/DD/yyyy");
    // : moment(dob).format("DD/MM/yyyy"); //Windows when debugging local needed this
    CreateUser(
      doCreateUserCB.bind(this),
      {
        Username: username,
        Name: name,
        Email: email,
        Phone: phone,
        RoleString: selectedRole,
        Admin: getLocalUsername(),
        Salary: salary,
        TravelAllowance: travelAllowance,
        OnBoardingDate: onboardDT,
        Team: selectedTeam,
        JobTitle: jobTitle,
        Photo: filePath,
        EmploymentStatus: selectedEmpStatus,
        Alias: alias,
        DOBString: dobDT,
        Course: course,
        College: college,
        Interest: interest,
        Address: address,
        ShortName: "",
      },
      getLocalToken()
    );
  }

  function doCreateUserCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      resetDialogData();
      console.log(jsonObj.queryMessage);
      GetAllUser();
      //dismiss the dialog
      handleClose();
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function resetDialogData() {
    setName("");
    setPhone("");
    setEmail("");
    setUsername("");
    // setShortName("");
    setUrl("");
    setSalary(0);
    setPicImage("");
    setTravelAllowance(0);
    setOnboardingDate(new Date());
    setJobTitle("");
    setSelectedRole(arrRoles[0].label);
    setSelectedTeam(arrTeam[0].label);
    setSelectedEmpStatus(arrEmployementStatus[0].label);
    setAlias("");
    setDOB("");
    setCourse("");
    setCollege("");
    setInterest("");
    setAddress("");
    setPicObject(null);

    resetInvalidMessage();
  }

  function resetInvalidMessage() {
    setNameInvalid("");
    setEmailInvalid("");
    setPhoneInvalid("");
    setOnboardingInvalid("");
    setJobTitleInvalid("");
    setSalaryInvalid("");
    setAllowanceInvalid("");
    // setShortNameInvalid('');
  }

  function onEdit(e, usrn) {
    GetUserInfoByUsername(
      doEditUserCB.bind(this),
      {
        Username: usrn,
        Admin: getLocalUsername(),
      },
      getLocalToken()
    );
  }

  function doEditUserCB(jsonObj) {
    //console.log(jsonObj);
    if (jsonObj.queryMessage === "Success") {
      setName(jsonObj.name);
      setPhone(jsonObj.phone);
      setEmail(jsonObj.email);
      setUsername(jsonObj.username);
      // setShortName(jsonObj.shortName);
      setSelectedRole(jsonObj.role);
      setDialogHeader("Edit Employee");
      setUrl(`${baseURL()}/firstsignup?guid=${jsonObj.guid}`);
      setSalary(jsonObj.salary);
      setTravelAllowance(jsonObj.travelAllowance);
      setOnboardingDate(moment(jsonObj.onBoardingDate).toDate());
      setSelectedTeam(jsonObj.team);
      setJobTitle(jsonObj.jobTitle);
      setSelectedEmpStatus(jsonObj.employmentStatus);
      setAlias(jsonObj.alias);
      setDOB(jsonObj.dob === null ? "" : moment(jsonObj.dob).toDate());
      setCourse(jsonObj.course);
      setCollege(jsonObj.college);
      setInterest(jsonObj.interest);
      setAddress(jsonObj.address);
      setShowDialog(true);
      setPicImage(
        jsonObj.photo !== undefined && jsonObj.photo !== ""
          ? FILES_API_URL + jsonObj.photo
          : ""
      );
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function onDelete(e, usrn) {
    MySwal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this data!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        //If user press DELETE
        DeleteUser(
          doDeleteUserCB.bind(this),
          {
            Username: usrn,
            Admin: getLocalUsername(),
          },
          getLocalToken()
        );
      }
    });
  }

  function doDeleteUserCB(jsonObj) {
    console.log(jsonObj);
    if (jsonObj.queryMessage === "Success") {
      GetAllUser();
      //After deleted only return below MySwal
      return MySwal.fire({
        title: "Deleted!",
        text: "Your data has been deleted.",
        type: "success",
        confirmButtonText: "OK",
      });
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function renderTableData() {
    return data.map((user) => {
      const {
        ID,
        Username,
        CreatedOn,
        FirstName,
        Phone,
        Photo,
        Alias,
        Birthday,
        Email,
        Salary,
      } = user;
      let staffPic = FILES_API_URL + Photo;

      if (Photo === "") {
        staffPic = "../assets/images/user-icon.png";
      }

      return (
        <tr key={ID}>
          <td className="width45">
            <img
              src={staffPic}
              className="rounded-circle avatar"
              alt=""
              onError={(e) => (
                (e.target.onerror = null), (e.target.src = DEFAULT_IMAGE)
              )}
            />
          </td>
          <td>
            <span>{Username}</span>
          </td>
          <td>
            <span>{FirstName}</span>
          </td>
          <td>{Alias}</td>
          <td>{Birthday}</td>
          <td>{Email}</td>
          <td>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary"
              title="Edit"
              onClick={(e) => onEdit(e, Username)}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-danger js-sweetalert"
              title="Delete"
              data-type="confirm"
              onClick={(e) => onDelete(e, Username)}
            >
              <i className="fa fa-trash-o"></i>
            </button>
          </td>
        </tr>
      );
    });
  }

  function handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    setSelectedPage(pageNumber);
  }

  function displayURL() {
    if (url !== "") {
      return (
        <Form.Group>
          {/* <Form.Text className="text-muted">{email}</Form.Text> */}
          <Button
            onClick={copyToClipboard}
            className="btn btn-success buttonMax"
          >
            Copy Link
          </Button>
        </Form.Group>
      );
    }
    return null;
  }

  function copyToClipboard(e) {
    navigator.clipboard.writeText(url);
  }

  function showImage(e) {
    setPicImage(URL.createObjectURL(e.target.files[0]));
    setPicObject(e.target.files[0]);
  }

  function uploadDroppedFilesToServer(file) {
    const formData = new FormData();
    formData.append("qqfile", file);
    formData.append("username", username.toLowerCase());
    formData.append("folder", "personal");
    formData.append("subfolder", "");
    fetch(FILES_API_URL + "/users/upload", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        var fullPath =
          "/" +
          FILES_BASE_URL +
          "/" +
          username.toLowerCase() +
          "/personal/" +
          massageFileName(file.name);

        CreateNewAccount(fullPath);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <div id="main-content">
      {/* {isLoading ? loadingpage() : ""} */}
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2>Employee List</h2>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="header">
                <ul className="header-dropdown">
                  <li>
                    <a
                      // href=";"
                      className="btn btn-info"
                      // data-toggle="modal"
                      // data-target="#addcontact"
                      onClick={() => {
                        setUsername("");
                        setDialogHeader("Create Employee");
                        setShowDialog(true);
                      }}
                    >
                      Create New Employee
                    </a>
                  </li>
                </ul>
              </div>
              <div className="body">
                <div className="table-responsive">
                  <table className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list">
                    <thead className="thead-dark">
                      <tr>
                        <th></th>
                        <th>Employee ID</th>
                        <th>Full Name</th>
                        <th>Alias</th>
                        <th>Birthday</th>
                        <th>Email</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderTableData()}</tbody>
                  </table>
                  {data.length === 0 ? (
                    <div>No Records</div>
                  ) : (
                    <div>
                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={selectedPage}
                        itemsCountPerPage={itemCountPerPage}
                        totalItemsCount={totalPages}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showDialog} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{dialogHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row clearfix">
            <div className="col-md-12">
              <div className="form-group">
                <Form.Label>Profile Photo</Form.Label>
                <br />
                <div className="flex-containerProfile">
                  <div>
                    <img
                      src={picImage}
                      style={{ width: 200 }}
                      onError={(e) => (
                        (e.target.onerror = null),
                        (e.target.src = DEFAULT_IMAGE),
                        (e.target.width = 200)
                      )}
                    />
                  </div>
                  <div>
                    <Form.Label style={{ fontSize: "12px", color: "grey" }}>
                      Your photo must be in square mode 1:1 aspect ratio with a
                      resolution of 1000×1000.
                    </Form.Label>
                    <br />
                    <Form.Label style={{ fontSize: "12px", color: "grey" }}>
                      Minimum 500x500.
                    </Form.Label>
                    <br />
                    <Button onClick={handleClick}>Upload Photo</Button>
                  </div>
                </div>
                <input
                  type="file"
                  name="file"
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    showImage(e);
                  }}
                />
                {/* <img
                    src={picImage}
                    style={{ width: 200 }}
                    onError={(e) => (
                      (e.target.onerror = null),
                      (e.target.src = DEFAULT_IMAGE),
                      (e.target.width = 200)
                    )}
                  /> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Label>Full Name</Form.Label>
                {""}
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  placeholder="Full Name"
                  name="search"
                  autoComplete={"" + Math.random()}
                  ref={focusRef}
                  value={name}
                  onChange={(e) => {
                    setNameInvalid("");
                    setName(e.target.value);
                  }}
                />
                <span style={{ color: "red" }}>{nameInvalid}</span>{" "}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Label>Preferred Name</Form.Label>
                <Form.Control
                  placeholder="Preferred Name"
                  value={alias}
                  onChange={(e) => {
                    setAlias(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Label>Contact No</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  placeholder="Contact No"
                  value={phone}
                  onChange={(e) => {
                    setPhoneInvalid("");
                    setPhone(e.target.value);
                  }}
                />
                <span style={{ color: "red" }}>{phoneInvalid}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Label>Email address</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => {
                    setEmailInvalid("");
                    setEmail(e.target.value);
                  }}
                />
                <span style={{ color: "red" }}>{emailInvalid}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Label>Salary</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="0"
                  value={salary}
                  onChange={(e) => {
                    setSalaryInvalid("");
                    setSalary(e.target.value);
                  }}
                />
                <span style={{ color: "red" }}>{salaryInvalid}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Label>Allowance</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="0"
                  value={travelAllowance}
                  onChange={(e) => {
                    setAllowanceInvalid("");
                    setTravelAllowance(e.target.value);
                  }}
                />
                <span style={{ color: "red" }}>{allowanceInvalid}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Label>On Boarding Date</Form.Label>
                <Datetime
                  value={onboardingDate}
                  placeholder="On Boarding Date"
                  dateFormat="DD/MM/yyyy"
                  closeOnSelect
                  timeFormat={false}
                  onChange={(e) => {
                    setOnboardingInvalid();
                    setOnboardingDate(e);
                  }}
                />

                <span style={{ color: "red" }}>{onboardingInvalid}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Label>Job Title</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  placeholder="Job Title"
                  value={jobTitle}
                  onChange={(e) => {
                    setJobTitleInvalid("");
                    setJobTitle(e.target.value);
                  }}
                />
                <span style={{ color: "red" }}>{jobTitleInvalid}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <Form.Label>Team</Form.Label>
                <Dropdown
                  id="dropdown-menu-align-right"
                  onSelect={handleSelectTeam}
                >
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="buttonMax"
                  >
                    {selectedTeam}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {arrTeam.map((child) => (
                      <Dropdown.Item eventKey={child.label} key={child.label}>
                        {child.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <Form.Label>Employment Status</Form.Label>
                <Dropdown
                  id="dropdown-menu-align-right"
                  onSelect={handleSelectEmploymentStatus}
                >
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="buttonMax"
                  >
                    {selectedEmpStatus}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {arrEmployementStatus.map((child) => (
                      <Dropdown.Item eventKey={child.label} key={child.label}>
                        {child.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <Form.Label>Role</Form.Label>
                <Dropdown
                  id="dropdown-menu-align-right"
                  onSelect={handleSelectRole}
                >
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="buttonMax"
                  >
                    {selectedRole}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {arrRoles.map((child) => (
                      <Dropdown.Item eventKey={child.label} key={child.label}>
                        {child.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="col-md-6">
              <Form.Label>Birthday</Form.Label>
              <Datetime
                value={dob}
                dateFormat="DD/MM/yyyy"
                closeOnSelect
                timeFormat={false}
                onChange={(e) => {
                  setDOB(e);
                }}
              />
            </div>

            {/* <div className="col-md-6">
                <div className="form-group">
                  <Form.Label>Interest</Form.Label>
                  <Form.Control
                    placeholder="Interest"
                    value={interest}
                    onChange={(e) => {
                      setInterest(e.target.value);
                    }}
                  />
                </div>
              </div> */}
            <div className="col-md-6">
              <div className="form-group">
                <Form.Label>College/University</Form.Label>
                <Form.Control
                  placeholder="College/University"
                  value={college}
                  onChange={(e) => {
                    setCollege(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Label>Course of Study</Form.Label>
                <Form.Control
                  placeholder="Course of Study"
                  value={course}
                  onChange={(e) => {
                    setCourse(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <Form.Label>Residential Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Residential Address"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {displayURL()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={OnCreateUser}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Employee;
