import React, { Component } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { createEditorStateWithText } from "draft-js-plugins-editor";
import "../SendMessage/editorStyles.css";
import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js-hashtag-plugin/lib/plugin.css";
import "draft-js-emoji-plugin/lib/plugin.css";
import "draft-js-linkify-plugin/lib/plugin.css";
import { GetAllPinMessageByProjectID, PinMessage } from "../../../api/UserAPI";
import { getLocalToken, getLocalUsername } from "../../../context/AuthContext";
import { mySwalAlertFailed, GetPeriodString } from "../../../components/Misc";
import { FILES_API_URL, DEFAULT_IMAGE } from "../../../components/ConstantAPI";
export default class PinMessageUI extends Component {
  intervalPinID;

  constructor(props) {
    super(props);

    const content = window.localStorage.getItem("content");

    if (content) {
      this.state.editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(content))
      );
    } else {
      this.state.editorState = EditorState.createEmpty();
    }
  }

  //when using MODAL POP UP
  componentWillMount() {
    clearTimeout(this.intervalPinID);
    this.retrievePinMessages();
  }

  componentWillUnmount() {
    clearTimeout(this.intervalPinID);

    this.setState = (state, callback) => {
      return;
    };
  }

  retrievePinMessages() {
    GetAllPinMessageByProjectID(
      this.doGetAllMessageDataCB.bind(this),
      {
        ProjectID: this.props.projectTaskID,
      },
      getLocalToken()
    );
  }

  doGetAllMessageDataCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);

      // console.log(temp);
      this.setState({
        data: temp,
      });
      // setData(temp);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }

    // call retrievePinMessages() again in 5 seconds
    this.intervalPinID = setTimeout(this.retrievePinMessages.bind(this), 1000);
  }

  state = {
    editorState: createEditorStateWithText(""),
    suggestions: [],
    isSendMessageDisable: false,
    data: [],
  };

  onChange = (editorState) => {
    // const contentState = editorState.getCurrentContent();
    // this.saveContent(contentState);

    this.setState({
      editorState,
    });
  };

  createMarkup(text) {
    return { __html: text };
  }

  isPinnedLink(ispinned, id) {
    if (ispinned === 1) {
      return (
        <a
          className="dropdown-item pinCSS"
          href="#"
          onClick={(e) => this.OnPinMessage(e, ispinned, id)}
        >
          Unpin from Top
        </a>
      );
    } else {
      return (
        <a
          className="dropdown-item pinCSS"
          href="#"
          onClick={(e) => this.OnPinMessage(e, ispinned, id)}
        >
          Pin to Top
        </a>
      );
    }
  }

  OnPinMessage(e, ispinned, id) {
    if (ispinned === 0) {
      ispinned = 1;
    } else {
      ispinned = 0;
    }

    console.log("OnPinMessage");
    PinMessage(
      this.doPinMessageCB.bind(this),
      {
        Username: getLocalUsername(),
        Id: id,
        IsPinned: ispinned,
      },
      getLocalToken()
    );
  }

  doPinMessageCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  renderMessageBox() {
    var onlyFirstCount = 0;

    return this.state.data.map((msg) => {
      const {
        ID,
        Username,
        Alias,
        Message,
        Photo,
        CreatedOn,
        Name,
        IsPinned,
        StaffPic,
      } = msg;

      var shortenName = Name.substring(0, 2);
      if (GetPeriodString(CreatedOn).includes("Today")) {
        onlyFirstCount += 1;
      }

      return (
        <li
          className={IsPinned == 1 ? "clearfix pinnedCSS" : "clearfix"}
          key={ID}
          style={{ marginBottom: "10px", marginLeft: "0px" }}
        >
          {/* {onlyFirstCount === 1 ? (
            <div class="media media-meta-day">Today</div>
          ) : (
            ""
          )} */}
          <div className="message-data" style={{ marginBottom: "0px" }}>
            <div className="flex-container" style={{ width: "26vw" }}>
              <div>
                <span className="message-data-time">
                  <div
                    class="btn-group hover_drop_down"
                    style={{
                      display: "inline",
                      marginLeft: "13px",
                      marginTop: "-10px",
                    }}
                  >
                    <button
                      class="buttonPublicMember btn-sm"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={FILES_API_URL + StaffPic}
                        alt="Publicmember"
                        style={{
                          height: "35px",
                          width: "35px",
                          borderRadius: "50%",
                          marginTop: "-8px",
                        }}
                        onError={(e) => (
                          (e.target.onerror = null),
                          (e.target.src = DEFAULT_IMAGE)
                        )}
                      />
                    </button>
                  </div>
                  {Alias}
                  <span style={{ fontSize: "9px" }}>
                    {" "}
                    {GetPeriodString(CreatedOn)}
                  </span>
                </span>
              </div>
              <div>
                <a
                  class="btn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ marginTop: "10px" }}
                >
                  <span class="fa fa-angle-down"></span>
                </a>
                <div class="dropdown-menu PinToTopCSS">
                  {this.isPinnedLink(IsPinned, ID)}
                </div>
              </div>
            </div>
          </div>
          <div class="media media-chat" style={{ marginTop: "-15px" }}>
            {" "}
            <div
              class="col-0 WholeColButton"
              style={{
                paddingLeft: "5px",
                paddingRight: "20px",
                fontSize: "18px",
                width: "10px",
                paddingTop: "0px",
              }}
            ></div>
            <div
              class="media-body"
              style={{
                fontWeight: "50",
                color: "red",
              }}
            >
              <div
                class="media-bodyZeroPadding"
                dangerouslySetInnerHTML={this.createMarkup(Message)}
                style={{ textAlign: "left" }}
              ></div>
            </div>
          </div>
        </li>
      );
    });
  }

  render() {
    return (
      // <div id="main-content">
      <div>
        <div
          className="chat"
          style={{
            marginLeft: 0,
            width: "100%",
            backgroundColor: "white",
            padding: "0px",
          }}
        >
          <div
            className="chat-history clearfixSendPinMessage"
            style={{
              padding: "0px",
              height: this.props.isMinResolution ? "180px" : "220px",
            }}
          >
            <ul className="m-b-0">{this.renderMessageBox()}</ul>
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.messagesEnd = el;
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}
