import React from "react";
import "./resultList.css";
import { useHistory } from "react-router-dom";
import { setLocalViewProjectRefresh } from "../../../context/AuthContext";
import {
  setSelectedChannel,
  setSelectedRegion,
} from "../../../context/AuthContext";
import { DEFAULT_IMAGE, FILES_API_URL } from "../../../components/ConstantAPI";

const ResultList = ({
  resultList = [],
  showResult,
  memberList = [],
  onShowResult,
}) => {
  let history = useHistory();

  return (
    <ul
      className={
        showResult && (resultList.length > 0 || memberList.length > 0)
          ? "dropdown-menu notifications animated fadeInLeft show"
          : "dropdown-menu notifications animated hide"
      }
      style={{ width: "600px", overflow: "auto", maxHeight: "500px" }}
    >
      {resultList.map((data, index) => {
        if (data) {
          return (
            <li key={data.ProjectCode}>
              <a
                href="javascript:void(0);"
                onMouseDown={() => {
                  onShowResult(false); //call the parent to hide the result after clicked

                  setLocalViewProjectRefresh(true);

                  setSelectedChannel(data.ChannelName);
                  setSelectedRegion("projects");

                  history.push({
                    pathname: "/projectdetails",
                    search: `?projectID=${data.ProjectCode}&userz=undefined`,
                  });
                }}
              >
                <div className="media">
                  <div className="media-left">
                    <i className=""></i>
                  </div>
                  <div className="media-body">
                    <p className="text">
                      <span style={{ fontWeight: "bold" }}>
                        {data.ProjectName}{" "}
                      </span>
                      &nbsp;
                      <span>{data.ChannelName}</span> &nbsp;&nbsp;
                      <span style={{ color: "grey", fontSize: "10px" }}>
                        {data.CompletionStatus}
                      </span>
                    </p>
                  </div>
                </div>
              </a>
            </li>
          );
        }
        return null;
      })}
      {memberList.length == 0 ? (
        ""
      ) : (
        <div>
          <li style={{ fontSize: "12px" }}>
            <div className="media">
              <div className="media-left">
                <i className=""></i>
              </div>
              <div className="media-body">
                <p className="text" style={{ marginTop: "0px" }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      textDecoration: "underline",
                    }}
                  >
                    Member
                  </span>
                </p>
              </div>
            </div>
          </li>
          {memberList.map((data, index) => {
            if (data) {
              return (
                <li style={{ fontSize: "12px" }} key={data.Alias}>
                  <a
                    href="javascript:void(0);"
                    onMouseDown={() => {
                      onShowResult(false); //call the parent to hide the result after clicked

                      setSelectedChannel("empty");
                      setSelectedRegion("projects");
                      history.push({
                        pathname: "/userprojectdetails",
                        search: `?userz=${data.Username}&isSearch=1`,
                      });
                    }}
                  >
                    <div className="media">
                      <div className="media-left"></div>
                      <div className="media-body">
                        <p className="text">
                          <img
                            src={FILES_API_URL + data.Photo}
                            onError={(e) => (
                              (e.target.onerror = null),
                              (e.target.src = DEFAULT_IMAGE)
                            )}
                            className="rounded-circle user-photo"
                            alt=""
                            style={{
                              border: "0px",
                              height: "30px",
                              width: "30px",
                              marginTop: "0px",
                            }}
                          />
                          <span
                            style={{ fontWeight: "bold", marginLeft: "10px" }}
                          >
                            {data.Alias}
                          </span>
                          {data.Alias.toLowerCase() === "evon" ||
                          data.Alias.toLowerCase() === "roger" ? (
                            <span></span>
                          ) : (
                            <span
                              style={{ fontWeight: "bold", marginLeft: "0px" }}
                            >
                              &nbsp;-&nbsp; {data.JobTitle}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
              );
            }
            return null;
          })}
        </div>
      )}
    </ul>
    // <div className="scroll">
    //   {resultList.map((data, index) => {
    //     if (data) {
    //       return (
    //         <div key={data.ProjectCode}>
    //           <h1>{data.ProjectName}</h1>
    //         </div>
    //       );
    //     }
    //     return null;
    //   })}
    // </div>
  );
};

export default ResultList;
