import React from "react";
import {
  useAuth,
  setLocalRole,
  getLocalViewProjectRefresh,
  setLocalViewProjectRefresh,
  setSelectedChannel,
  setSelectedRegion,
} from "../../../context/AuthContext";
import {
  GetTop5NotificationByUsername,
  DeleteNotification,
} from "../../../api/UserAPI";
import { getLocalToken, getLocalUsername } from "../../../context/AuthContext";
import {
  mySwalAlertFailed,
  RenderIcon,
  SetNotificationReadWithCB,
  GetPeriodString,
  mySwalAlertSuccess,
} from "../../../components/Misc";
import { useHistory } from "react-router-dom";
import Notification from "../../NotificationPopUp";
import NotificationPopUp from "../../NotificationPopUp";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function BottomBar(props) {
  const { setAuthTokens } = useAuth();
  const [data, setData] = React.useState([]);
  const [deleteQueue, setDeleteQueue] = React.useState([]);
  const [showContentButton, setShowContentButton] = React.useState(true);
  const [newNotification, setNewNotification] = React.useState(0);
  const [unreadCount, setUnreadCount] = React.useState(0);
  const [showDialog, setShowDialog] = React.useState(false);
  const [notiContent, setNotiContent] = React.useState("");
  const [notiIcon, setNotiIcon] = React.useState();
  const MySwal = withReactContent(Swal);
  let history = useHistory();

  const handleClose = () => {
    setShowDialog(false);
    reloadData();
  };

  React.useEffect(() => {
    reloadData();
    const interval = setInterval(() => {
      reloadData();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  function reloadData() {
    GetTop5NotificationByUsername(
      doGetDataCB,
      {
        FilterUsername: getLocalUsername(),
      },
      getLocalToken()
    );
  }

  function doGetDataCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      // console.log(temp);
      setData(temp);
      setNewNotification(temp.length);
      setUnreadCount(jsonObj.unreadCount);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  const viewAllNotifications = () => {
    SetNotificationReadWithCB(
      -1,
      getLocalUsername(),
      getLocalToken(),
      reloadData
    );
  };

  const seeAllNotification = () => {
    setShowContentButton(true);
    history.push("/notification");
  };

  function PopulateNotificationBox() {
    if (!showContentButton) {
      return (
        <div id="live-chat" class="NotificationTitle">
          <header class="clearfix">
            <a
              href="#"
              class="chat-close-dialog"
              onClick={() => setShowContentButton(true)}
            >
              x
            </a>
            <h4>
              Notification <span> ( {unreadCount} )</span>
            </h4>

            <p
              style={{
                marginTop: "-1px",
                marginBottom: "-5px",
                marginRight: "-5px",
              }}
            ></p>
          </header>

          <div class="chat">
            <div class="chat-history">{PopulateNotificationContent()}</div>
            <div class="buttonNotification">
              <a
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                  paddingLeft: "20px",
                }}
                onClick={viewAllNotifications}
              >
                Mark all as read
              </a>
              <a
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                  float: "right",
                  paddingRight: "60px",
                }}
                onClick={seeAllNotification}
              >
                See All Notifications
              </a>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div class="floatButton">
          <a
            href="#"
            onClick={() => setShowContentButton(false)}
            class="fa fa-envelope"
            style={{
              paddingTop: "13px",
              marginLeft: "8px",
              width: "55px",
              height: "55px",
            }}
          >
            {unreadCount > 0 ? (
              <span
                class="chat-message-counter"
                style={{
                  marginTop: "-10px",
                  marginLeft: "34px",
                  fontFamily: "Tahoma, sans-serif",
                  fontSize: "11px",
                }}
              >
                {unreadCount}
              </span>
            ) : (
              ""
            )}
          </a>
        </div>
      );
    }
  }

  function addToDeletionQueue(notiId) {
    let data = [...deleteQueue];

    const info = {
      NotiID: notiId,
    };

    let newData = [];

    if (!searchElement(data, notiId)) {
      data.push(info);
      setDeleteQueue(data);
    } else {
      data.map(function (val) {
        const info = {
          NotiID: val.NotiID,
        };

        if (notiId !== val.NotiID) {
          newData.push(info);
        }
      });

      setDeleteQueue(newData);
    }
  }

  function onDelete() {
    if (deleteQueue.length > 0) {
      MySwal.fire({
        title: "Confirmation",
        text: "Are you sure you want to delete these notification(s) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          let notiQueue = [];
          deleteQueue.map((settings) => {
            notiQueue.push(settings.NotiID);
          });

          DeleteNotification(
            doDeleteCB.bind(this),
            {
              Notis: notiQueue,
            },
            getLocalToken()
          );
        }
      });
    }
  }

  function doDeleteCB(jsonObj) {
    console.log(jsonObj);
    if (jsonObj.message === "Success") {
      setDeleteQueue([]);
      reloadData();
      return MySwal.fire({
        title: "Deleted!",
        text: "Your notifications has been deleted.",
        type: "success",
        confirmButtonText: "OK",
      });
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function searchElement(array, NotiID) {
    return array.find((element) => {
      return element.NotiID === NotiID;
    });
  }

  function PopulateNotificationContent() {
    return data.map((noti, index) => {
      const {
        Username,
        RoleID,
        Content,
        TypeIcon,
        NotiID,
        ReadBy,
        CreatedOn,
        ProjectID,
        ProjectCode,
        NotiType,
        ChannelCode,
      } = noti;

      var periodString = GetPeriodString(CreatedOn);
      let checkBoxClass = "customCheck" + index;
      return (
        <div>
          <div class="chat-message clearfix">
            <div
              class="chat-message-content clearfix"
              style={{
                backgroundColor: ReadBy === "" ? "white" : "lightgrey",
                marginTop: "-30px",
                marginLeft: "-20px",
                width: "300px",
              }}
            >
              <p class="notificationSender">
                <a
                  class="notificationSender"
                  href="#"
                  style={{
                    fontSize: "13px",
                    color: ReadBy === "" ? "" : "grey",
                  }}
                  onClick={() => {
                    SetNotificationReadWithCB(
                      NotiID,
                      getLocalUsername(),
                      getLocalToken(),
                      reloadData()
                    );

                    setLocalViewProjectRefresh(true);

                    setSelectedChannel(ChannelCode);
                    setSelectedRegion("projects");

                    history.push({
                      pathname: "/projectdetails",
                      search: `?projectID=${ProjectCode}&userz=undefined`,
                    });

                    setShowContentButton(true);
                  }}
                  //onClick={() =>
                  //  readNotification(Content, RenderIcon(TypeIcon), NotiID)
                  //}
                >
                  {Content.length > 68
                    ? Content.substring(0, 68) + " ..."
                    : Content}
                </a>
              </p>
              {NotiType === "1" ? (
                <span
                  class="badge badge-info"
                  style={{ marginLeft: "3px", marginTop: "5px" }}
                >
                  Admin
                </span>
              ) : (
                ""
              )}
              <p
                class="notificationInfo"
                style={{
                  marginTop: "5px",
                  marginBottom: "4px",
                  fontSize: "11px",
                }}
              >
                {moment(CreatedOn, "yyyy-MM-DD").format("DD/MM/yyyy")} -{" "}
                {periodString}
              </p>
              <hr />
            </div>
          </div>
        </div>
      );
    });
  }

  function renderGotoProject(projectCode, notiID) {
    if (projectCode !== "") {
      return (
        <span
          class="badge badge-info"
          style={{ marginLeft: "-3px", marginTop: "5px", cursor: "pointer" }}
          onClick={() => {
            SetNotificationReadWithCB(
              notiID,
              getLocalUsername(),
              getLocalToken(),
              reloadData
            );

            setLocalViewProjectRefresh(true);

            history.push({
              pathname: "/projectdetails",
              search: `?projectID=${projectCode}&userz=undefined`,
            });

            setShowContentButton(true);
          }}
        >
          Click to View Details
        </span>
      );
    }

    return null;
  }

  return (
    <div id="bottomBar">
      {PopulateNotificationBox()}
      <NotificationPopUp
        show={showDialog}
        handleClose={handleClose}
        notiContent={notiContent}
        icon={notiIcon}
      />
    </div>
  );
}

export default BottomBar;
