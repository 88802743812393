import React, { useState } from "react";
import { GetAllChannel } from "../../../api/AdminAPI";
import { CreateProject } from "../../../api/ProjectAPI";
import {
  getLocalToken,
  getLocalUsername,
  setLocalViewProjectRefresh,
} from "../../../context/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { textValidation } from "../../../components/Validation";
import {
  mySwalAlertFailed,
  mySwalAlertSuccess,
} from "../../../components/Misc";
import { Form } from "react-bootstrap";
import Select from "react-select";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

const NewProject = (props) => {
  const [projectData, setProjectData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [channelDataOption, setChannelDataOption] = useState([]);
  const [IsUpdate, setIsUpdate] = useState(false);
  const [data, setData] = React.useState([]);
  const MySwal = withReactContent(Swal);
  const [projectName, setProjectName] = useState("");
  const [dialogHeader, setDialogHeader] = useState("Create Group");
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [selectedChannelData, setSelectedChannelData] = React.useState("");
  const [sequence, setSequence] = useState(0);
  const [nameInvalid, setNameInvalid] = React.useState("");
  const [codeInvalid, setCodeInvalid] = React.useState("");
  const [showDialog, setShowDialog] = React.useState(false);
  const [existingChannelCode, setExistingCode] = React.useState("");
  const [isAdhoc, setAdHoc] = React.useState("");
  let history = useHistory();

  React.useEffect(() => {
    let params = queryString.parse(window.location.search);
    let channelCode = params.channelCode;
    setAdHoc(params.adhoc);

    if (existingChannelCode !== channelCode) {
      setExistingCode(channelCode);
      setSelectedChannelData(channelCode);
      GetSettings();
    }
  });

  const handleDropDownChange = (e) => {
    setSelectedChannelData(e.value);
  };

  const handleClose = () => {
    setShowDialog(false);
    resetDialogData();
  };

  function GetSettings() {
    GetAllChannel(
      doGetDataCB.bind(this),
      {
        FilterCode: "",
        SelectedPage: selectedPage,
      },
      getLocalToken()
    );
  }

  function doGetDataCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);

      let tagArr = [];
      for (var i = 0; i < temp.length; i++) {
        tagArr.push({
          value: temp[i].ChannelCode,
          label: temp[i].ChannelName,
        });
      }

      setChannelDataOption(tagArr);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function OnCreateSetting() {
    var nameInv = textValidation("Project Name", projectName, 2);
    setNameInvalid(nameInv);

    var projectInv = textValidation(
      "Project Code Prefix",
      selectedChannelData,
      2
    );
    setCodeInvalid(projectInv);

    if (nameInv === null && projectInv === null) {
      CreateProject(
        doCreateCB.bind(this),
        {
          ProjectName: projectName,
          ChannelCode: selectedChannelData,
          ProjectCode: code,
          Type: isAdhoc == "false" ? 1 : 0,
          UpdatedBy: getLocalUsername(),
          OtherProjectName: [],
        },
        getLocalToken()
      );
    }
  }

  function doCreateCB(jsonObj) {
    if (jsonObj.message === "Success") {
      resetDialogData();
      handleClose();
      // mySwalAlertSuccess("Project created successfully");

      setLocalViewProjectRefresh(true);

      if (jsonObj.projectCode !== "") {
        history.push({
          pathname: "/projectdetails",
          search: `?projectID=${jsonObj.projectCode}&userz=undefined&channelCode=${selectedChannelData}`,
        });
      }
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function resetDialogData() {
    setName("");
    setCode("");
    setProjectName("");
  }

  function onProjectDelete(e, usrn) {
    MySwal.fire({
      title: "Confirmation",
      text: "Are you sure you want to delete this ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        let tempArr = [...projectData];
        let newArr = [];

        for (var i = 0; i < tempArr.length; i++) {
          const info = {
            GroupName: tempArr[i].GroupCode,
            GroupCode: tempArr[i].GroupCode,
          };

          if (tempArr[i].GroupCode !== usrn) {
            newArr.push(info);
          }
        }

        setProjectData(newArr);
      }
    });
  }

  return (
    <div id="main-content">
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2>Create Project</h2>
            </div>
          </div>
        </div>

        <div class="row clearfix">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="card">
              <div class="body">
                <div class="row clearfix">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Project Name *"
                        onChange={(e) => setProjectName(e.target.value)}
                        value={projectName}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            OnCreateSetting();
                          }
                        }}
                      />
                    </div>
                    <span style={{ color: "red" }}>{nameInvalid}</span>{" "}
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <Select
                        style={{ width: "100px" }}
                        placeholder="Select Default Channel Setting *"
                        options={channelDataOption}
                        isDisabled={true}
                        onChange={handleDropDownChange}
                        value={channelDataOption.find(
                          (obj) => obj.value === selectedChannelData
                        )}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            OnCreateSetting();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div class="row clearfix">
                  <div class="col-sm-12">
                    <div class="mt-4">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            OnCreateSetting();
                          }
                        }}
                        onClick={() => OnCreateSetting()}
                      >
                        Create
                      </button>{" "}
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProject;
