import React, { useState } from "react";
import {
  useAuth,
  setLocalRole,
  getLocalRole,
  getLocalToken,
} from "../../../context/AuthContext";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { InsertNotification, InsertProjectLog } from "../../../api/UserAPI";
import { mySwalAlertFailed } from "../../../components/Misc";
import { useHistory } from "react-router-dom";
import "../../../App.css";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Nestable from "react-nestable";
import "./Dashboard.css";

function Dashboard() {
  const { holidays } = useAuth();
  let history = useHistory();
  const [arrHolidayDates, setArrHolidayDates] = React.useState([]);
  const [onboardingDate, setOnboardingDate] = useState(new Date());
  const [data, setData] = React.useState([]);
  const disableFutureDt = (current) => {
    const yesterday = moment().subtract(1, "day");

    //disable saturday + sunday + past day
    return (
      current.day() !== 0 &&
      current.day() !== 6 &&
      current.isAfter(yesterday) &&
      holidays !== null &&
      !holidays.includes(current.format("DD/MM/yyyy"))
    );
  };

  React.useEffect(() => {
    // let holidays = getWeekendDate();
    // setArrHolidayDates(holidays);

    let arr = [];

    arr.push({ id: "1", text: "Brian" });
    arr.push({ id: "2", text: "Bily" });
    arr.push({ id: "3", text: "Chris" });
    arr.push({ id: "4", text: "Japan" });
    arr.push({ id: "5", text: "Stephanie" });
    arr.push({ id: "6", text: "Sheatyee" });

    setData(arr);
  }, []);

  if (getLocalToken() === "undefined") {
    const referer = "/";
    return <Redirect to={referer} />;
  }

  function tryMe() {
    InsertNotification(
      doInsertNotificationCB,
      {
        Username: "B010",
        RoleID: getLocalRole(),
        Content:
          "Task [Script Preparation] on Project [The Rubber - Project A] is assigned to you ",
        // "You have been tagged on Project [The Rubber - Project A]",
        // "Project [The Rubber - Project A] is going to hit deadline on 1 January 2021",
        TypeIcon: "TASKASSIGN", //TASKASSIGN | TAG | REMINDER
        ProjectTaskID: 102,
      },
      getLocalToken()
    );
  }

  function doInsertNotificationCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      console.log(jsonObj);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function insertProjectLog() {
    InsertProjectLog(
      doInsertProjectLogCB,
      {
        ProjectCode: "RUBBER_PRO1",
        ProjectDescription:
          "Project Status Changed From Incomplete to Completed",
        ProjectType: "Status",
        DataFrom: "Incomplete",
        DataTo: "Completed",
      },
      getLocalToken()
    );
  }

  function doInsertProjectLogCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      console.log(jsonObj);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function gotoProjectLog() {
    history.push({
      pathname: "/projectlog",
      search: "?projectCode=RUBBER_PRO1",
    });
  }

  const items = [
    {
      id: 0,
      text: "Andy",
      children: [
        { id: 1, text: "Harry", parent: "Andy" },
        { id: 2, text: "David", parent: "Andy" },
        { id: 3, text: "Lisa", parent: "Andy" },
      ],
    },
    {
      id: 0,
      text: "Group z",
      children: [
        { id: 4, text: "aa", parent: "Group z" },
        { id: 5, text: "bb", parent: "Group z" },
        { id: 6, text: "cc", parent: "Group z" },
        { id: 7, text: "dd", parent: "Group z" },
      ],
    },
  ];

  const renderItem = ({ item }) => {
    return (
      <div>
        <a href="#" draggable="true">
          <i className="icon-target" style={{ marginRight: "20px" }}></i>
        </a>
        {item.text}
      </div>
    );
  };

  const onItemOnChange = (items, item) => {
    // console.log(items);
    // console.log(item);
  };

  const onItemConfirmChange = (dragItem, destinationParent) => {
    console.log("dragitem");
    console.log(dragItem);
    // console.log(dragItem.parent);
    console.log("destinationParent");
    console.log(destinationParent);
    // console.log(destinationParent.text);

    if (
      destinationParent !== null &&
      dragItem.parent === destinationParent.text
    ) {
      return true;
    }
    return false;
  };

  function renderTableData() {
    return data.map((user) => {
      const { text } = user;

      return (
        <div>
          {text}
          <div>
            <Form.Control
              placeholder="text"
              value={text}
              // defaultValue={TaskName} //using value, will cause hanging when typing. But another issue is the drag and drop
              onChange={(e) => {
                user.text = e.target.value;
                setData((data) => [...data]);
              }}
            />
          </div>
        </div>
      );
    });
  }

  return (
    <div id="main-content">
      <img
        src="assets/images/Brainywallapaper.png"
        width="100%"
        height="100%"
        alt="Lucid"
      />
    </div>
  );
}

export default Dashboard;
