import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import emailjs from "emailjs-com";
import { ForgetPasswordMethod } from "../../api/UserAPI";
import {
  mySwalAlertFailed,
  mySwalAlertSuccess,
  baseURL,
} from "../../components/Misc";

const ForgetPassword = (props) => {
  const [backToHome, setRedirectBackToHome] = React.useState(false);
  const [username, setUsername] = useState("");

  function doGetDataCB(jsonObj) {
    if (jsonObj.message === "Success") {
      let templateParams = {
        to: username,
        reply_to: username,
        name: jsonObj.firstName,
        reset_code:
          `${baseURL()}` +
          "/resetpassword?verificationCode=" +
          jsonObj.generateCode +
          "&username=" +
          username,
      };

      emailjs
        .send(
          "service_4lnjn6d",
          "template_i1hbhm8",
          templateParams,
          "user_wDBeqXfT9AWnCCKi3SBt7"
        )
        .then(
          function (response) {
            mySwalAlertSuccess(
              "Reset Password Email sent.\nPlease check your email."
            );

            console.log("SUCCESS!", response.status, response.text);
            setRedirectBackToHome(true);
          },
          function (error) {
            mySwalAlertFailed(
              "Reset Password Failed, Please try again later or contact IT support for more information"
            );

            console.log("FAILED...", error);
          }
        );
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function sendResetPasswordEmail() {
    ForgetPasswordMethod(doGetDataCB, {
      Username: username,
    });
  }

  if (backToHome) {
    return <Redirect to="/" />;
  } else {
    return (
      <div>
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div className="top">
                <img src="../assets/images/login.png" alt="Brainy" />
              </div>
              <div className="card">
                <div class="header">
                  <p class="lead">Forgot Password</p>
                </div>
                <div className="body">
                  <div className="form-group">
                    <label for="signin-email">Email Address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="signin-email"
                      placeholder="Email"
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary btn-lg btn-block"
                    onClick={() => sendResetPasswordEmail()}
                  >
                    Reset Password
                  </button>
                  <button
                    class="btn btn-primary btn-lg btn-block"
                    onClick={() => setRedirectBackToHome(true)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ForgetPassword;
