import React from "react";
import { useAuth, setLocalRole } from "../../../context/AuthContext";
import {
  GetTop5NotificationByUsername,
  SetNotificationReadByUsernameWithCB,
} from "../../../api/UserAPI";
import {
  getLocalToken,
  getLocalUsername,
  getLocalViewProjectRefresh,
  setLocalViewProjectRefresh,
  setSelectedChannel,
  setSelectedRegion,
} from "../../../context/AuthContext";
import {
  mySwalAlertFailed,
  RenderIcon,
  SetNotificationReadWithCB,
  GetPeriodString,
} from "../../../components/Misc";
import { useHistory } from "react-router-dom";
import Notification from "../../NotificationPopUp";
import NotificationPopUp from "../../NotificationPopUp";
import moment from "moment";
import SearchBar from "../SearchBar";

function TopHeader(props: any) {
  const { setAuthTokens } = useAuth();
  const [data, setData] = React.useState([]);
  const [newNotification, setNewNotification] = React.useState(0);
  const [showDialog, setShowDialog] = React.useState(false);
  const [notiContent, setNotiContent] = React.useState("");
  const [notiIcon, setNotiIcon] = React.useState();
  let history = useHistory();

  const handleClose = () => {
    setShowDialog(false);
    reloadData();
  };

  // React.useEffect(() => {
  //   reloadData();
  //   const interval = setInterval(() => {
  //     reloadData();
  //     // var isExpanded = $(".testing").attr("aria-expanded");
  //     // if (isExpanded === "false") {
  //     //   $(".testing").click();
  //     // }
  //   }, 10000);

  //   return () => clearInterval(interval);
  // }, []);

  function reloadData() {
    GetTop5NotificationByUsername(
      doGetDataCB,
      {
        FilterUsername: getLocalUsername(),
      },
      getLocalToken()
    );
  }

  function doGetDataCB(jsonObj: any) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      // console.log(temp);
      setData(temp);
      setNewNotification(temp.length);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function readNotification(notiContent: any, icon: any, notiID: any) {
    setNotiIcon(icon);
    setNotiContent(notiContent);
    setShowDialog(true);
    SetNotificationReadWithCB(notiID, getLocalUsername(), getLocalToken());
  }

  function redirectToTask() {
    setSelectedChannel("mytasks");
    setSelectedRegion("projects");
    history.push({
      pathname: "/projectdetails",
      search: `?channelName=My Task&userz=${getLocalUsername()}`,
    });
  }

  function redirectToDashboard() {
    setSelectedChannel("mytasks");
    setSelectedRegion("projects");
    history.push({
      pathname: "/Dashboard",
    });
  }

  function renderContent(
    isReadBy: any,
    content: any,
    typeicon: any,
    notiID: any
  ) {
    if (!isReadBy) {
      return (
        <a
          href="#"
          style={{ color: "black" }}
          onClick={() =>
            readNotification(content, RenderIcon(typeicon), notiID)
          }
        >
          <h6>
            <u>
              <strong>{content}</strong>
            </u>
          </h6>
        </a>
      );
    }
    return (
      <a
        href="#"
        style={{ color: "black" }}
        onClick={() => readNotification(content, RenderIcon(typeicon), notiID)}
      >
        <h6>{content}</h6>
      </a>
    );
  }

  function renderGotoProject(projectCode: any, notiID: any) {
    if (projectCode !== "") {
      return (
        <a
          href="#"
          onClick={() => {
            SetNotificationReadWithCB(
              notiID,
              getLocalUsername(),
              getLocalToken(),
              reloadData
            );

            setLocalViewProjectRefresh(true);

            history.push({
              pathname: "/projectdetails",
              search: `?projectID=${projectCode}&userz=undefined`,
            });
          }}
        >
          <h6>Go to project</h6>
        </a>
      );
    }

    return null;
  }

  function renderNotificationData() {
    return data.map((noti) => {
      const {
        Username,
        RoleID,
        Content,
        TypeIcon,
        NotiID,
        ReadBy,
        CreatedOn,
        ProjectID,
        ProjectCode,
      } = noti;
      var readBy = ReadBy as string;
      var isReadBy = readBy.includes(getLocalUsername());

      var periodString = GetPeriodString(CreatedOn);

      return (
        <li key={NotiID}>
          <a>
            <div className="media">
              <div className="media-left">{RenderIcon(TypeIcon)}</div>
              <div className="media-body">
                <div
                  className="text"
                  style={{
                    whiteSpace: "normal",
                    width: "100%",
                  }}
                >
                  {renderContent(isReadBy, Content, TypeIcon, NotiID)}
                  {renderGotoProject(ProjectCode, NotiID)}
                </div>
                <span className="timestamp" style={{ whiteSpace: "normal" }}>
                  {periodString}
                </span>
              </div>
            </div>
          </a>
        </li>
      );
    });
  }

  return (
    <div>
      <nav className="navbar navbar-fixed-top" style={{ marginTop: "-10px" }}>
        <div className="container-fluid">
          <div className="navbar-btn">
            <button type="button" className="btn-toggle-offcanvas">
              <i className="lnr lnr-menu fa fa-bars"></i>
            </button>
          </div>

          <div className="navbar-brand" style={{ padding: "0px" }}>
            <a
              href="#"
              onClick={() => {
                redirectToDashboard();
              }}
            >
              <img
                src="../assets/images/topleft.png"
                alt="Brainy Logo"
                style={{ width: "170px" }}
                className="img-responsive logo"
              />
            </a>
          </div>

          <div className="navbar-right">
            <form id="navbar-search" className="navbar-form search-form">
              <SearchBar />
              {/* <input
                value=""
                className="form-control"
                placeholder="Search here..."
                type="text"
                style={{ width: "218px" }}
              /> */}
              <button type="button" className="btn btn-default">
                <i className="icon-magnifier"></i>
              </button>
            </form>
            {/*<div id="navbar-menu">
              <ul className="nav navbar-nav">
                <li>
                  <a
                    className="icon-menu"
                    href="#"
                    onClick={(val) => btnLogOut()}
                  >
                    <i className="icon-login"></i>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </nav>
      <NotificationPopUp
        show={showDialog}
        handleClose={handleClose}
        notiContent={notiContent}
        icon={notiIcon}
      />
    </div>
  );
}

export default TopHeader;
