import React from "react";
import { NavLink } from "react-router-dom";
import {
  GetChannelWithProjects,
  GetChannelWithProjectsMenu,
} from "../../../api/ProjectAPI";
import {
  getLocalToken,
  getLocalUsername,
  getLocalPhotoPath,
  getLocalRole,
  getLocalAlias,
  setSelectedChannel,
  getSelectedChannel,
  setSelectedRegion,
  getSelectedRegion,
  useAuth,
} from "../../../context/AuthContext";
import { mySwalAlertFailed } from "../../../components/Misc";
import { useHistory } from "react-router-dom";
import { DEFAULT_IMAGE } from "../../../components/ConstantAPI";

const Menu = (props) => {
  let history = useHistory();
  const { setAuthTokens } = useAuth();
  const [data, setData] = React.useState([]);
  const [picPath, setPicPath] = React.useState("");
  const imageErrorSrc = "";
  const [menuState, setMenuDisplay] = React.useState("");
  const [region, setRegion] = React.useState(
    getLocalRole() !== 1 && getLocalRole() !== 2 ? "projects" : "projects"
  );

  React.useEffect(() => {
    InitialProject();
    setPicPath(getLocalPhotoPath());

    if (getSelectedChannel() !== null && getSelectedChannel() !== "") {
      setMenuState(getSelectedChannel());

      let currentChannel = getSelectedChannel();
      if (
        currentChannel === "employee" ||
        currentChannel === "projectstatus" ||
        currentChannel === "tasksettings" ||
        currentChannel === "group" ||
        currentChannel === "channel" ||
        currentChannel === "publicholiday" ||
        currentChannel === "scheduler" ||
        currentChannel === "dashboard"
      ) {
        setRegion("hr");
      }
    }
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (picPath !== getLocalPhotoPath()) {
        setPicPath(getLocalPhotoPath());
      }

      if (
        getSelectedRegion() !== null &&
        getSelectedRegion() !== undefined &&
        getSelectedRegion() !== ""
      ) {
        setRegion(getSelectedRegion());
        setSelectedRegion("");
      }

      if (
        getSelectedChannel() !== null &&
        getSelectedChannel() !== "" &&
        getSelectedChannel() !== menuState &&
        menuState !== "mytasks"
      ) {
        setMenuState(getSelectedChannel());
      }
    }, 3000);
  });

  function setMenuState(state) {
    setMenuDisplay(state);
    setSelectedChannel(state);
  }

  function InitialProject() {
    GetChannelWithProjectsMenu(
      doGetDataCB.bind(this),
      {
        ChannelCode: "",
        Username: getLocalUsername(),
      },
      getLocalToken()
    );
  }

  function onClickProjectTab() {
    setRegion("projects");

    GetChannelWithProjects(
      doGetDataCB.bind(this),
      {
        ChannelCode: "",
        Username: getLocalUsername(),
      },
      getLocalToken()
    );
  }

  function doGetDataCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      setData(temp);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function goToProjectListing(code, name, parent, userz, project) {
    setSelectedChannel(code);
    history.push({
      pathname: "/projectdetails",
      search:
        "?channelCode=" +
        code +
        "&channelName=" +
        name +
        "&userz=" +
        userz +
        "&parent=" +
        parent +
        "&projectCode=" +
        parent +
        "&projectID=" +
        project,
    });
  }

  function goToProfile() {
    setMenuState("profile");
    history.push({
      pathname: "/profile",
    });
  }

  function renderChannelData() {
    return data.map((settings) => {
      const { ChannelName, projects, ChannelCode, Parent } = settings;

      return (
        <li
          className={menuState === ChannelName ? "active" : ""}
          aria-expanded={menuState === ChannelName ? false : true}
          onClick={() =>
            menuState === ChannelName
              ? setMenuState("")
              : setMenuState(ChannelName)
          }
        >
          <a
            href="#"
            onClick={() => goToProjectListing(ChannelCode, ChannelName, Parent)}
          >
            <i className="icon-social-youtube"></i>
            <span>{ChannelName}</span>
          </a>
        </li>
      );
    });
  }

  function setHRFunction() {
    setRegion("hr");
  }

  return (
    <div id="left-sidebar" className="sidebar">
      <div className="sidebar-scroll">
        <div className="user-account">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              goToProfile();
            }}
          >
            <img
              src={picPath}
              onError={(e) => (
                (e.target.onerror = null), (e.target.src = DEFAULT_IMAGE)
              )}
              className="rounded-circle user-photo"
              alt="User Profile Picture"
              style={{
                border: "0px",
                height: "65px",
                width: "65px",
                marginTop: "0px",
              }}
            />
          </span>
          <div
            className="dropdown"
            onClick={() => {
              goToProfile();
            }}
            style={{
              marginTop: "20px",
              fontSize: "15px",
              cursor: "pointer",
              color: "#007bff",
            }}
          >
            <span>Hello {getLocalAlias()}</span>
          </div>
        </div>
        <ul
          className="nav nav-tabs"
          style={{ borderBottom: "1px solid lightgrey", marginTop: "-10px" }}
        >
          {getLocalRole() === 1 || getLocalRole() === 2 ? (
            <ul className="nav nav-tabs">
              <li className="nav-item" onClick={() => setHRFunction()}>
                <a
                  className={region === "hr" ? "nav-link active" : "nav-link"}
                  data-toggle="tab"
                  href="#hr_menu"
                  style={{
                    borderLeft: region === "hr" ? "1px solid grey" : "",
                    borderRight: region === "hr" ? "1px solid grey" : "",
                    borderTop: region === "hr" ? "1px solid grey" : "",
                  }}
                >
                  Admin
                </a>
              </li>
              <li className="nav-item" onClick={() => onClickProjectTab()}>
                <a
                  className={
                    region === "projects" ? "nav-link active" : "nav-link"
                  }
                  data-toggle="tab"
                  href="#project_menu"
                  style={{
                    borderLeft: region === "projects" ? "1px solid grey" : "",
                    borderRight: region === "projects" ? "1px solid grey" : "",
                    borderTop: region === "projects" ? "1px solid grey" : "",
                  }}
                >
                  Projects
                </a>
              </li>
            </ul>
          ) : (
            <ul
              className="nav nav-tabs"
              style={{ borderBottom: "1px solid red" }}
            ></ul>
          )}
        </ul>

        <div className="tab-content p-l-0 p-r-0">
          <div
            className={
              region === "hr"
                ? "tab-pane animated fadeIn active"
                : "tab-pane animated"
            }
            id="hr_menu"
          >
            <nav className="sidebar-nav">
              <ul className="main-menu metismenu">
                {/**<li
                  onClick={() => setMenuState("dashboard")}
                  className={menuState === "dashboard" ? "active" : ""}
                >
                  <NavLink to="/dashboard">
                    <i className="icon-speedometer"></i>Dashboard
                  </NavLink>
                </li> **/}
                <li
                  onClick={() => setMenuState("employee")}
                  className={menuState === "employee" ? "active" : ""}
                >
                  <NavLink to="/employee">
                    <i className="icon-users"></i>Employees
                  </NavLink>
                </li>

                <li
                  className={
                    menuState === "adminsetting" ||
                    menuState === "projectstatus" ||
                    menuState === "tasksettings" ||
                    menuState === "group" ||
                    menuState === "channel" ||
                    menuState === "publicholiday"
                      ? "active"
                      : ""
                  }
                >
                  <a
                    href="#"
                    className="has-arrow"
                    onClick={() => setMenuState("adminsetting")}
                  >
                    <i className="icon-settings"></i>
                    <span>Settings</span>
                  </a>
                  <ul
                    className={
                      menuState === "adminsetting" ||
                      menuState === "projectstatus" ||
                      menuState === "tasksettings" ||
                      menuState === "group" ||
                      menuState === "channel" ||
                      menuState === "publicholiday"
                        ? "collapse in"
                        : "collapse"
                    }
                  >
                    <li
                      onClick={() => setMenuState("projectstatus")}
                      className={menuState === "projectstatus" ? "active" : ""}
                    >
                      <NavLink to="/projectstatus">Project Status</NavLink>
                    </li>
                    <li
                      onClick={() => setMenuState("tasksettings")}
                      className={menuState === "tasksettings" ? "active" : ""}
                    >
                      <NavLink to="/task">Tasks</NavLink>
                    </li>
                    <li
                      onClick={() => setMenuState("group")}
                      className={menuState === "group" ? "active" : ""}
                    >
                      <NavLink to="/group">Groups</NavLink>
                    </li>
                    <li
                      onClick={() => setMenuState("channel")}
                      className={menuState === "channel" ? "active" : ""}
                    >
                      <NavLink to="/channel">Channels</NavLink>
                    </li>
                    <li
                      onClick={() => setMenuState("publicholiday")}
                      className={menuState === "publicholiday" ? "active" : ""}
                    >
                      <NavLink to="/publicholiday">Public Holiday</NavLink>
                    </li>
                  </ul>
                </li>

                {/* <li
                  onClick={() => setMenuState("projectlog")}
                  className={menuState === "projectlog" ? "active" : ""}
                >
                  <NavLink to="/projectlog">
                    <i className="icon-list"></i>Project Log
                  </NavLink>
                </li> */}

                <li
                  onClick={() => setMenuState("scheduler")}
                  className={menuState === "scheduler" ? "active" : ""}
                >
                  <NavLink to="/scheduler">
                    <i className="icon-list"></i>Project Management
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>

          <div
            className={
              region === "projects"
                ? "tab-pane animated fadeIn active"
                : "tab-pane animated"
            }
            id="project_menu"
          >
            <nav className="sidebar-nav">
              <div className="menuwrapper" id="menuwrapper">
                <ul className="main-menu metismenu">
                  {/* <li
                  onClick={() => setMenuState("newproject")}
                  className={menuState === "newproject" ? "active" : ""}
                >
                  <NavLink to="/newproject">
                    <i className="icon-list"></i> Create Project
                  </NavLink>
                </li> */}
                  <li
                    onClick={() => setMenuState("mytasks")}
                    className={
                      menuState === "mytasks" || menuState === "mytaskstasks"
                        ? "active"
                        : ""
                    }
                  >
                    <a
                      href="#"
                      onClick={() =>
                        goToProjectListing(
                          "",
                          "My Tasks",
                          "",
                          getLocalUsername()
                        )
                      }
                    >
                      <i className="icon-list"></i>
                      <span>My Tasks</span>
                    </a>
                  </li>
                  {renderChannelData()}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
