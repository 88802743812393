export const textValidation = (fieldName, fieldValue, fieldlength) => {
  if (fieldValue.trim() === "") {
    return `${fieldName} is required`;
  }

  if (fieldValue.trim().length < fieldlength && fieldlength !== -1) {
    return `${fieldName} needs to be at least ${fieldlength} characters`;
  }
  return null;
};

export const numberValidation = (fieldName, num) => {
  if (isNaN(num)) {
    return `${fieldName} must be number`;
  }

  return null;
};

export const emailValidation = (email) => {
  if (
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      email
    )
  ) {
    return null;
  }
  if (email.trim() === "") {
    return "Email is required";
  }
  return "Please enter a valid email";
};

export const ageValidation = (age) => {
  if (!age) {
    return "Age is required";
  }
  if (age < 18) {
    return "Age must be at least 18";
  }
  if (age > 99) {
    return "Age must be under 99";
  }
  return null;
};

export const passwordValidation = (
  fieldName,
  fieldName2,
  fieldValue,
  fieldValue2,
  fieldlength
) => {
  if (fieldValue.trim() === "") {
    return `${fieldName} is required`;
  }

  if (fieldValue.trim().length < fieldlength && fieldlength !== -1) {
    return `${fieldName} needs to be at least ${fieldlength} characters`;
  }

  if (fieldValue !== fieldValue2) {
    return `${fieldName} and ${fieldName2} does not match.`;
  }

  return null;
};
