import React, { Component } from "react";
import {
  EditorState,
  AtomicBlockUtils,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import Editor, { createEditorStateWithText } from "draft-js-plugins-editor";
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "draft-js-mention-plugin";
import "./editorStyles.css";
import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js-hashtag-plugin/lib/plugin.css";
import "draft-js-emoji-plugin/lib/plugin.css";
import "draft-js-linkify-plugin/lib/plugin.css";
import createImagePlugin from "draft-js-image-plugin";
import createHashtagPlugin from "draft-js-hashtag-plugin";
import createEmojiPlugin from "draft-js-emoji-plugin";
import createLinkifyPlugin from "draft-js-linkify-plugin";
import createHighlighPlugin from "./highlighPlugin";
import {
  GetMentionName,
  InsertMessage,
  GetAllMessageByProjectID,
  PinMessage,
  DeleteMessage,
  CheckUploadImageNameConflict,
} from "../../../api/UserAPI";
import {
  getLocalToken,
  getLocalUsername,
  getLocalPhotoPath,
} from "../../../context/AuthContext";
import {
  mySwalAlertFailed,
  GetPeriodString,
  massageFileName,
} from "../../../components/Misc";
import {
  REST_API_URL,
  FILES_API_URL,
  FILES_BASE_URL,
  DEFAULT_IMAGE,
} from "../../../components/ConstantAPI";
import debounce from "lodash/debounce";
import { Button } from "react-bootstrap";
import { stateToHTML } from "draft-js-export-html";

export default class SendMessage extends Component {
  intervalID;

  constructor(props) {
    super(props);

    this.countz = 0;
    this.alreadyUnmount = false;

    this.mentionPlugin = createMentionPlugin({
      mentionPrefix: "@",
      // enable below if there's complain that the mention is show below and unable to see all and need to scroll up.
      // I manually set the left and top to match my screen. Not sure will it behave differently on diff screen or not thats why disable it
      // positionSuggestions: (settings) => {
      //   return {
      //     left: settings.decoratorRect.left - 270 + "px",
      //     top: settings.decoratorRect.top - 290 + "px", // change this value (40) for manage the distance between cursor and bottom edge of popover
      //     display: "block",
      //     transform: "scale(1) translateY(-100%)", // transition popover on the value of its height
      //     transformOrigin: "1em 0% 0px",
      //     transition: "all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)",
      //   };
      // },
    });
    this.imagePlugin = createImagePlugin();
    this.hashtagPlugin = createHashtagPlugin();
    this.emojiPlugin = createEmojiPlugin({});
    this.linkifyPlugin = createLinkifyPlugin({
      component: (props) => (
        // eslint-disable-next-line no-alert, jsx-a11y/anchor-has-content
        <a
          download
          href={props.href}
          {...props}
          onClick={() => window.open(props.href, "_blank")}
        />
      ),
    });
    this.highlightPlugin = createHighlighPlugin();
    this.hiddenFileInput = React.createRef();

    const content = window.localStorage.getItem("content");

    if (content) {
      this.state.editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(content))
      );
    } else {
      this.state.editorState = EditorState.createEmpty();
    }
  }

  //WHEN NOT USING MODAL POP UP
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.projectTaskID !== this.props.projectTaskID) {
  //     clearTimeout(this.intervalID);
  //     this.retrieveMessages();
  //   }
  // }

  //when using MODAL POP UP
  componentWillMount() {
    //console.log('Component Will Mount');
    clearTimeout(this.intervalID);
    this.retrieveMessages();
  }

  componentWillUnmount() {
    //console.log('Component Will Unmount');
    clearTimeout(this.intervalID);
    this.alreadyUnmount = true;

    this.setState = (state, callback) => {
      return;
    };
  }

  retrieveMessages() {
    this.countz = this.countz + 1;
    
    GetAllMessageByProjectID(
      this.doGetAllMessageDataCB.bind(this),
      {
        ProjectID: this.props.projectTaskID,
      },
      getLocalToken(),
      this.errorCB.bind(this),
      this.countz,
    );
  }

  errorCB(jsonObj) {
    // console.log(`ERROR AAAAAA ${this.countz}`);
    // this.countz = 0;
    this.retrieveMessages();
  }

  doGetAllMessageDataCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      console.log(`SUCCESS ${this.countz}`);
      let temp = JSON.parse(jsonObj.listing);

      var oriLength = this.state.data.length;
      var newLength = temp.length;

      // console.log(temp);
      this.setState({
        data: temp,
      });

      // console.log(this.alreadyUnmount);

      if(this.alreadyUnmount) {
        clearTimeout(this.intervalID);
        this.setState = (state, callback) => {
          return;
        };
      }

      // They don't want to auto scroll to bottom. Maybe because worried staff haven't read yet
      // if (oriLength !== newLength && oriLength !== 0) {
      //   this.scrollToBottom();
      // }
      // setData(temp);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }

    // call retrieveMessages() again in 5 seconds
    if(!this.alreadyUnmount) {
      this.intervalID = setTimeout(this.retrieveMessages.bind(this), 1000);
    }  }

  state = {
    editorState: createEditorStateWithText(""),
    suggestions: [],
    isSendMessageDisable: false,
    data: [],
  };

  onChange = (editorState) => {
    // const contentState = editorState.getCurrentContent();
    // this.saveContent(contentState);

    this.setState({
      editorState,
    });
  };

  // saveContent = debounce((content) => {
  //   console.log(convertToRaw(content));
  //   window.localStorage.setItem(
  //     "content",
  //     JSON.stringify(convertToRaw(content))
  //   );
  // }, 1000);

  onSearchChange = ({ value }) => {
    GetMentionName(this.doGetDataCB.bind(this), {}, getLocalToken(), value);
  };

  doGetDataCB(jsonObj, value) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      this.setState({
        suggestions: defaultSuggestionsFilter(value, temp),
      });
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  onAddMention = () => {
    // get the mention object selected
  };

  focus = () => {
    this.editor.focus();
  };

  //supported like screenshot only
  uploadPastedFiles(files) {
    this.setState({
      isSendMessageDisable: true,
    });

    var fileSize = files[0].size;
    //100000000 - 100MB
    if (fileSize > 100000000) {
      mySwalAlertFailed("File size exceed 100MB");
    } else {
      const formData = new FormData();
      formData.append("qqfile", files[0]);
      formData.append("username", getLocalUsername().toLowerCase());
      formData.append("folder", `messagebox`);
      formData.append("subfolder", `${this.props.projectTaskID}`);

      var filename = massageFileName(files[0].name);

      fetch(FILES_API_URL + "/users/upload", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          var fullPath =
            FILES_API_URL +
            "/" +
            FILES_BASE_URL +
            "/" +
            getLocalUsername().toLowerCase() +
            `/messagebox/${this.props.projectTaskID}/` +
            filename;
          this.setState({
            editorState: this.insertImage(fullPath),
          });

          this.setState({
            isSendMessageDisable: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isSendMessageDisable: false,
          });
        });
    }
  }

  insertImage(url) {
    const contentState = this.state.editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(this.state.editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  }

  handleDroppedFiles(selection, files) {
    this.uploadDroppedFiles(files);
  }

  //we only support one file upload at a time. Thats why all are aiming [0] which is the first item
  uploadDroppedFiles(files) {
    this.uploadDroppedFilesToServer(files);
  }

  async uploadDroppedFilesToServer(files) {
    this.setState({
      isSendMessageDisable: true,
    });

    for (var z = 0; z < files.length; z++) {
      this.processImageUpload(files[z]);
    }
  }

  async processImageUpload(file) {
    var isImage = false;

    if (file.type.includes("image")) {
      isImage = true;
    }

    var fileSize = file.size;
    //100000000 - 100MB
    if (fileSize > 100000000) {
      mySwalAlertFailed("File size exceed 100MB");
    } else {
      const formData = new FormData();
      formData.append("qqfile", file);
      formData.append("username", getLocalUsername().toLowerCase());
      formData.append("folder", `messagebox`);
      formData.append("subfolder", `${this.props.projectTaskID}`);

      var filename = massageFileName(file.name);

      var fullPath =
        FILES_API_URL +
        "/" +
        FILES_BASE_URL +
        "/" +
        getLocalUsername().toLowerCase() +
        `/messagebox/${this.props.projectTaskID}/` +
        filename;

      //check server to see if the path exists, prevent it from overwrite it
      CheckUploadImageNameConflict(
        this.doCheckUploadImageNameConflictCB.bind(this),
        {
          ProjectTaskID: `${this.props.projectTaskID}`,
          FilePath: fullPath,
          FileName: filename,
        },
        getLocalToken(),
        fullPath,
        formData,
        isImage
      );
    }
  }

  insertLink(editorState, url) {
    const contentState = this.state.editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { src: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(this.state.editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, url);
  }

  convertMentioned(html) {
    const rawEditorContent = convertToRaw(
      this.state.editorState.getCurrentContent()
    );
    const entityMap = rawEditorContent.entityMap;

    const uniqueTags = [];
    Object.values(entityMap).map((entity) => {
      if (
        entity.data.mention !== undefined &&
        uniqueTags.indexOf(entity.data.mention.name) === -1
      ) {
        uniqueTags.push(entity.data.mention.name);
        var nameReplaceFrom = "@" + entity.data.mention.name;
        var nameReplaceTo = `[@${entity.data.mention.name}]`;
        html = html.replaceAll(nameReplaceFrom, nameReplaceTo);
      }
    });

    return html;
  }

  OnSendMessage() {
    const content = this.state.editorState.getCurrentContent();
    var contentHtml = stateToHTML(content);
    contentHtml = this.convertMentioned(contentHtml);

    var stripedHtml = contentHtml.replace(/<[^>]+>/g, "");
    stripedHtml = stripedHtml.replaceAll("&nbsp;", "");
    //only save if it has contain some text.
    //My checking for now is removing all the html tag, then replace the space &nbsp; and then trip it
    //Above regex removes all the tagging just get the data inside the tag, thus those with just the image will be identified as no message as well, so check the 'figure'
    if (stripedHtml.trim() !== "" || contentHtml.includes("figure")) {
      console.log("OnSendMessage");
      InsertMessage(
        this.doInsertMessageCB.bind(this),
        {
          Username: getLocalUsername(),
          ProjectTaskID: this.props.projectTaskID,
          Project: "Project ABC",
          Task: "Task ABC",
          Message: contentHtml,
          Photo: getLocalPhotoPath(),
        },
        getLocalToken()
      );
    }
  }

  doInsertMessageCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      this.setState({
        editorState: createEditorStateWithText(""),
      });
      console.log(jsonObj);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  createMarkup(text) {
    return { __html: text };
  }

  isPinnedLink(ispinned, id, username) {
    if (username === getLocalUsername()) {
      return (
        <div>
          <a
            className="dropdown-item pinCSS"
            href="#"
            onClick={(e) => this.OnPinMessage(e, ispinned, id)}
          >
            {ispinned === 1 ? "Unpin from Top" : "Pin to Top"}
          </a>

          <a
            className="dropdown-item pinCSS"
            href="#"
            onClick={(e) => this.OnDeleteMessage(e, id)}
          >
            Delete Message
          </a>
        </div>
      );
    }

    return (
      <div>
        <a
          className="dropdown-item pinCSS"
          href="#"
          onClick={(e) => this.OnPinMessage(e, ispinned, id)}
        >
          {ispinned === 1 ? "Unpin from Top" : "Pin to Top"}
        </a>
      </div>
    );
  }

  OnPinMessage(e, ispinned, id) {
    if (ispinned === 0) {
      ispinned = 1;
    } else {
      ispinned = 0;
    }

    console.log("OnPinMessage");
    PinMessage(
      this.doPinMessageCB.bind(this),
      {
        Username: getLocalUsername(),
        Id: id,
        IsPinned: ispinned,
      },
      getLocalToken()
    );
  }

  doPinMessageCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  OnDeleteMessage(e, id) {
    console.log("OnDeleteMessage");
    DeleteMessage(
      this.doDeleteMessageCB.bind(this),
      {
        Username: getLocalUsername(),
        Id: id,
      },
      getLocalToken()
    );
  }

  doDeleteMessageCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  async doCheckUploadImageNameConflictCB(jsonObj, fullPath, formData, isImage) {
    if (jsonObj.queryMessage === "Success") {
      await fetch(FILES_API_URL + "/users/upload", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (isImage) {
            this.setState({
              editorState: this.insertImage(fullPath),
            });
          } else {
            this.setState({
              editorState: this.insertLink(data.file, fullPath),
            });
          }
          this.setState({
            isSendMessageDisable: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isSendMessageDisable: false,
          });
        });
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  renderMessageBox() {
    var onlyFirstCount = 0;

    return this.state.data.map((msg) => {
      const {
        ID,
        Username,
        Alias,
        Message,
        Photo,
        CreatedOn,
        Name,
        IsPinned,
        StaffPic,
      } = msg;

      var shortenName = Name.substring(0, 2);
      if (GetPeriodString(CreatedOn).includes("Today")) {
        onlyFirstCount += 1;
      }

      return (
        <li
          className={IsPinned == 1 ? "clearfix pinnedCSS" : "clearfix"}
          key={ID}
          style={{ marginBottom: "0px", marginLeft: "0px" }}
        >
          {/* {onlyFirstCount === 1 ? (
            <div class="media media-meta-day">Today</div>
          ) : (
            ""
          )} */}
          <div className="message-data" style={{ marginBottom: "0px" }}>
            <div className="flex-container" style={{ width: "57vw" }}>
              <div>
                <span
                  className="message-data-time"
                  style={{ color: "grey", fontSize: "13px" }}
                >
                  <div
                    class="btn-group hover_drop_down"
                    style={{
                      display: "inline",
                      marginLeft: "13px",
                      marginTop: "-10px",
                    }}
                  >
                    <button
                      class="buttonPublicMember btn-sm"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={FILES_API_URL + StaffPic}
                        alt="Publicmember"
                        style={{
                          height: "35px",
                          width: "35px",
                          borderRadius: "50%",
                          marginTop: "-8px",
                        }}
                        onError={(e) => (
                          (e.target.onerror = null),
                          (e.target.src = DEFAULT_IMAGE)
                        )}
                      />
                    </button>
                  </div>

                  {Alias}
                  <span style={{ fontSize: "9px" }}>
                    {" "}
                    {GetPeriodString(CreatedOn)}
                  </span>
                </span>
              </div>
              <div>
                <a
                  class="btn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ marginTop: "10px", marginRight: "20px" }}
                >
                  <span class="fa fa-angle-down"></span>
                </a>
                <div class="dropdown-menu PinToTopCSS">
                  {this.isPinnedLink(IsPinned, ID, Username)}
                </div>
              </div>
            </div>
          </div>
          <div
            class="media media-chatSendMessage"
            style={{ marginTop: "-15px" }}
          >
            {" "}
            <div
              class="col-0 WholeColButton"
              style={{
                paddingLeft: "5px",
                paddingRight: "20px",
                fontSize: "18px",
                width: "10px",
                paddingTop: "0px",
              }}
            ></div>
            <div
              class="media-body"
              style={{
                fontWeight: "50",
              }}
            >
              <div
                class="media-bodyZeroPadding"
                dangerouslySetInnerHTML={this.createMarkup(Message)}
                style={{ textAlign: "left" }}
              ></div>
            </div>
          </div>
        </li>
      );
    });
  }

  onSelectFile = (event) => {
    this.uploadDroppedFiles(event.target.files);

    event.target.value = ""; //set this to empty, to fix where choose file A, then choose again file A, need to set empty so that it work on second time
  };

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  handleClick = (event) => {
    this.hiddenFileInput.current.click();
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const { EmojiSuggestions, EmojiSelect } = this.emojiPlugin;
    const plugins = [
      this.mentionPlugin,
      this.imagePlugin,
      this.hashtagPlugin,
      this.emojiPlugin,
      this.linkifyPlugin,
      this.highlightPlugin,
    ];

    return (
      // <div id="main-content">
      <div>
        <div
          className="chat"
          style={{
            marginLeft: 0,
            width: "100%",
            backgroundColor: "white",
            padding: "0px",
          }}
        >
          <div
            className="chat-history autoHeightCSS clearfixSendPinMessage"
            style={{
              padding: "0px",
            }}
          >
            <ul className="m-b-0">{this.renderMessageBox()}</ul>
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.messagesEnd = el;
              }}
            ></div>
          </div>
        </div>
        <div
          className="options"
          style={{ marginTop: "10px", marginBottom: "5px" }}
        >
          <EmojiSelect />
          <button
            type="button"
            class="btn btn-default"
            title="Upload"
            onClick={this.handleClick}
            style={{
              marginLeft: "5px",
              marginTop: "-5px",
              borderRadius: "1.5em",
              border: "1px solid #ddd",
              width: "52.5px",
              height: "31.7px",
            }}
          >
            <i class="fa fa-files-o"></i>
          </button>
          <input
            type="file"
            name="file"
            multiple
            ref={this.hiddenFileInput}
            style={{ display: "none" }}
            onChange={this.onSelectFile}
          />
        </div>
        <div
          className="editor"
          onClick={this.focus}
          style={{ overflowY: "scroll", height: "90px" }}
        >
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            handlePastedFiles={this.uploadPastedFiles.bind(this)}
            handleDroppedFiles={this.handleDroppedFiles.bind(this)}
            plugins={plugins}
            ref={(element) => {
              this.editor = element;
            }}
          />
          <EmojiSuggestions />
          <MentionSuggestions
            onSearchChange={this.onSearchChange}
            suggestions={this.state.suggestions}
            // onAddMention={this.onAddMention}
          />
        </div>
        <div className="flex-containerMessageBottom">
          <div></div>
          <div>
            <Button
              style={{ marginTop: "-30px" }}
              variant="primary"
              onClick={this.OnSendMessage.bind(this)}
              disabled={this.state.isSendMessageDisable}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
