import React, { useState, useRef } from "react";
import ReactFileReader from "react-file-reader";
import Papa from "papaparse";
import {
  ImportPublicHoliday,
  GetAllPublicHolidayByYear,
  CreateHoliday,
  DeleteHoliday,
} from "../../../api/AdminAPI";
import { mySwalAlertFailed, loadingpage } from "../../../components/Misc";
import { getLocalToken, getLocalUsername } from "../../../context/AuthContext";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import moment from "moment";
import Pagination from "react-js-pagination";
import { textValidation } from "../../../components/Validation";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Datetime from "react-datetime";
import {
  FILES_API_URL,
  FILES_BASE_URL,
} from "../../../components/ConstantAPI";
import { CSVLink } from "react-csv";

function PublicHoliday(props) {
  const [year, setYear] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [itemCountPerPage, setItemCountPerPage] = React.useState(50);
  const [data, setData] = React.useState([]);
  const [showDialog, setShowDialog] = React.useState(false);
  const [holidayInvalid, setHolidayInvalid] = React.useState("");
  const [holiday, setHoliday] = React.useState("");
  const [dateInvalid, setDateInvalid] = React.useState("");
  const [holidayDate, setDate] = React.useState("");
  const MySwal = withReactContent(Swal);
  const [firstTime, setFirstTime] = React.useState(0);
  const focusRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [csvData, setCsvData] = React.useState([]);
  const holidayUrl =
    FILES_API_URL + "/" + FILES_BASE_URL + "/holidays/files/publicholiday.csv";

  React.useEffect(() => {
    var now = new Date();

    setYear(now.getFullYear());
    GetAllHolidays(now.getFullYear());
  }, []);

  //hook to check selectedPage changed before refresh
  React.useEffect(() => {
    GetAllHolidays(year);
  }, [selectedPage]);

  React.useEffect(() => {
    if (
      focusRef.current !== undefined &&
      focusRef.current !== null &&
      firstTime === 0
    ) {
      focusRef.current.focus();
      setFirstTime(1);
    }
  });

  /*const csvData = [
    ["Date", "Details"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"],
  ]; */

  const handleClose = () => {
    setShowDialog(false);
    resetDialogData();
  };

  function downloadExcel() {
    fetch(holidayUrl).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "publicholiday.csv";
        a.click();
      });
    });
  }

  function uploadDroppedFilesToServer(file) {
    const formData = new FormData();
    formData.append("qqfile", file);
    formData.append("username", "holidays");
    formData.append("folder", "files");

    fetch(FILES_API_URL + "/users/upload", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("upload successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleFiles(files) {
    if (files[0].name !== "publicholiday.csv") {
      alert(
        "Invalid File Name, File Name must be in this format publicholiday.csv"
      );
      return;
    }

    var reader = new FileReader();
    reader.onload = function (e) {
      // Use reader.result
      var data = reader.result;

      Papa.parse(data, {
        complete: getData,
      });
    };

    uploadDroppedFilesToServer(files[0]);
    reader.readAsText(files[0]);
  }

  const handleSearch = () => {
    GetAllHolidays(year);
  };

  function OnCreate() {
    var nameInv = textValidation("Holiday Name", holiday, 2);
    setHolidayInvalid(nameInv);

    var dateInv = null;
    if (!moment(holidayDate, "DD/MM/yyyy HH:mm").isValid) {
      setDateInvalid(dateInv);
      dateInv = "Invalid Date";
    }

    if (nameInv === null && dateInv === null) {
      CreateHoliday(
        doCreateCB.bind(this),
        {
          Name: holiday,
          HolidayDate: holidayDate,
          UpdatedBy: getLocalUsername(),
        },
        getLocalToken()
      );
    }
  }

  function doCreateCB(jsonObj) {
    if (jsonObj.message === "Success") {
      resetDialogData();
      GetAllHolidays(year);
      //dismiss the dialog
      handleClose();
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function GetAllHolidays(year) {
    setIsLoading(true);
    // console.log(year);
    // console.log(selectedPage);
    if (year > 0) {
      GetAllPublicHolidayByYear(
        doGetDataCB.bind(this),
        {
          SelectedPage: selectedPage,
          Year: year,
        },
        getLocalToken()
      );
    }
  }

  function doGetDataCB(jsonObj) {
    setIsLoading(false);
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      // console.log(temp);

      let aggData = [];
      let sampleData = [];
      sampleData.push("Date");
      sampleData.push("Details");

      aggData.push(sampleData);

      for (var i = 0; i < temp.length; i++) {
        sampleData = [];
        sampleData.push(moment(temp[i].Date).format("DD-MMM-YYYY"));
        sampleData.push(temp[i].Name);
        aggData.push(sampleData);
      }

      setCsvData(aggData);
      setTotalPages(itemCountPerPage * jsonObj.page); //some kind of hack so that API doesn't need to return total count
      setData(temp);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function getData(result) {
    ImportPublicHoliday(
      doImportCB.bind(this),
      {
        data: result.data,
      },
      getLocalToken()
    );
    // this.setState({ data: result.data });
  }

  function doImportCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      GetAllHolidays(year);
      // console.log(jsonObj);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
    setSelectedPage(pageNumber);
  }

  function resetDialogData() {
    setDateInvalid("");
    setHolidayInvalid("");
    setHoliday("");
    setDate("");
  }

  function onDelete(e, usrn) {
    MySwal.fire({
      title: "Confirmation",
      text: "Are you sure you want to delete this holiday ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        DeleteHoliday(
          doDeleteCB.bind(this),
          {
            Id: usrn,
          },
          getLocalToken()
        );
      }
    });
  }

  function doDeleteCB(jsonObj) {
    console.log(jsonObj);
    if (jsonObj.message === "Success") {
      GetAllHolidays(year);
      return MySwal.fire({
        title: "Deleted!",
        text: "Your setting has been deleted.",
        type: "success",
        confirmButtonText: "OK",
      });
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function renderTableData() {
    return data.map((user) => {
      const { ID, Name, Date } = user;
      var dtMoment = moment(Date);

      return (
        <tr key={ID}>
          <td>
            <span>{dtMoment.format("DD-MM-YYYY")}</span>
          </td>
          <td>
            <span>{Name}</span>
          </td>
          <td>
            <button
              type="button"
              className="btn btn-sm btn-outline-danger js-sweetalert"
              title="Delete"
              data-type="confirm"
              onClick={(e) => onDelete(e, ID)}
            >
              <i className="fa fa-trash-o"></i>
            </button>
          </td>
        </tr>
      );
    });
  }

  return (
    <div id="main-content">
      {/* {isLoading ? loadingpage() : ""} */}
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2>Malaysia Public Holiday</h2>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="header">
                <ul>
                  <div>
                    <Form.Control
                      type="number"
                      style={{ width: "30%", display: "inline" }}
                      placeholder="Year"
                      value={year}
                      onChange={(e) => {
                        setYear(e.target.value);
                      }}
                    />
                    <Button variant="secondary" onClick={handleSearch}>
                      Search
                    </Button>
                    &nbsp; &nbsp;
                    {csvData.length === 0 ? (
                      <a
                        href="#"
                        style={{ textDecoration: "underline" }}
                        onClick={() => downloadExcel()}
                      >
                        Download Sample
                      </a>
                    ) : (
                      <CSVLink filename={"publicholiday.csv"} data={csvData}>
                        Download Sample
                      </CSVLink>
                    )}
                  </div>
                </ul>

                <ul className="header-dropdown">
                  <li>
                    <button
                      className="btn btn-info"
                      onClick={() => setShowDialog(true)}
                    >
                      Create Holiday
                    </button>
                  </li>
                  &nbsp; &nbsp;
                  <li>
                    <ReactFileReader
                      handleFiles={handleFiles}
                      fileTypes={".csv"}
                    >
                      <button className="btn btn-info">Upload</button>
                    </ReactFileReader>
                  </li>
                </ul>
              </div>
              <div className="body">
                <div className="table-responsive">
                  <table className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list">
                    <thead className="thead-dark">
                      <tr>
                        <th>Date</th>
                        <th>Holiday</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderTableData()}</tbody>
                  </table>
                  <div>
                    {data.length === 0 ? (
                      <div>No Records</div>
                    ) : (
                      <div>
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={selectedPage}
                          itemsCountPerPage={itemCountPerPage}
                          totalItemsCount={totalPages}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showDialog} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Setup New Holiday</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Holiday</Form.Label>
            <Form.Control
              placeholder="Name"
              ref={focusRef}
              value={holiday}
              onChange={(e) => {
                setHolidayInvalid("");
                setHoliday(e.target.value);
              }}
            />
            <span style={{ color: "red" }}>{holidayInvalid}</span>
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Date</Form.Label>
            <Datetime
              value={holidayDate}
              dateFormat="DD/MM/yyyy"
              closeOnSelect
              timeFormat={false}
              onChange={(e) => {
                setDateInvalid("");
                setDate(e);
              }}
            />
            <span style={{ color: "red" }}>{dateInvalid}</span>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={OnCreate}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PublicHoliday;
