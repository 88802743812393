import React from "react";
import {
  getLocalUsername,
  getLocalToken,
  setLocalViewProjectRefresh,
} from "../../../context/AuthContext";
import { Redirect } from "react-router-dom";
import { GetAllNotificationByUsername } from "../../../api/UserAPI";
import NotificationPopUp from "../../NotificationPopUp";
import {
  mySwalAlertFailed,
  getTaskDetail,
  RenderIcon,
  SetNotificationReadWithCB,
  GetPeriodString,
} from "../../../components/Misc";
import { useHistory } from "react-router-dom";
import moment from "moment";

function Notification(props: any) {
  const [data, setData] = React.useState([]);
  const [showDialog, setShowDialog] = React.useState(false);
  const [notiContent, setNotiContent] = React.useState("");
  const [notiIcon, setNotiIcon] = React.useState();
  let history = useHistory();

  React.useEffect(() => {
    ReloadData();
  }, []);

  function ReloadData() {
    GetAllNotificationByUsername(
      doGetDataCB,
      {
        FilterUsername: getLocalUsername(),
        SelectedPage: 1,
      },
      getLocalToken()
    );
  }

  function doGetDataCB(jsonObj: any) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      // console.log(temp);
      setData(temp);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function renderContent(isReadBy: any, content: any) {
    if (!isReadBy) {
      return (
        <h6>
          <u>
            <strong>{content}</strong>
          </u>
        </h6>
      );
    }
    return <h6>{content}</h6>;
  }

  function renderGotoProject(projectCode: any, notiID: any) {
    if (projectCode !== "") {
      return (
        <a
          href="#"
          onClick={() => {
            SetNotificationReadWithCB(
              notiID,
              getLocalUsername(),
              getLocalToken()
            );

            setLocalViewProjectRefresh(true);

            history.push({
              pathname: "/projectdetails",
              search: `?projectID=${projectCode}&userz=undefined`,
            });
          }}
        >
          <h6>Go to project</h6>
        </a>
      );
    }

    return null;
  }

  function renderNotificationData() {
    return data.map((noti) => {
      const {
        Username,
        RoleID,
        Content,
        TypeIcon,
        NotiID,
        ReadBy,
        From,
        CreatedOn,
        ProjectID,
        ProjectCode,
      } = noti;

      var typeDetail = getTaskDetail(TypeIcon);
      var readBy = ReadBy as string;
      var isReadBy = readBy.includes(getLocalUsername());

      var periodString = GetPeriodString(CreatedOn);

      return (
        <a
          key={NotiID}
          href="#"
          onClick={() => {
            SetNotificationReadWithCB(
              NotiID,
              getLocalUsername(),
              getLocalToken()
            );

            setLocalViewProjectRefresh(true);

            history.push({
              pathname: "/projectdetails",
              search: `?projectID=${ProjectCode}&userz=undefined`,
            });
          }}
          className="list-group-item list-group-item-action flex-column align-items-start"
        >
          <div
            className="media"
            /*onClick={() => {
              SetNotificationReadWithCB(
                NotiID,
                getLocalUsername(),
                getLocalToken()
              );

              setLocalViewProjectRefresh(true);

              history.push({
                pathname: "/projectdetails",
                search: `?projectID=${ProjectCode}&userz=undefined`,
              });
            }} */
          >
            <div
              className="media-left"
              style={{ marginRight: 50, fontSize: 25 }}
            >
              {RenderIcon(TypeIcon)}
            </div>
            <div className="media-body">
              <div
                className="text"
                style={{
                  whiteSpace: "normal",
                  width: "100%",
                }}
              >
                {renderContent(isReadBy, Content)}
              </div>
              <span className="timestamp" style={{ whiteSpace: "normal" }}>
                {moment(CreatedOn, "yyyy-MM-DD").format("DD/MM/yyyy")} -{" "}
                {periodString}
              </span>
            </div>
          </div>
        </a>
      );
    });
  }

  function readNotification(notiContent: any, icon: any, notiID: any) {
    setNotiIcon(icon);
    setNotiContent(notiContent);
    setShowDialog(true);
    SetNotificationReadWithCB(notiID, getLocalUsername(), getLocalToken());
  }

  const handleClose = () => {
    setShowDialog(false);
    ReloadData();
  };

  return (
    <div id="main-content">
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2>All Notifications</h2>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="header"></div>
          <div className="body">
            <div className="list-group">{renderNotificationData()}</div>
          </div>
        </div>
      </div>
      <NotificationPopUp
        show={showDialog}
        handleClose={handleClose}
        notiContent={notiContent}
        icon={notiIcon}
      />
    </div>
  );
}

export default Notification;
