import React, { useState, useRef } from "react";
import {
  GetAllChannel,
  GetAllGroups,
  GetAllTasks,
  CreateChannel,
  DeleteChannel,
  GetChannel,
} from "../../../api/AdminAPI";
import { OnCloneProject } from "../../../api/ProjectAPI";
import { getLocalToken, getLocalUsername } from "../../../context/AuthContext";
import Swal from "sweetalert2";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import { textValidation } from "../../../components/Validation";
import {
  mySwalAlertFailed,
  mySwalAlertSuccess,
  loadingpage,
} from "../../../components/Misc";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import Pagination from "react-js-pagination";
import Nestable from "react-nestable";
import { AiOutlineDrag } from "react-icons/ai";

const Channel = (props) => {
  const [searchCode, setSearchCode] = useState("");
  const [selectedGroupCode, setSelectedGroupCode] = useState("");
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState(0);
  const [selectedTaskCode, setSelectedTaskCode] = useState("");
  const [selectedTaskName, setSelectedTaskName] = useState("");
  const [selectedTaskId, setSelectedTaskId] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [IsUpdate, setIsUpdate] = useState(false);
  const [data, setData] = React.useState([]);
  const [channels, setChannels] = React.useState([]);
  const [selectedParent, setSelectedParent] = React.useState("");
  const [groupDataOption, setGroupDataOption] = React.useState([]);
  const [groupData, setGroupData] = React.useState([]);
  const [taskDataOption, setTaskDataOption] = React.useState([]);
  const [taskData, setTaskData] = React.useState([]);
  const [groupTaskData, setGroupTaskData] = React.useState([]);
  const MySwal = withReactContent(Swal);
  const [projectName, setProjectName] = useState("");
  const [dialogHeader, setDialogHeader] = useState("Create Channel");
  const [code, setCode] = useState("");
  const [channelID, setChannelID] = useState(0);
  const [name, setName] = useState("");
  const [sequence, setSequence] = useState(0);
  const [channelSequence, setChannelSequence] = useState(0);
  const [nameInvalid, setNameInvalid] = React.useState("");
  const [codeInvalid, setCodeInvalid] = React.useState("");
  const [sequenceInvalid, setSequenceInvalid] = React.useState("");
  const [showDialog, setShowDialog] = React.useState(false);
  const [showGroupDialog, setShowGroupDialog] = React.useState(false);
  const [groupHeader, setGroupHeader] = React.useState("");
  const [selectedGroupTask, setSelectedGroupTask] = React.useState("");
  const [activePage, setActivePage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [itemCountPerPage, setItemCountPerPage] = React.useState(50);
  const [prefix, setPrefix] = React.useState("");
  const [prefixInvalid, setPrefixInvalid] = React.useState("");
  const [firstTime, setFirstTime] = React.useState(0);
  const focusRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    GetSettings();
  }, []);

  //hook to check selectedPage changed before refresh
  React.useEffect(() => {
    GetSettings();
  }, [selectedPage]);

  React.useEffect(() => {
    if (
      focusRef.current !== undefined &&
      focusRef.current !== null &&
      firstTime === 0
    ) {
      focusRef.current.focus();
      setFirstTime(1);
    }
  });

  function setClearData() {
    setGroupData([]);
    setGroupTaskData([]);
    setTaskData([]);
  }

  const handleClose = () => {
    setShowDialog(false);
    resetDialogData();
  };

  const handleTaskClose = () => {
    setShowGroupDialog(false);
  };

  const handleBack = () => {
    OnCreateSetting();
    setShowGroupDialog(false);
    setShowDialog(true);
  };

  function GetSettings() {
    setIsLoading(true);
    GetAllChannel(
      doGetDataCB.bind(this),
      {
        FilterCode: searchCode,
        SelectedPage: selectedPage,
      },
      getLocalToken()
    );

    GetAllGroups(
      doGetGroupsDataCB.bind(this),
      {
        FilterCode: "",
        SelectedPage: selectedPage,
      },
      getLocalToken()
    );

    GetAllTasks(
      doGetTasksDataCB.bind(this),
      {
        FilterCode: "",
        SelectedPage: selectedPage,
      },
      getLocalToken()
    );
  }

  function populateParentChannel(channelCode) {
    //populate the channel
    let channelArr = [];

    channelArr.push({
      value: "",
      label: "No Parent",
    });

    //for channel combobox [which exclude ownself]
    for (var i = 0; i < data.length; i++) {
      if (data[i].ChannelCode !== channelCode) {
        channelArr.push({
          value: data[i].ChannelCode,
          label: data[i].ChannelName,
        });
      }
    }

    if (channelCode === "") {
      setSelectedParent("No Parent");
    }
    setChannels(channelArr);
  }

  function doGetTasksDataCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      let tagArr = [];
      for (var i = 0; i < temp.length; i++) {
        tagArr.push({
          value: temp[i].TaskCode,
          label: temp[i].TaskName,
          id: temp[i].Id,
        });
      }

      setTaskDataOption(tagArr);
      //if no selected data, set it on the first item
      if (selectedTaskCode === "") {
        setSelectedTaskCode(tagArr[0].value);
        setSelectedTaskName(tagArr[0].label);
        setSelectedTaskId(tagArr[0].Id);
      }
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function doGetGroupsDataCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      let tagArr = [];
      for (var i = 0; i < temp.length; i++) {
        tagArr.push({
          value: temp[i].GroupCode,
          label: temp[i].GroupName,
          id: temp[i].Id,
        });
      }

      setGroupDataOption(tagArr);
      //if no selected data, set it on the first item
      if (selectedGroupCode === "") {
        setSelectedGroupCode(tagArr[0].value);
        setSelectedGroupName(tagArr[0].label);
        setSelectedGroupId(tagArr[0].id);
      }
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    setSelectedPage(pageNumber);
  }

  function doGetDataCB(jsonObj) {
    setIsLoading(false);
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      console.log(temp);
      setData(temp);
      setTotalPages(itemCountPerPage * jsonObj.page); //some kind of hack so that API doesn't need to return total count
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function OnCloneSetting() {
    OnCloneProject(
      doNothingCallBack.bind(this),
      {
        MyChannelCode: code,
        ChannelCode: selectedParent,
        UpdatedBy: getLocalUsername(),
      },
      getLocalToken()
    );
  }

  function doNothingCallBack(jsonObj) {
    if (jsonObj.message !== "Success") {
      mySwalAlertFailed(jsonObj.message);
    } else {
      mySwalAlertSuccess("Clone Successfully");
    }
  }

  function OnCreateSetting() {
    var nameInv = textValidation("Name", name, 2);
    var codeInv = textValidation("Code", code, 2);
    var prefixInv = textValidation("Prefix", prefix, 1);
    setNameInvalid(nameInv);
    setCodeInvalid(codeInv);
    setPrefixInvalid(prefixInv);

    if (nameInv === null && codeInv === null && prefixInv === null) {
      let tempArr = [...groupData];
      let tempTaskArr = [...groupTaskData];
      let newArray = [];

      tempArr.map(function (val) {
        let newTaskArray = [];
        tempTaskArr.map(function (taskVal) {
          const groupTaskInfo = {
            TaskName: taskVal.TaskName,
            TaskCode: taskVal.TaskCode,
            GroupCode: taskVal.GroupCode,
          };

          if (val.GroupCode === taskVal.GroupCode) {
            newTaskArray.push(groupTaskInfo);
          }
        });

        const info = {
          GroupName: val.GroupName,
          GroupCode: val.GroupCode,
          tasks: newTaskArray,
        };

        newArray.push(info);
      });
      CreateChannel(
        doCreateCB.bind(this),
        {
          ChannelCode: code,
          ID: channelID,
          ChannelName: name,
          Sequence:
            channelSequence === "" || channelSequence === null
              ? 0
              : channelSequence,
          ChannelPrefix: prefix,
          IsUpdate: IsUpdate,
          UpdatedBy: getLocalUsername(),
          groups: newArray,
          Parent: selectedParent,
        },
        getLocalToken()
      );
    }
  }

  function doCreateCB(jsonObj) {
    if (jsonObj.message === "Success") {
      // resetDialogData();
      GetSettings();
      setIsUpdate(true);
      //dismiss the dialog
      // handleClose();
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function resetDialogData() {
    setName("");
    setCode("");
    setPrefix("");
    setSequence(0);
    setChannelSequence(0);
    setProjectName("");
    setSelectedParent("");
  }

  function onEdit(e, usrn) {
    GetChannel(
      doEditCB.bind(this),
      {
        FilterCode: usrn,
      },
      getLocalToken()
    );
  }

  function onGroupEdit(e, usrn, groupName) {
    setShowDialog(false);
    setGroupHeader("Edit " + groupName);
    setShowGroupDialog(true);
    setSelectedGroupTask(usrn);

    let tempGroupArr = [...groupTaskData];
    let newArray = [];
    let sequence = 0;
    tempGroupArr.map(function (val) {
      const info = {
        TaskName: val.TaskName,
        TaskCode: val.TaskCode,
        GroupCode: val.GroupCode,
        Sequence: sequence,
        id: val.Id,
      };

      if (usrn == val.GroupCode) {
        newArray.push(info);
        sequence += 1;
      }
    });
    setTaskData(newArray);
  }

  function onGroupDelete(e, usrn) {
    let tempArr = [...groupData];
    let newArray = [];
    tempArr.map(function (val) {
      const info = {
        GroupName: val.GroupName,
        GroupCode: val.GroupCode,
        id: val.id,
      };

      if (usrn !== val.GroupCode) {
        newArray.push(info);
      }
    });

    setGroupData(newArray);

    let tempArrTask = [...groupTaskData];
    let newTaskArr = [];
    tempArrTask.map(function (val) {
      const info = {
        TaskName: val.TaskName,
        TaskCode: val.TaskCode,
        GroupCode: val.GroupCode,
        Id: val.Id,
      };

      if (usrn !== val.GroupCode) {
        newTaskArr.push(info);
      }
    });

    setGroupTaskData(newTaskArr);
  }

  function onTaskDelete(e, usrn) {
    let tempArr = [...taskData];
    let newArray = [];
    tempArr.map(function (val) {
      const info = {
        TaskName: val.TaskName,
        TaskCode: val.TaskCode,
        GroupCode: val.GroupCode,
        Sequence: val.Sequence,
        id: val.id,
      };

      if (usrn !== val.TaskCode) {
        newArray.push(info);
      }
    });

    setTaskData(newArray);

    let tempArrTask = [...groupTaskData];
    let newTaskArr = [];
    tempArrTask.map(function (val) {
      const info = {
        TaskName: val.TaskName,
        TaskCode: val.TaskCode,
        GroupCode: val.GroupCode,
        Sequence: val.Sequence,
        Id: val.Id,
      };

      if (selectedGroupTask === val.GroupCode && usrn === val.TaskCode) {
        // do nothing
      } else {
        newTaskArr.push(info);
      }

      setGroupTaskData(newTaskArr);
    });
  }

  function doEditCB(jsonObj) {
    console.log(jsonObj);
    if (jsonObj.message === "Success") {
      setName(jsonObj.data.channelName);
      setCode(jsonObj.data.channelCode);
      setChannelID(jsonObj.data.id);
      setPrefix(jsonObj.data.channelPrefix);
      setChannelSequence(jsonObj.data.sequence);

      let tagArr = [];
      let tasksArr = [];
      for (var i = 0; i < jsonObj.data.groups.length; i++) {
        tagArr.push({
          GroupCode: jsonObj.data.groups[i].groupCode,
          GroupName: jsonObj.data.groups[i].groupName,
          id: jsonObj.data.groups[i].id,
        });

        let groupTaskItems = jsonObj.data.groups[i].tasks;

        for (var x = 0; x < groupTaskItems.length; x++) {
          tasksArr.push({
            GroupCode: jsonObj.data.groups[i].groupCode,
            TaskCode: groupTaskItems[x].taskCode,
            TaskName: groupTaskItems[x].taskName,
            Id: groupTaskItems[x].id,
          });
        }
      }

      setGroupData(tagArr);
      setGroupTaskData(tasksArr);

      setDialogHeader("Edit Channel");
      setShowDialog(true);
      setIsUpdate(true);

      populateParentChannel(jsonObj.data.channelCode);
      setSelectedParent(jsonObj.data.parent);
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function onDelete(e, usrn) {
    MySwal.fire({
      title: "Confirmation",
      text: "Are you sure you want to delete this channel ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        DeleteChannel(
          doDeleteCB.bind(this),
          {
            StatusCode: usrn,
          },
          getLocalToken()
        );
      }
    });
  }

  function onCloseDialog(e, usrn) {
    MySwal.fire({
      title: "Confirmation",
      text: "Are you sure you want close this channel ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        handleClose();
      }
    });
  }

  function onCloseGroupDialog(e, usrn) {
    MySwal.fire({
      title: "Confirmation",
      text: "Are you sure you want close this channel ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        setShowGroupDialog(false);
      }
    });
  }

  const handleTaskDropDownChange = (e) => {
    setSelectedTaskCode(e.value);
    setSelectedTaskName(e.label);
    setSelectedTaskId(e.id);
  };

  const handleDropDownChange = (e) => {
    setSelectedGroupCode(e.value);
    setSelectedGroupName(e.label);
    setSelectedGroupId(e.id);
  };

  const handleParentDropDownChange = (e) => {
    setSelectedParent(e.value);
  };

  const onTaskItemOnChange = (items, item) => {
    setTaskData(items);

    //just to repopulate the data for GroupTaskData
    let tempArrTask = [...groupTaskData];
    let groupTaskArr = [];
    let changesGroupArr = [];
    tempArrTask.map(function (val) {
      const info = {
        TaskName: val.TaskName,
        TaskCode: val.TaskCode,
        GroupCode: val.GroupCode,
        Id: val.Id,
      };

      if (items[0].GroupCode !== val.GroupCode) {
        groupTaskArr.push(info);
      } else {
        changesGroupArr.push(info);
      }
    });

    //now based on the new listing position, recompute it
    items.map(function (val) {
      var data = changesGroupArr.find((e) => e.TaskCode === val.TaskCode);
      groupTaskArr.push(data);
    });

    setGroupTaskData(groupTaskArr);
  };

  const renderTaskItem = ({ item, index }) => {
    const { TaskCode, TaskName } = item;

    return (
      <div className="row clearfix" key={index}>
        <div className="col-lg-1 col-md-6">
          <a draggable="true" style={{ cursor: "grab" }}>
            <AiOutlineDrag size={30} style={{ marginRight: "20px" }} />
          </a>
        </div>
        <div className="col-lg-3 col-md-6">{TaskName}</div>
        <div className="col-lg-3 col-md-6">
          <button
            type="button"
            className="btn btn-sm btn-outline-danger js-sweetalert"
            title="Delete"
            data-type="confirm"
            onClick={(e) => onTaskDelete(e, TaskCode)}
          >
            <i className="fa fa-trash-o"></i>
          </button>
        </div>
      </div>
    );
  };

  const onGroupItemOnChange = (items, item) => {
    setGroupData(items);
  };

  const renderGroupItem = ({ item, index }) => {
    const { GroupCode, GroupName } = item;

    return (
      <div className="row clearfix" key={index}>
        <div className="col-lg-1 col-md-6">
          <a draggable="true" style={{ cursor: "grab" }}>
            <AiOutlineDrag size={30} style={{ marginRight: "20px" }} />
          </a>
        </div>
        <div className="col-lg-3 col-md-6">{GroupName}</div>
        <div className="col-lg-3 col-md-6">
          <button
            type="button"
            className="btn btn-sm btn-outline-secondary"
            title="Edit"
            onClick={(e) => onGroupEdit(e, GroupCode, GroupName)}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            type="button"
            className="btn btn-sm btn-outline-danger js-sweetalert"
            title="Delete"
            data-type="confirm"
            onClick={(e) => onGroupDelete(e, GroupCode)}
          >
            <i className="fa fa-trash-o"></i>
          </button>
        </div>
      </div>
    );
  };

  function doDeleteCB(jsonObj) {
    console.log(jsonObj);
    if (jsonObj.message === "Success") {
      GetSettings();
      return MySwal.fire({
        title: "Deleted!",
        text: "Your channel has been deleted.",
        type: "success",
        confirmButtonText: "OK",
      });
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function searchElement(array, title) {
    return array.find((element) => {
      return element.GroupCode === title;
    });
  }

  function searchTaskElement(array, title) {
    return array.find((element) => {
      return element.TaskCode === title;
    });
  }

  function searchGroupTaskElement(array, title, groupTask) {
    return array.find((element) => {
      return element.TaskCode === title && element.GroupCode === groupTask;
    });
  }

  const AddGroupIntoSelection = (event) => {
    const info = {
      GroupName: selectedGroupName,
      GroupCode: selectedGroupCode,
      id: selectedGroupId,
    };

    let tempArr = [...groupData];
    if (!searchElement(tempArr, selectedGroupCode)) {
      tempArr.push(info);
      setGroupData(tempArr);
    }
  };

  const AddTaskIntoSelection = (event) => {
    let tempArr = [...taskData];
    let sequence = tempArr.length;

    const info = {
      TaskName: selectedTaskName,
      TaskCode: selectedTaskCode,
      GroupCode: selectedGroupTask,
      Sequence: sequence,
      id: selectedTaskId,
    };

    if (!searchTaskElement(tempArr, selectedTaskCode)) {
      tempArr.push(info);
      setTaskData(tempArr);
    }

    let tempGroupArr = [...groupTaskData];
    if (
      !searchGroupTaskElement(tempGroupArr, selectedTaskCode, selectedGroupTask)
    ) {
      tempGroupArr.push(info);
      setGroupTaskData(tempGroupArr);
    }
  };

  function renderTableData() {
    return data.map((settings) => {
      const { Id, Number, ChannelCode, ChannelName, Sequence } = settings;

      return (
        <tr key={Id}>
          <td>
            <span>{Number}</span>
          </td>
          <td>
            <span>{ChannelName}</span>
          </td>
          <td>
            <span>{Sequence}</span>
          </td>
          <td>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary"
              title="Edit"
              onClick={(e) => onEdit(e, ChannelCode)}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-danger js-sweetalert"
              title="Delete"
              data-type="confirm"
              onClick={(e) => onDelete(e, ChannelCode)}
            >
              <i className="fa fa-trash-o"></i>
            </button>
          </td>
        </tr>
      );
    });
  }

  return (
    <div id="main-content">
      {/* {isLoading ? loadingpage() : ""} */}
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2>Channels</h2>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="header">
                <ul className="header-dropdown">
                  <li>
                    <a
                      // href=";"
                      className="btn btn-info"
                      // data-toggle="modal"
                      // data-target="#addcontact"
                      onClick={() => {
                        setDialogHeader("Create New Channel");
                        setChannelID(0);
                        setShowDialog(true);
                        setClearData();
                        setIsUpdate(false);
                        populateParentChannel();
                      }}
                    >
                      Create New Channel
                    </a>
                  </li>
                </ul>
              </div>
              <div className="body">
                <div className="table-responsive">
                  <table className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list">
                    <thead className="thead-dark">
                      <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Sequence</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderTableData()}</tbody>
                  </table>
                  {data.length === 0 ? (
                    <div>No Records</div>
                  ) : (
                    <div>
                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={selectedPage}
                        itemsCountPerPage={itemCountPerPage}
                        totalItemsCount={totalPages}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showDialog} onHide={onCloseDialog}>
        <Modal.Header>
          <Modal.Title>{dialogHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form.Group controlId="formBasicEmail">
            <Form.Label>Code</Form.Label>
            <Form.Control
              placeholder="Code"
              ref={focusRef}
              value={code}
              autoComplete={"" + Math.random()}
              disabled={IsUpdate}
              onChange={(e) => {
                setCodeInvalid("");
                setCode(e.target.value);
              }}
            />
            <span style={{ color: "red" }}>{codeInvalid}</span>
          </Form.Group> */}
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Name </Form.Label>
            <Form.Control
              placeholder="Name"
              name="search"
              value={name}
              autoComplete={"" + Math.random()}
              onChange={(e) => {
                setNameInvalid("");
                setName(e.target.value);
                setCode(e.target.value);
              }}
            />
            <span style={{ color: "red" }}>{nameInvalid}</span>
            <br />
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Prefix</Form.Label>
              <Form.Control
                placeholder="Prefix"
                value={prefix}
                autoComplete={"" + Math.random()}
                onChange={(e) => {
                  setPrefixInvalid("");
                  setPrefix(e.target.value);
                }}
              />
              <span style={{ color: "red" }}>{prefixInvalid}</span>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Sequence</Form.Label>
              <Form.Control
                placeholder="Sequence"
                value={channelSequence}
                autoComplete={"" + Math.random()}
                onChange={(e) => {
                  setChannelSequence(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Label>Parent</Form.Label>
            <Select
              style={{ width: "100px" }}
              placeholder=""
              options={channels}
              onChange={handleParentDropDownChange}
              value={channels.find((obj) => obj.value === selectedParent)}
            />
            <br />
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Groups</Form.Label>
              <Select
                style={{ width: "100px" }}
                placeholder=""
                options={groupDataOption}
                onChange={handleDropDownChange}
                value={groupDataOption.find(
                  (obj) => obj.value === selectedGroupCode
                )}
              />
              <br />
              <a
                onClick={() => AddGroupIntoSelection()}
                className="btn btn-info"
              >
                Add Group
              </a>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Group Selection</Form.Label>
              <div className="row clearfix">
                <div className="col-lg-1 col-md-6"></div>
                <div
                  className="col-lg-3 col-md-6"
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Name
                </div>
                <div
                  className="col-lg-3 col-md-6"
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Action
                </div>
              </div>
              <br />
              <Nestable
                items={groupData}
                renderItem={renderGroupItem}
                onChange={onGroupItemOnChange}
                maxDepth="1"
              />
            </Form.Group>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {!IsUpdate ? (
            ""
          ) : (
            <Button variant="primary" onClick={OnCloneSetting}>
              Clone From Parent
            </Button>
          )}
          <Button variant="primary" onClick={OnCreateSetting}>
            Save
          </Button>
          <Button variant="secondary" onClick={onCloseDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showGroupDialog} onHide={onCloseGroupDialog}>
        <Modal.Header>
          <Modal.Title>{groupHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Task</Form.Label>
              <Select
                style={{ width: "100px" }}
                placeholder=""
                options={taskDataOption}
                onChange={handleTaskDropDownChange}
                value={taskDataOption.find(
                  (obj) => obj.value === selectedTaskCode
                )}
              />
              <br />
              <a
                onClick={() => AddTaskIntoSelection()}
                className="btn btn-info"
              >
                Add Task
              </a>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Task Selection</Form.Label>
              <div className="row clearfix">
                <div className="col-lg-1 col-md-6"></div>
                <div
                  className="col-lg-3 col-md-6"
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Name
                </div>
                <div
                  className="col-lg-3 col-md-6"
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Action
                </div>
              </div>
              <br />
              <Nestable
                items={taskData}
                renderItem={renderTaskItem}
                onChange={onTaskItemOnChange}
                maxDepth="1"
              />
              {/* <div className="table-responsive">
                  <table className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list">
                    <thead className="thead-dark">
                      <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderTaskData()}</tbody>
                  </table>
                </div> */}
            </Form.Group>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleBack}>
            Save & Back
          </Button>
          {/* <Button variant="secondary" onClick={handleTaskClose}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Channel;
