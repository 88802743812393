import React, { useState, useCallback } from "react";
import { GetAllProjectSetting } from "../../../api/AdminAPI";
import {
  GetProjectDetails,
  UpdateTask,
  CreateTask,
  DeleteTask,
  DeleteProject,
  UpdateProject,
  GetProjectsWithChannelCode,
  GetAllAttachmentByProjectTaskID,
  GetProjectsWithUsername,
  GetAllProjectChannelByChildOrParent,
  GetProjectDetailByProjectCodeAPI,
  GetProjectStatusByProjectCode,
  GetProjectLogs,
} from "../../../api/ProjectAPI";
import {
  getLocalToken,
  getLocalUsername,
  getLocalViewProjectRefresh,
  setLocalViewProjectRefresh,
  getLocalRole,
  useAuth,
  setSelectedChannel,
} from "../../../context/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  mySwalAlertFailed,
  getTimeArr,
  getArrProjectStatus,
  loadingpage,
} from "../../../components/Misc";
import { Modal, Form } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import queryString from "query-string";
import moment from "moment";
import { FILES_API_URL, DEFAULT_IMAGE } from "../../../components/ConstantAPI";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import SendMessage from "../../User/SendMessage";
import PinMessage from "../../User/PinMessage";
import AttachmentBox from "../../User/AttachmentBox";
import "./Details.css";
import { useHistory } from "react-router-dom";
import debounce from "lodash/debounce";
import Nestable from "react-nestable";
import { AiOutlineMore } from "react-icons/ai";
import { GetPeriodString } from "../../../components/Misc";
import { GetAllPublicHolidayByYear } from "../../../api/AdminAPI";
import SelectSearch from "react-select-search";
import { useMediaQuery } from "react-responsive";
import TextareaAutosize from "react-textarea-autosize";

const ProjectDetails = (props) => {
  let history = useHistory();
  const { holidays, projectTaskID, setProjectTaskIDMain } = useAuth();
  const [staffData, setStaffData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [timeCB, setTimeCB] = useState([]);
  const [starttimeCB, setStartTimeCB] = useState([]);
  const [relatedStaffData, setRelatedStaffData] = useState([]);
  const [projectLogData, setProjectLogData] = useState([]);
  const [priorityData, setPriorityData] = useState([]);
  const [projectStatus, setProjectStatus] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [holidayData, setData] = React.useState([]);
  const [projectRelatedList, setProjectRelatedList] = React.useState([]);
  const MySwal = withReactContent(Swal);
  const [channelCode, setChannelCode] = useState("");
  const [detailsRunningNo, setDetailsRunningNo] = React.useState("");
  const [selectedId, setSelectedId] = React.useState("");
  const [DetailsProjectName, setDetailsProjectName] = React.useState("");
  const [detailsRemark, setDetailsRemark] = React.useState("");
  const [DetailsDuration, setDetailsDuration] = React.useState(1);
  const [detailsStatus, setDetailsStatus] = React.useState("");
  const [testingData, setTestingData] = useState([]);
  const [channelName, setChannelName] = React.useState("");
  const [taskData, setTaskData] = React.useState([]);
  const [arrTaskAttachment, setArrTaskAttachment] = useState([]);
  const [showDialog, setShowDialog] = React.useState(false);
  const [taskName, setTaskName] = React.useState("");
  const [detailsProjectId, setDetailsProjectId] = React.useState(0);
  const [detailsPublishDate, setDetailsPublishDate] = React.useState("");
  const [detailsProjectCode, setDetailsProjectCode] = React.useState("");
  const [parentCode, setParentCode] = React.useState("");
  const [selectedUserz, setSelectedUserz] = React.useState("undefined");
  const [detailsProjectStatus, setDetailsProjectStatus] = React.useState(-1); //-1 (default) 0(Not complete) 1(Completed) 2(KIV)
  const [populateData, setPopulateData] = React.useState(false);
  const [selectedProjectStatus, setSelectedProjectStatus] = React.useState("");
  const [arrProjectStatus, setArrProjectStatus] = React.useState([]);
  const [isRefresh, setIsRefresh] = React.useState(0);
  const [isRefreshProStatusChanged, setIsRefreshProStatusChanged] =
    React.useState(0);
  const [sortById, setSortById] = React.useState("asc");
  const [sortByName, setSortByName] = React.useState("asc");
  const [sortByGroup, setSortByGroup] = React.useState("asc");
  const [sortByTask, setSortByTask] = React.useState("asc");
  const [sortByAssignee, setSortByAssignee] = React.useState("asc");
  const [sortByTaskStatus, setSortByTaskStatus] = React.useState("asc");
  const [sortByStaffTask, setSortByStaffTask] = React.useState("asc");
  const [sortByProject, setSortByProject] = React.useState("asc");
  const [updateDueDuration, setUpdateDueDuration] = React.useState(false);
  const [projectType, setProjectType] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showLog, setShowLog] = React.useState(false);
  const [showSlide, setShowSlide] = React.useState(false);
  const [friends, setFriends] = React.useState([]);
  const [sortByProjectTaskName, setSortByProjectTaskName] =
    React.useState("asc");
  const isSmallSize = useMediaQuery({ query: "(max-width: 1500px)" });
  const isSmallHeight = useMediaQuery({ query: "(max-height: 1000px)" });

  const disableWeekend = (current) => {
    const yesterday = moment().subtract(1, "day");

    //disable saturday + sunday + past day
    return current.isAfter(yesterday);
  };

  const disableFutureDt = (current) => {
    const yesterday = moment().subtract(1, "day");

    //disable saturday + sunday + past day
    return (
      current.day() !== 0 &&
      current.day() !== 6 &&
      current.isAfter(yesterday) &&
      holidayData !== null &&
      !holidayData.includes(current.format("DD/MM/yyyy"))
    );
  };

  //run only once
  React.useEffect(() => {
    let arr = getTimeArr();

    setTimeCB(arr);

    arr = getTimeArr();
    setStartTimeCB(arr);

    arr = getArrProjectStatus();
    setArrProjectStatus(arr);
    GetAllHolidays();
  }, []);

  //run everytime a state changes
  React.useEffect(() => {
    let params = queryString.parse(window.location.search);
    let singleChannelCode = params.channelCode;
    let userz = params.userz;
    let projectID = params.projectID;

    let isLocalViewProjectRefresh = getLocalViewProjectRefresh();
    setLocalViewProjectRefresh(false); //after get the data, reset to false so that it doesn't refresh

    setSelectedUserz(userz);
    //cater for case where projectID is passed in. This will first query the data for this project, next load channel and others data
    if (
      projectID !== "undefined" &&
      projectID !== undefined &&
      isLocalViewProjectRefresh === true
    ) {
      //fix the bugs where in notification, press in the project ID first time works, second time press again on the same ID, some data goes missing
      if (detailsProjectCode !== projectID) {
        resetChannel();
        resetDetailsPage();
        setIsLoading(true);
        setDetailsProjectCode(projectID);
        GetProjectDetailByProjectCode(projectID);
      }
    } else if (
      userz !== "undefined" &&
      userz !== undefined &&
      (selectedUserz === undefined || selectedUserz === "undefined")
    ) {
      setShowSlide(false);
      setDetailsProjectCode("");
      setIsLoading(true);
      //need to check selectedUserz else it will keep run
      // i feel don't need reset since MyTask is not changing between channel
      // resetChannel();
      // resetDetailsPage();
      setSelectedProjectStatus("Incomplete");
      loadTaskByUsername(userz, "Incomplete");
      setChannelCode("");
      setChannelName(params.channelName);
      setParentCode(params.parent);
    } else if (
      channelCode !== singleChannelCode &&
      userz === "undefined" &&
      projectID === "undefined"
    ) {
      setShowSlide(false);
      setDetailsProjectCode("");
      setIsLoading(true);
      //need to check selectedUserz else it will come in here and reset the data
      resetChannel();
      resetDetailsPage();
      loadChannel(singleChannelCode, "Incomplete");
      setSelectedProjectStatus("Incomplete");
      setChannelCode(singleChannelCode);
      setChannelName(params.channelName);
      setParentCode(params.parent);
      setPopulateData(false);
      setDetailsPublishDate("");
    } else if (isRefresh === 1) {
      setIsLoading(true);

      if (projectID !== "undefined" && projectID !== undefined) {
        loadChannel(channelCode, selectedProjectStatus);
      } else if (userz !== "undefined" && userz !== undefined) {
        loadTaskByUsername(userz);
      } else {
        resetChannel();
        resetDetailsPage();
        loadChannel(singleChannelCode, selectedProjectStatus);
        setChannelCode(singleChannelCode);
        setChannelName(params.channelName);
        setParentCode(params.parent);
        setPopulateData(false);
        setDetailsPublishDate("");
      }

      setIsRefresh(0); //after refresh reset it
    } else if (isRefreshProStatusChanged === 1) {
      //this triggered whenever the status changed, and I want to reflect the Projest Listing > Task Name
      setIsRefreshProStatusChanged(0);
      if (
        projectID !== "undefined" &&
        projectID !== undefined &&
        userz !== undefined &&
        userz !== "undefined"
      ) {
        //somehow MyTask will run here also [when first time login], so do the same as MyTask
        loadTaskByUsername(userz);
      } else if (userz !== "undefined" && userz !== undefined) {
        //My Task
        loadTaskByUsername(userz);
      } else {
        loadChannel(channelCode, selectedProjectStatus); //Channel
      }
    }
  });

  function GetAllHolidays() {
    GetAllPublicHolidayByYear(
      doGetHolidayDataCB.bind(this),
      {
        SelectedPage: 0,
        Year: 0,
      },
      getLocalToken()
    );
  }

  function doGetHolidayDataCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let tempArrHoliday = JSON.parse(jsonObj.listing);

      let tempHolidayDate = [];
      tempArrHoliday.map((element) => {
        let tempDateString = moment(element.Date);

        tempHolidayDate.push(tempDateString.format("DD/MM/yyyy"));
      });

      setData(tempHolidayDate);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function GetProjectDetailByProjectCode(code) {
    GetProjectDetailByProjectCodeAPI(
      doGetProjectDetailByProjectCodeCB.bind(this),
      {
        ProjectCode: code,
      },
      getLocalToken()
    );
  }

  function doGetProjectDetailByProjectCodeCB(jsonObj) {
    setIsLoading(false);
    if (jsonObj.queryMessage === "Success") {
      // below is to filter by project status
      // setSelectedProjectStatus(jsonObj.projectStatus);
      // loadChannel(jsonObj.channelCode, jsonObj.projectStatus);

      var projState = "Incomplete";
      var projStateInt = "0";
      if (jsonObj.projectStatus === "Completed") {
        projState = "Completed";
        projStateInt = "1";
      } else if (jsonObj.projectStatus === "KIV") {
        projState = "KIV";
        projStateInt = "2";
      }

      //below is to filter by All
      setSelectedProjectStatus(projState);
      loadChannel(jsonObj.channelCode, projState);

      setChannelCode(jsonObj.channelCode);
      setChannelName(jsonObj.channelName);
      setParentCode(jsonObj.parent);

      populateDetailsPage(
        jsonObj.projectCode,
        jsonObj.projectName,
        jsonObj.runningNo,
        jsonObj.id,
        jsonObj.remark,
        jsonObj.publishDate,
        "",
        projStateInt
      );
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function GetProjectListing(channelCode, projStatus) {
    setProjectData([]);
    GetProjectsWithChannelCode(
      doGetProjectListingCB.bind(this),
      {
        ChannelCode: channelCode,
        ProjectStatus: projStatus,
        Username: getLocalUsername(),
      },
      getLocalToken()
    );
  }

  function doGetProjectListingCB(jsonObj) {
    setIsLoading(false);
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      setProjectData(temp);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function GetProjectListingByUsername(userz, e) {
    setProjectData([]);
    GetProjectsWithUsername(
      doGetProjectListingByUsernameCB.bind(this),
      {
        Username: userz,
        ProjectStatus: e === null ? selectedProjectStatus : e,
      },
      getLocalToken()
    );
  }

  function doGetProjectListingByUsernameCB(jsonObj) {
    setIsLoading(false);
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      setProjectData(temp);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function loadChannel(channelCode, projectStatus) {
    GetProjectListing(channelCode, projectStatus);
    // GetSettings();//this is to get all channels and store to [data] which i don't think we need this anymore

    PopulatePriorityData();
    PopulateJobStatus();
  }

  function loadTaskByUsername(userz, e) {
    GetProjectListingByUsername(userz, e === undefined ? null : e);
    PopulatePriorityData();
    PopulateJobStatus();
  }

  function resetChannel() {
    setProjectData([]);
    setStaffData([]);
    setRelatedStaffData([]);
    setProjectLogData([]);
    setTestingData([]);
    setProjectRelatedList([]);

    resetTaskData();
  }

  function resetTaskData() {
    setTaskData([]);
    setProjectTaskIDMain(0);
    GetAttachmentByProjectTaskID(0);
    setTaskName("");
  }

  function renderStaffInCharge() {
    return relatedStaffData.map((staffData, index) => {
      let staffPic = FILES_API_URL + staffData.PhotoPath;
      if (staffData.InvolveProject === "Yes")
        return (
          <div
            class="btn-group hover_drop_down"
            style={{
              float: "right",
              display: "inline",
            }}
          >
            <button class="buttonPublicMember btn-sm">
              <img
                src={staffPic}
                style={{
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                }}
                onError={(e) => (
                  (e.target.onerror = null), (e.target.src = DEFAULT_IMAGE)
                )}
              />
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div class="DropDownUSerInfoInamge">
                <img
                  src={staffPic}
                  style={{
                    height: "200px",
                    width: "200px",
                    marginTop: "-8px",
                  }}
                  onError={(e) => (
                    (e.target.onerror = null), (e.target.src = DEFAULT_IMAGE)
                  )}
                />
              </div>
              <label
                class="flex-containerCenterVerticalHorizontal asanaFontSize"
                style={{ marginTop: "5px", lineHeight: "10px" }}
              >
                {staffData.Alias}
              </label>
            </ul>
          </div>
        );
    });
  }

  function PopulateProjectLogs() {
    GetProjectLogs(
      doGetProjectLogsCallback.bind(this),
      {
        ProjectCode: detailsProjectCode,
      },
      getLocalToken()
    );
  }

  function doGetProjectLogsCallback(jsonObj) {
    if (jsonObj.message !== "Success") {
      mySwalAlertFailed(jsonObj.message);
    } else {
      let projLog = JSON.parse(jsonObj.listing);
      let projLogArr = [];
      for (var i = 0; i < projLog.length; i++) {
        projLogArr.push({
          text: projLog[i].ProjectDescription,
          name: projLog[i].ActionBy,
          shortName: projLog[i].ShortName,
          actionOn: projLog[i].ActionOn,
          staffPic: projLog[i].StaffPic,
        });
      }

      for (var i = 0; i < 3; i++) {
        projLogArr.push({
          text: "",
          name: "",
          shortName: "",
          actionOn: "",
          staffPic: "",
        });
      }

      setProjectLogData(projLogArr);
      setShowLog(true);
    }
  }

  function doNothingCallBack(jsonObj) {
    if (jsonObj.message !== "Success") {
      mySwalAlertFailed(jsonObj.message);
    } else {
      if (jsonObj.refreshNeeded === 1) {
        //refresh channel's project listing
        setIsRefresh(1);
      }

      if (jsonObj.retrieveProjectStatus === 1) {
        //update project status
        GetProjectStatusByProjectCodeMethod(jsonObj.projectCode);
        setIsRefreshProStatusChanged(1);
      }
    }
  }

  function GetProjectStatusByProjectCodeMethod(projectCode) {
    GetProjectStatusByProjectCode(
      doGetProjectStatusByProjectCodeCB.bind(this),
      {
        ProjectCode: projectCode,
      },
      getLocalToken()
    );
  }

  function doGetProjectStatusByProjectCodeCB(jsonObj) {
    if (jsonObj.message === "Success") {
      setDetailsStatus(jsonObj.projectStatus);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  const delayedOnUpdateProjectData = useCallback(
    debounce(
      (e, section, id, debounceData) =>
        OnUpdateProjectData(e, section, id, debounceData),
      1000
    ),
    []
  );

  function OnUpdateProjectData(e, section, id, debounceData) {
    let dataz = projectData;
    if (debounceData !== undefined) {
      dataz = debounceData;
    }

    if (
      id === "" ||
      (section === "publishdate" &&
        (!moment(e).isValid() || moment(e).year() > 9999))
    ) {
      // skip
      alert("Invalid Date");
    } else {
      console.log(e);
      UpdateProject(
        doNothingCallBack.bind(this),
        {
          id: id,
          Section: section,
          Value: e,
          UpdatedBy: getLocalUsername(),
        },
        getLocalToken()
      );
    }

    let newData = [];
    let tempArrTask = [...dataz];
    //this is for case where user keep on update task status
    //then fast fast press 'Mark as complete' Just for the sake of hiding the slide [setShowSlide]
    let foundStatus = false;

    tempArrTask.map((element) => {
      if (element.id == id) {
        if (section === "title") {
          newData.push({
            RunningNo: element.RunningNo,
            ProjectCode: element.ProjectCode,
            id: element.id,
            ProjectName: e,
            Remark: element.Remark,
            PublishDate: element.PublishDate,
            ProjectStatus: element.ProjectStatus,
            PublishDateWithoutYear: element.PublishDateWithoutYear,
            Status: element.Status,
            Assignee: element.Assignee,
            AssigneeEndDate: element.AssigneeEndDate,
            TaskNameAssigned: element.TaskNameAssigned,
            AssigneeFullEndDate: element.AssigneeFullEndDate,
          });

          setDetailsProjectName(e);
        } else if (section === "publishdate") {
          newData.push({
            RunningNo: element.RunningNo,
            ProjectCode: element.ProjectCode,
            id: element.id,
            ProjectName: element.ProjectName,
            PublishDate: moment(e, "DD/MM/yyyy").format("DD/MM/yyyy"),
            Remark: element.Remark,
            AssigneeEndDate: element.AssigneeEndDate,
            ProjectStatus: element.ProjectStatus,
            PublishDateWithoutYear: moment(e, "DD/MM/yyyy").format("DD/MMM"),
            Status: element.Status,
            Assignee: element.Assignee,
            TaskNameAssigned: element.TaskNameAssigned,
            AssigneeFullEndDate: element.AssigneeFullEndDate,
          });

          setDetailsPublishDate(e);
        } else if (section === "remark") {
          newData.push({
            RunningNo: element.RunningNo,
            ProjectCode: element.ProjectCode,
            id: element.id,
            ProjectName: element.ProjectName,
            Remark: e,
            ProjectStatus: element.ProjectStatus,
            PublishDate: element.PublishDate,
            PublishDateWithoutYear: element.PublishDateWithoutYear,
            Status: element.Status,
            AssigneeEndDate: element.AssigneeEndDate,
            AssigneeFullEndDate: element.AssigneeFullEndDate,
            Assignee: element.Assignee,
            TaskNameAssigned: element.TaskNameAssigned,
          });
        } else if (section === "status") {
          foundStatus = true;

          newData.push({
            RunningNo: element.RunningNo,
            ProjectCode: element.ProjectCode,
            id: element.id,
            ProjectName: element.ProjectName,
            Remark: element.Remark,
            PublishDate: element.PublishDate,
            ProjectStatus: e,
            PublishDateWithoutYear: element.PublishDateWithoutYear,
            Status: element.Status,
            Assignee: element.Assignee,
            AssigneeFullEndDate: element.AssigneeFullEndDate,
            AssigneeEndDate: element.AssigneeEndDate,
            TaskNameAssigned: element.TaskNameAssigned,
          });
          setDetailsProjectStatus(e);

          setShowSlide(false);
        }
      } else {
        newData.push({
          RunningNo: element.RunningNo,
          ProjectCode: element.ProjectCode,
          id: element.id,
          ProjectName: element.ProjectName,
          Remark: element.Remark,
          PublishDate: element.PublishDate,
          ProjectStatus: element.ProjectStatus,
          PublishDateWithoutYear: element.PublishDateWithoutYear,
          Status: element.Status,
          AssigneeEndDate: element.AssigneeEndDate,
          AssigneeFullEndDate: element.AssigneeFullEndDate,
          Assignee: element.Assignee,
          TaskNameAssigned: element.TaskNameAssigned,
        });
      }
    });

    //when press Mark as complete, if can't find ID, just hide it
    if (!foundStatus && section === "status") {
      setShowSlide(false);
    }

    setProjectData(newData);
  }

  function OnUpdateTaskData(e, section, taskId, shortName) {
    UpdateTask(
      doNothingCallBack.bind(this),
      {
        id: taskId,
        Section: section,
        Value:
          e === ""
            ? ""
            : section === "duedate"
            ? moment(e, "DD/MM/yyyy HH:MM").format("yyyy-MM-DD HH:mm")
            : e,
        UpdatedBy: getLocalUsername(),
      },
      getLocalToken()
    );
  }

  function OnUpdateData(e, section, taskId, shortName, liveUpdate) {
    if (
      taskId === "" ||
      ((section === "duedate" || section === "startdate") &&
        e != "" &&
        !moment(e).isValid())
    ) {
      // skip
      return;
    }

    taskData.forEach((gps) => {
      if (gps === null) {
        console.log("IT IS NULL");
      } else if (gps === undefined) {
        console.log("IT IS UNDEFINED");
      } else {
        if (
          gps !== null &&
          gps !== undefined &&
          gps !== "undefined" &&
          gps !== ""
        ) {
          if (gps.children !== null) {
            var hasChanges = false;
            gps.children
              .filter((e) => e.id === taskId)
              .map((element) => {
                if (section === "assignee") {
                  console.log(e);
                  element.Assignee = e;
                  element.StaffPic = shortName;
                } else if (section === "taskName") {
                  element.TaskName = e;
                } else if (section === "duedate") {
                  element.EndDate = e;
                  element.EndTime =
                    e === ""
                      ? ""
                      : moment(e, "DD/MM/yyyy HH:mm").format("HH:mm");
                  //moment(e).format("DD/MM/yyyy");
                } else if (section === "duetime") {
                  element.EndTime = e;
                } else if (section === "startdate") {
                  element.StartDate =
                    e === "" ? "" : moment(e, "DD/MM/yyyy HH:mm");
                  element.StartTime =
                    e === ""
                      ? ""
                      : moment(e, "DD/MM/yyyy HH:mm").format("HH:mm") ===
                        "00:00"
                      ? "09:30"
                      : moment(e, "DD/MM/yyyy HH:mm").format("HH:mm");
                } else if (section === "starttime") {
                  element.StartTime = e;
                } else if (section === "priority") {
                  element.Priority = e;
                } else if (section === "status") {
                  element.Status = e;
                  element.StatusName = e === "" ? "" : shortName;
                }

                hasChanges = true;
              });

            if (hasChanges) {
              setTaskData([...taskData], gps);
            }
          }
        }
      }
    });

    if (liveUpdate) {
      OnUpdateTaskData(e, section, taskId, shortName);
    }
  }

  function doGetProjectData(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      setTaskData(temp);

      let stafftemp = JSON.parse(jsonObj.staffListing);
      let tagArr = [];
      let relatedStaff = [];

      for (var i = 0; i < stafftemp.length; i++) {
        tagArr.push({
          value: stafftemp[i].Username,
          label: stafftemp[i].Alias,
          shortName: stafftemp[i].ShortName,
          PhotoPath: stafftemp[i].PhotoPath,
        });

        if (stafftemp[i].InvolveProject === "Yes") {
          relatedStaff.push(stafftemp[i]);
        }
      }

      let sampleData = [];

      sampleData.push({
        username: "",
        name: "No assignee",
        photo: DEFAULT_IMAGE,
      });

      for (var i = 0; i < stafftemp.length; i++) {
        sampleData.push({
          photo: stafftemp[i].PhotoPath,
          name: stafftemp[i].Alias,
          username: stafftemp[i].Username,
        });
      }

      setFriends(sampleData);

      setRelatedStaffData(relatedStaff);
      setStaffData(tagArr);

      setPopulateData(true);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function PopulateJobStatus() {
    GetAllProjectSetting(
      doGetJobStatusCB.bind(this),
      {
        FilterCode: "",
        SelectedPage: selectedPage,
      },

      getLocalToken()
    );
  }

  function doGetJobStatusCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);

      let tagArr = [];

      tagArr.push({
        value: "",
        label: "Please Select Status",
      });

      for (var i = 0; i < temp.length; i++) {
        tagArr.push({
          value: temp[i].StatusCode,
          label: temp[i].StatusName,
        });
      }

      setProjectStatus(tagArr);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function PopulatePriorityData() {
    let tagArr = [];

    tagArr.push({
      value: "high",
      label: "High",
    });

    tagArr.push({
      value: "medium",
      label: "Medium",
    });

    tagArr.push({
      value: "low",
      label: "Low",
    });

    setPriorityData(tagArr);
  }

  function populateDetailsPage(
    projectCode,
    projectName,
    runningNo,
    id,
    Remark,
    publishDate,
    status,
    projectStatus,
    projectType
  ) {
    //if the runningNo different only we refresh it
    if (detailsRunningNo === runningNo && showSlide !== false) {
      return;
    }

    setPopulateData(false);
    setShowSlide(true);
    setTimeout(function () {
      resetTaskData();
      resetDetailsPage();
      setRelatedStaffData([]);
      setDetailsRunningNo(runningNo);
      setDetailsProjectName(projectName);
      setDetailsPublishDate(publishDate);
      setDetailsProjectStatus(projectStatus);
      setDetailsRemark(Remark === "" ? "" : Remark);
      setDetailsProjectId(id);
      setDetailsProjectCode(projectCode);
      setProjectType(projectType);
      GetProjectDetails(
        doGetProjectData.bind(this),
        {
          ProjectCode: projectCode,
        },
        getLocalToken()
      );

      GetProjectChannelByChildOrParent(projectCode);
      // setDetailsStatus(status); //no longer get the status from here, we query upon populating detail page
      GetProjectStatusByProjectCodeMethod(projectCode);
      setShowLog(false);
    }, 1000);
  }

  function GetProjectChannelByChildOrParent(code) {
    GetAllProjectChannelByChildOrParent(
      doGetProjectChannelByChildOrParent.bind(this),
      {
        ProjectCode: code,
      },
      getLocalToken()
    );
  }

  function doGetProjectChannelByChildOrParent(jsonObj) {
    if (jsonObj.message === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      // console.log(temp);
      setProjectRelatedList(temp);
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function resetDetailsPage() {
    setDetailsRunningNo("");
    setDetailsProjectName("");
    setDetailsProjectStatus(0);
    setTaskData([]);
    setStaffData([]);
  }

  function doCreateCB(jsonObj) {
    if (jsonObj.message === "Success") {
      let temp = JSON.parse(jsonObj.listing);

      let newData = [];
      let tempArrTask = [...temp];
      tempArrTask.map((element) => {
        newData.push(element);
      });

      // console.log(newData);
      setTaskData(newData);
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function doDeleteProjectCB(jsonObj) {
    if (jsonObj.message === "Success") {
      //refresh the listing
      resetChannel();
      loadChannel(channelCode, selectedProjectStatus);
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function onAddRow(groupCode) {
    CreateTask(
      doCreateCB.bind(this),
      {
        ProjectCode: detailsProjectCode,
        GroupCode: groupCode,
        UpdatedBy: getLocalUsername(),
      },
      getLocalToken()
    );
  }

  function removeTaskConfirmation(Id, projectCode, runningNo) {
    MySwal.fire({
      title: "Confirmation",
      text: "Are you sure you want to delete this task " + runningNo + "?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        DeleteTask(
          doCreateCB.bind(this),
          {
            id: Id,
            ProjectCode: projectCode,
            UpdatedBy: getLocalUsername(),
          },
          getLocalToken()
        );
      }
    });
  }

  function removeProjectConfirmation(Id, runningNo) {
    MySwal.fire({
      title: "Confirmation",
      text: "Are you sure you want to delete this project " + runningNo + "?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        DeleteProject(
          doDeleteProjectCB.bind(this),
          {
            id: Id,
            ChannelCode: channelCode,
            UpdatedBy: getLocalUsername(),
            ProjectStatus: selectedProjectStatus,
          },
          getLocalToken()
        );
      }
    });
  }

  function populateProjectTable() {
    return projectData.map((settings, index) => {
      const {
        id,
        ProjectCode,
        ProjectName,
        RunningNo,
        TaskNameAssigned,
        Remark,
        PublishDate,
        PublishDateWithoutYear,
        AssigneeEndDate,
        EarliestStatus,
        Status,
        Type,
        ProjectStatus,
        AssigneeFullEndDate,
        TaskEndDateString,
        TaskEndDateFullString,
        Assignee,
      } = settings;
      var checkBoxId = "Section1Check" + index;
      var fnameId = "fname" + index;

      let statusArr = Status != null ? Status.split("-") : "";
      let groupName = statusArr.length > 1 ? statusArr[0] : "";
      let taskName = statusArr.length > 1 ? statusArr[1] : "";

      return (
        <tr
          style={{
            lineHeight: "28px",
            borderLeft:
              detailsProjectCode !== "" && detailsProjectCode === ProjectCode
                ? "1px solid grey"
                : "",
            backgroundColor:
              detailsProjectCode !== "" && detailsProjectCode === ProjectCode
                ? "#EBEBEE"
                : ProjectStatus === "2"
                ? "#B2B7B2"
                : ProjectStatus === "1"
                ? ""
                : "",
          }}
          key={index}
        >
          <td>
            {getLocalRole() !== 3 ? (
              <span class="round myDIV" style={{ marginLeft: "0px" }}>
                {getLocalRole() === 3 ? (
                  selectedUserz === undefined ||
                  selectedUserz === "undefined" ? (
                    <span>
                      <input
                        type="checkbox"
                        class=""
                        id={checkBoxId}
                        checked={ProjectStatus === "1"}
                        // for staff don't do anything onclick but I need this so that the alignment wont lari
                      />{" "}
                    </span>
                  ) : (
                    <span>
                      <input
                        type="checkbox"
                        class=""
                        id={checkBoxId}
                        checked={ProjectStatus === "1"}
                        // for staff don't do anything onclick but I need this so that the alignment wont lari
                      />{" "}
                    </span>
                  )
                ) : (
                  <span>
                    <input
                      type="checkbox"
                      class=""
                      style={{ marginTop: "-50px" }}
                      id={checkBoxId}
                      checked={ProjectStatus === "1"}
                      onClick={() => {
                        settings.ProjectStatus =
                          ProjectStatus === "0" ? "1" : "0";
                        OnUpdateProjectData(
                          ProjectStatus === "0" ? "1" : "0",
                          "status",
                          id
                        );
                      }}
                    />
                    <label class="" for={checkBoxId}></label>
                  </span>
                )}
              </span>
            ) : (
              ""
            )}
            <span
              style={{ paddingLeft: getLocalRole() === 3 ? "0px" : "20px" }}
              onClick={() => {
                setDetailsProjectCode(ProjectCode);
                populateDetailsPage(
                  ProjectCode,
                  ProjectName,
                  RunningNo,
                  id,
                  Remark,
                  PublishDate,
                  Status,
                  ProjectStatus,
                  Type
                );
              }}
            >
              {RunningNo}
            </span>
          </td>
          <th
            style={{
              maxWidth: "350px",
              cursor: "pointer",
              fontFamily: "Roboto, sans-serif",
              color: "#007BFF",
              fontSize: "0.8125rem !important",
              fontWeight: "normal",
            }}
            onClick={() => {
              setDetailsProjectCode(ProjectCode);
              populateDetailsPage(
                ProjectCode,
                ProjectName,
                RunningNo,
                id,
                Remark,
                PublishDate,
                Status,
                ProjectStatus,
                Type
              );
            }}
          >
            {showSlide
              ? ProjectName.length > 80
                ? ProjectName.substring(0, 80) + " ..."
                : ProjectName
              : !isSmallSize
              ? ProjectName.length > 195
                ? ProjectName.substring(0, 195) + " ..."
                : ProjectName
              : ProjectName.length > 140
              ? ProjectName.substring(0, 140) + " ..."
              : ProjectName}
          </th>
          {selectedUserz !== "undefined" ? (
            ""
          ) : (
            <td
              onClick={() => {
                setDetailsProjectCode(ProjectCode);
                populateDetailsPage(
                  ProjectCode,
                  ProjectName,
                  RunningNo,
                  id,
                  Remark,
                  PublishDate,
                  Status,
                  ProjectStatus,
                  Type
                );
              }}
            >
              {groupName.trim() === "adhoc" ? "Adhoc" : groupName}
            </td>
          )}
          {selectedUserz !== "undefined" ? (
            <td
              onClick={() => {
                setDetailsProjectCode(ProjectCode);
                populateDetailsPage(
                  ProjectCode,
                  ProjectName,
                  RunningNo,
                  id,
                  Remark,
                  PublishDate,
                  Status,
                  ProjectStatus,
                  Type
                );
              }}
            >
              {TaskNameAssigned}
            </td>
          ) : (
            <td
              onClick={() => {
                setDetailsProjectCode(ProjectCode);
                populateDetailsPage(
                  ProjectCode,
                  ProjectName,
                  RunningNo,
                  id,
                  Remark,
                  PublishDate,
                  Status,
                  ProjectStatus,
                  Type
                );
              }}
            >
              {taskName}
            </td>
          )}
          <td
            onClick={() => {
              setDetailsProjectCode(ProjectCode);
              populateDetailsPage(
                ProjectCode,
                ProjectName,
                RunningNo,
                id,
                Remark,
                PublishDate,
                Status,
                ProjectStatus,
                Type
              );
            }}
          >
            {EarliestStatus}
          </td>
          {selectedUserz !== "undefined" ? (
            ""
          ) : (
            <td
              onClick={() => {
                setDetailsProjectCode(ProjectCode);
                populateDetailsPage(
                  ProjectCode,
                  ProjectName,
                  RunningNo,
                  id,
                  Remark,
                  PublishDate,
                  Status,
                  ProjectStatus,
                  Type
                );
              }}
            >
              {Assignee}
            </td>
          )}{" "}
          {selectedUserz !== "undefined" ? (
            ""
          ) : (
            <td
              onClick={() => {
                setDetailsProjectCode(ProjectCode);
                populateDetailsPage(
                  ProjectCode,
                  ProjectName,
                  RunningNo,
                  id,
                  Remark,
                  PublishDate,
                  Status,
                  ProjectStatus,
                  Type
                );
              }}
            >
              {AssigneeEndDate}
            </td>
          )}
          {selectedUserz !== "undefined" ? (
            <td
              onClick={() => {
                setDetailsProjectCode(ProjectCode);
                populateDetailsPage(
                  ProjectCode,
                  ProjectName,
                  RunningNo,
                  id,
                  Remark,
                  PublishDate,
                  Status,
                  ProjectStatus,
                  Type
                );
              }}
            >
              {TaskEndDateString}
            </td>
          ) : (
            ""
          )}
          {getLocalRole() !== 3 && selectedUserz === "undefined" ? (
            <td>
              <a
                class="icon-menu"
                href="#"
                onClick={() => removeProjectConfirmation(id, RunningNo)}
              >
                <i class="icon-trash"></i>
              </a>
            </td>
          ) : (
            ""
          )}
        </tr>
      );
    });
  }

  function populateTaskDetailsPage(taskName, Id) {
    setTaskName(taskName);
    setSelectedId(Id);
    setProjectTaskIDMain(Id);
    GetAttachmentByProjectTaskID(Id);
    setShowDialog(true);
  }

  function GetAttachmentByProjectTaskID(Id) {
    GetAllAttachmentByProjectTaskID(
      doGetAttachmentByProjectTaskIDCB.bind(this),
      {
        ProjectTaskID: Id,
      },
      getLocalToken()
    );
  }

  function doGetAttachmentByProjectTaskIDCB(jsonObj) {
    if (jsonObj.message === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      setArrTaskAttachment(temp);
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  const renderItem = ({ item, index }) => {
    // console.log(item);

    const {
      id,
      GroupName,
      GroupCode,
      TaskName,
      TaskCode,
      RunningNo,
      Assignee,
      EndDate,
      StartDate,
      Remark,
      Priority,
      ProjectCode,
      StartTime,
      EndTime,
      Status,
      StatusName,
      ProjectName,
      ShortName,
      StaffPic,
    } = item;

    var txtIndex = TaskCode + "_" + id;
    if (GroupCode === "") {
      return (
        <div class="form-group row" key={index}>
          <label
            for="inputEndDate"
            class="col-form-label"
            style={{ textAlign: "left" }}
            data-toggle="tooltip"
            title="Add subtask to break up work into smaller parts"
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              paddingLeft: "15px",
            }}
          >
            {GroupName === "adhoc" ? "Adhoc" : GroupName}
          </label>
        </div>
      );
    } else if (GroupName === "LastItem") {
      return (
        <div
          key={index}
          class="row no-gutter"
          style={{
            marginTop: "20px",
            marginBottom: getLocalRole() === 3 ? "0px" : "30px",
          }}
        >
          <div class="col-2" style={{ paddingLeft: "0", paddingRight: "0" }}>
            {getLocalRole() === 3 ? (
              ""
            ) : (
              <button
                class="btn btn-light asanaFontSize"
                data-toggle="tooltip"
                title="Add subtask to break up work into smaller parts"
                onClick={() => onAddRow(GroupCode)}
              >
                <i class="fa fa-plus asanaFontSize"></i>&nbsp; Add
              </button>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="row clearfix" key={index}>
          <div className="col-lg-4 col-md-6">
            <div className="row clearfix">
              <div>
                {getLocalRole() === 3 ? (
                  ""
                ) : (
                  <span>
                    <a
                      draggable="true"
                      style={{
                        cursor: "grab",
                        marginLeft: "-65px",
                        marginTop: "-13px",
                      }}
                    >
                      <AiOutlineMore />
                    </a>
                  </span>
                )}
              </div>
              <div
                className="col-lg-12 customPadding"
                style={{ marginLeft: "-10px", width: "110%" }}
              >
                {getLocalRole() === 3 ? (
                  <TextareaAutosize
                    class={
                      isSmallSize
                        ? "form-control textarea-custom asanaFontSize noHover"
                        : "form-control textarea-custom asanaFontSize noHover"
                    }
                    id="textareaExample"
                    maxRows="3"
                    rows="1"
                    placeholder="Add Subtasks.."
                    disabled={true}
                    value={TaskName}
                  ></TextareaAutosize>
                ) : (
                  <TextareaAutosize
                    class={
                      isSmallSize
                        ? "form-control textarea-custom asanaFontSize"
                        : "form-control textarea-custom asanaFontSize"
                    }
                    id="textareaExample"
                    maxRows="3"
                    rows="1"
                    style={{ width: isSmallSize ? "190px" : "" }}
                    placeholder="Add Subtasks.."
                    key={txtIndex}
                    defaultValue={TaskName}
                    onBlur={(e) => {
                      OnUpdateData(e.target.value, "taskName", id, "", true);
                    }}
                  ></TextareaAutosize>
                )}
              </div>
            </div>
          </div>
          <div
            className="col-lg-8 col-md-6"
            style={{ marginLeft: isSmallSize ? "-56px" : "" }}
          >
            <div className="row clearfix">
              <div className="col-lg-5 col-md-6 customPadding">
                <div className="row clearfix">
                  <div
                    className="col-lg-6 col-md-6 customPadding asanaFontSize"
                    style={{ marginTop: getLocalRole() === 3 ? "6.8px" : "" }}
                  >
                    <span>
                      {getLocalRole() === 3 ? (
                        <span
                          className="asanaFontSize"
                          style={{
                            color: "black",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          {moment(StartDate, "DD/MM/yyyy").format(
                            "DD-MMM-YY "
                          ) === "Invalid date"
                            ? "-"
                            : moment(StartDate, "DD/MM/yyyy").format(
                                "DD-MMM-YY "
                              ) + StartTime}
                        </span>
                      ) : (
                        <Datetime
                          value={
                            StartDate === ""
                              ? "-"
                              : moment(StartDate, "DD/MM/yyyy").format(
                                  "DD-MMM-YY "
                                ) === "Invalid date"
                              ? "-"
                              : moment(StartDate, "DD/MM/yyyy").format(
                                  "DD-MMM-YY "
                                ) + StartTime
                          }
                          renderInput={(props) => {
                            return (
                              <input
                                {...props}
                                value={
                                  StartDate
                                    ? moment(
                                        props.value,
                                        "DD-MMM-YY h:mm"
                                      ).format("DD-MMM ") + StartTime
                                    : "-"
                                }
                              />
                            );
                          }}
                          class="form-control-plaintextTaskListOpt2"
                          dateFormat="DD-MMM-YY HH:mm"
                          // closeOnSelect//don't want close on select because want to have user to select time and duration
                          renderView={(mode, renderDefault) =>
                            renderViewWithAddHoursExtension(
                              mode,
                              renderDefault,
                              StartDate,
                              StartTime,
                              "starttime",
                              id
                            )
                          }
                          onOpen={(e) => setDetailsDuration(0)}
                          // isValidDate={(e) => disableWeekend(e)}
                          renderDay={(props, currentDate, selectedDate) => {
                            var ystd = moment().subtract(1, "day");

                            //disable saturday + sunday + past day
                            var x =
                              currentDate.isAfter(ystd) &&
                              holidayData !== null &&
                              !holidayData.includes(
                                currentDate.format("DD/MM/yyyy")
                              );

                            if (x) {
                              return <td {...props}>{currentDate.date()}</td>;
                            } else {
                              return (
                                <td {...props} className="redBorderDate">
                                  {currentDate.date()}
                                </td>
                              );
                            }
                          }}
                          timeFormat={false}
                          onChange={(e) => {
                            OnUpdateData(e, "startdate", id, "", true);
                            onUpdateDueDate(
                              DetailsDuration,
                              e,
                              StartTime,
                              id,
                              true
                            );
                          }}
                        />
                      )}
                    </span>
                  </div>
                  <div
                    className="col-lg-6 col-md-6 customPadding asanaFontSize"
                    style={{
                      marginLeft: isSmallSize ? "-6px" : "",
                      marginTop: getLocalRole() === 3 ? "6.8px" : "",
                    }}
                  >
                    <span>
                      {getLocalRole() === 3 ? (
                        <span
                          className="asanaFontSize"
                          style={{
                            color: "black",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          {moment(EndDate, "DD/MM/yyyy") === "Invalid date" ||
                          moment(EndDate, "DD/MM/yyyy").format("DD-MMM-YY ") ===
                            "Invalid date"
                            ? "-"
                            : moment(EndDate, "DD/MM/yyyy").format(
                                "DD-MMM-YY "
                              ) + EndTime}
                        </span>
                      ) : (
                        <Datetime
                          value={
                            moment(EndDate, "DD/MM/yyyy") === "Invalid date" ||
                            moment(EndDate, "DD/MM/yyyy").format(
                              "DD-MMM-YY "
                            ) === "Invalid date"
                              ? "-"
                              : moment(EndDate, "DD/MM/yyyy").format(
                                  "DD-MMM-YY "
                                ) + EndTime
                          }
                          renderInput={(props) => {
                            return (
                              <input
                                {...props}
                                value={
                                  EndDate
                                    ? moment(
                                        props.value,
                                        "DD-MMM-YY h:mm"
                                      ).format("DD-MMM ") + EndTime
                                    : "-"
                                }
                              />
                            );
                          }}
                          /*
                          leave it here for awhile first
                          updateDueDuration
                              ? moment(EndDate, "DD/MM/yyyy") === "Invalid date"
                                ? "End Date"
                                : moment(EndDate, "DD/MM/yyyy")
                              : moment(EndDate, "DD/MM/yyyy").format(
                                  "DD-MMM-YY "
                                ) === "Invalid date"
                              ? "End Date"
                              : moment(EndDate, "DD/MM/yyyy").format(
                                  "DD-MMM-YY "
                                ) + EndTime */

                          dateFormat="DD-MMM-YY HH:mm"
                          // closeOnSelect//don't want close on select because want to have user to select time
                          renderView={(mode, renderDefault) =>
                            renderView(
                              mode,
                              renderDefault,
                              EndTime,
                              "duetime",
                              id
                            )
                          }
                          // isValidDate={(e) =>
                          //   disableWeekend(e) &&
                          //   e.isAfter(
                          //     moment(StartDate, "DD/MM/yyyy").subtract(
                          //       1,
                          //       "days"
                          //     )
                          //   )
                          // }
                          renderDay={(props, currentDate, selectedDate) => {
                            var ystd = moment().subtract(1, "day");

                            //disable saturday + sunday + past day
                            var x =
                              currentDate.isAfter(ystd) &&
                              holidayData !== null &&
                              !holidayData.includes(
                                currentDate.format("DD/MM/yyyy")
                              );

                            var xx = currentDate.isAfter(
                              moment(StartDate, "DD/MM/yyyy").subtract(
                                1,
                                "days"
                              )
                            );

                            if (x && xx) {
                              return <td {...props}>{currentDate.date()}</td>;
                            } else {
                              return (
                                <td {...props} className="redBorderDate">
                                  {currentDate.date()}
                                </td>
                              );
                            }
                          }}
                          timeFormat={false}
                          onChange={(e) => {
                            OnUpdateData(e, "duedate", id, "", true);
                          }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6"
                style={{ marginLeft: isSmallSize ? "-25px" : "" }}
              >
                <div className="row clearfix">
                  <div
                    className="col-lg-4 col-md-6 customPadding"
                    style={{ marginTop: "-6px" }}
                  >
                    <a
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{
                        padding: "0px",
                        marginLeft: "20px",
                        marginBottom: "0px",
                        paddingTop: "-10px",
                      }}
                    >
                      <img
                        src={FILES_API_URL + StaffPic}
                        style={{
                          height: "35px",
                          width: "35px",
                          borderRadius: "50%",
                        }}
                        onError={(e) => (
                          (e.target.onerror = null),
                          (e.target.src = DEFAULT_IMAGE)
                        )}
                      />
                    </a>
                    <div
                      class="dropdown-menu SubtaskDropdown"
                      style={{
                        height: "300px",
                      }}
                    >
                      {renderAllStaff(id)}
                    </div>
                  </div>
                  {/* {projectType === "0" ? (
                    <div className="col-lg-8 col-md-6 customPadding">
                      {renderCustomStatus(id, Status)}
                    </div>
                  ) : ( */}
                  <div
                    className="col-lg-8 col-md-6 customPadding"
                    style={{
                      marginLeft: isSmallSize ? "-10px" : "",
                      outline: "none",
                    }}
                  >
                    <button
                      class="btn btn-sm DropdownPrioritySubtaskCSSTaskListOpt2 asanaFontSize"
                      href="#"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{
                        boxShadow: "0 0 0 0",
                        outline: "none",
                        color: "black",
                      }}
                    >
                      {StatusName} &nbsp;
                      {StatusName === "" ? (
                        <i class="fa fa-angle-down"></i>
                      ) : (
                        ""
                      )}
                    </button>
                    <div class="dropdown-menu PriorityDropdown">
                      {renderAllStatus(id)}
                    </div>
                  </div>
                  {/* )} */}
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="row clearfix"
                  style={{
                    marginTop: "8px",
                    marginLeft: isSmallSize ? "-50px" : "",
                  }}
                >
                  <button
                    style={{
                      marginLeft: "0px",
                      marginRight: "0px",
                      boxShadow: "",
                      outline: "none",
                    }}
                    class="btn-sm buttonComments"
                    data-toggle="tooltip"
                    title="Comments"
                    onClick={() => populateTaskDetailsPage(TaskName, id)}
                  >
                    <span class="fa fa-comments" href="#"></span>
                  </button>
                  {getLocalRole() === 3 ? (
                    ""
                  ) : (
                    <button
                      class="btn-sm buttonComments"
                      style={{
                        paddingLeft: "0px !important",
                        paddingRight: "0px !important",
                        marginLeft: isSmallSize ? "-15px" : "",
                      }}
                      data-toggle="tooltip"
                      title="Delete"
                      onClick={() =>
                        removeTaskConfirmation(id, ProjectCode, TaskName)
                      }
                    >
                      <span class="fa fa-trash" href="#"></span>
                    </button>
                  )}
                  {/* <div className="col-lg-6 col-md-6">
                    <button
                      class="btn-sm buttonComments"
                      data-toggle="tooltip"
                      title="Click here to open comment box"
                      onClick={() => populateTaskDetailsPage(TaskName, id)}
                    >
                      <span class="fa fa-comments" href="#"></span>
                    </button>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {getLocalRole() === 3 ? (
                      ""
                    ) : (
                      <button
                        class="btn-sm buttonComments"
                        data-toggle="tooltip"
                        title="Click here to open comment box"
                        onClick={() =>
                          removeTaskConfirmation(id, ProjectCode, RunningNo)
                        }
                      >
                        <span class="fa fa-trash" href="#"></span>
                      </button>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  function renderAllStatus(id) {
    return projectStatus.map((attach, index) => {
      const { value, label } = attach;
      return (
        <a
          class="dropdown-item asanaFontSize"
          href="#"
          onClick={() => OnUpdateData(value, "status", id, label, true)}
          style={{ color: "black" }}
        >
          {label}
        </a>
      );
    });
  }

  function renderFriend(props, option, snapshot, className, id) {
    const imgStyle = {
      borderRadius: "50%",
      verticalAlign: "middle",
      marginRight: 10,
    };

    return (
      <button
        {...props}
        className={className}
        type="button"
        style={{ marginLeft: "-30px" }}
        onClick={() =>
          OnUpdateData(option.username, "assignee", id, option.photo, true)
        }
      >
        <span>
          <img
            alt=""
            style={imgStyle}
            width="32"
            height="32"
            src={FILES_API_URL + option.photo}
            onError={(e) => (
              (e.target.onerror = null), (e.target.src = DEFAULT_IMAGE)
            )}
          />
          <span className="asanaFontSize" style={{ color: "black" }}>
            {option.name}
          </span>
        </span>
      </button>
    );
  }

  function renderAllStaff(id) {
    return (
      <SelectSearch
        className="select-search select-search--multiple"
        options={friends}
        renderOption={(props, option, snapshot, className) =>
          renderFriend(props, option, snapshot, className, id)
        }
        multiple
        search
        closeOnSelect={false}
        placeholder="Search"
        value=""
      />
    );
  }

  function renderProjectRelated() {
    return projectRelatedList.map((input) => {
      const { ProjectCode, ChannelCode, ChannelName } = input;
      return (
        <a
          href="#"
          className="asanaFontSizeFloatLeft"
          style={{
            color: "#007BFF",
            fontFamily: "Roboto, sans-serif;",
            paddingLeft: "13px",
          }}
          onClick={() => {
            setLocalViewProjectRefresh(true);

            setSelectedChannel(ChannelCode);
            history.push({
              pathname: "/projectdetails",
              search: `?projectID=${ProjectCode}` + "&userz=undefined",
            });
          }}
        >
          {ProjectCode}
        </a>
      );
    });
  }

  const handleClose = () => {
    setShowDialog(false);
  };

  function goToCreateProject(code, adhoc) {
    history.push({
      pathname: "/newproject",
      search: "?channelCode=" + code + "&adhoc=" + adhoc,
    });
  }

  function renderView(mode, renderDefault, time, section, taskId) {
    // Only for years, months and days view
    if (mode === "time") return renderDefault();

    return (
      <div className="wrapper">
        {renderDefault()}
        <div className="controls" style={{ justifyContent: "space-between" }}>
          <label
            style={{
              float: "left",
              marginTop: "10px",
              width: "120px",
              textAlign: "left",
            }}
          >
            Due Time
          </label>
          <Select
            style={{ maxWidth: "40px", float: "right" }}
            placeholder=""
            menuPlacement="top"
            options={timeCB}
            value={timeCB.find((obj) => obj.value === time)} //need to use default value instead of value. Else when you change selection it wont update
            onChange={(e) => {
              setUpdateDueDuration(false);
              OnUpdateData(e.value, section, taskId, "", true);
            }}
          />
        </div>
        <div className="controls" style={{ float: "right", marginTop: "10px" }}>
          <a
            style={{
              textDecoration: "underline",
              float: "right",
              fontSize: "15px",
              paddingLeft: "15px",
            }}
            onClick={(e) => {
              OnUpdateData("", "duedate", taskId, "", true);
            }}
          >
            Reset
          </a>
        </div>
      </div>
    );
  }

  function renderViewWithAddHoursExtension(
    mode,
    renderDefault,
    startDate,
    time,
    section,
    taskId
  ) {
    // Only for years, months and days view
    if (mode === "time") return renderDefault();

    return (
      <div className="wrapper">
        {renderDefault()}
        <div className="controls" style={{ justifyContent: "space-between" }}>
          <label
            style={{
              float: "left",
              marginTop: "10px",
              width: "120px",
              textAlign: "left",
            }}
          >
            Start Time
          </label>
          <Select
            style={{ maxWidth: "40px", float: "right" }}
            placeholder=""
            menuPlacement="top"
            options={starttimeCB}
            defaultValue={starttimeCB.find((obj) => obj.value === time)} //need to use default value instead of value. Else when you change selection it wont update
            onChange={(e) => {
              OnUpdateData(e.value, section, taskId, "", true);
              onUpdateDueDate(
                DetailsDuration,
                startDate,
                e.value,
                taskId,
                true
              );
            }}
          />
        </div>
        <div
          className="controls"
          style={{ justifyContent: "space-between", marginTop: "10px" }}
        >
          <label style={{ float: "left", marginTop: "10px" }}>Date Range</label>
          <input
            style={{ width: "120px", float: "right" }}
            value={DetailsDuration}
            placeholder="add day"
            className="form-control"
            onChange={(e) => {
              onUpdateDueDate(e.target.value, startDate, time, taskId, false);
            }}
            onBlur={(e) => {
              onUpdateDueDate(e.target.value, startDate, time, taskId, true);
            }}
          />
        </div>
        <div className="controls" style={{ float: "right", marginTop: "10px" }}>
          <a
            style={{
              textDecoration: "underline",
              float: "right",
              fontSize: "15px",
              paddingLeft: "15px",
            }}
            onClick={(e) => {
              OnUpdateData("", "startdate", taskId, "", true);
              OnUpdateData("", "duedate", taskId, "", true);
            }}
          >
            Reset
          </a>
        </div>
      </div>
    );
  }

  function onUpdateDueDate(e, startDate, startTime, Id, liveUpdate) {
    setDetailsDuration(e);

    let startDateDate = moment(
      moment(startDate, "DD/MM/yyyy").format("DD-MMM-yyyy ") + startTime
    );

    let beforeAdjustment = moment(
      moment(startDate, "DD/MM/yyyy").format("DD-MMM-yyyy ") + startTime
    );

    if (e === 0) {
      OnUpdateData("", "duedate", Id, "", liveUpdate);
    }

    if (e % 0.5 === 0 && e > 0 && startDateDate.isValid) {
      //let totalDays = e * 24 * 60;
      //let endDate = startDateDate.add(totalDays, "minutes");
      let totalDays = e / 0.5;

      if (
        beforeAdjustment.isValid &&
        (beforeAdjustment.day() === 6 ||
          beforeAdjustment.day() === 0 ||
          holidayData.includes(beforeAdjustment.format("DD/MM/yyyy")))
      ) {
        while (
          beforeAdjustment.day() === 6 ||
          beforeAdjustment.day() === 0 ||
          holidayData.includes(beforeAdjustment.format("DD/MM/yyyy"))
        ) {
          if (e !== 1) {
            beforeAdjustment = beforeAdjustment.subtract(1440, "minutes");
          } else {
            beforeAdjustment = beforeAdjustment.add(540, "minutes");
          }
        }
      }

      let days = e / 1;
      let endDate = 0;
      if (parseInt(days) > 0) {
        endDate = addBusinessDays(beforeAdjustment, parseInt(days));

        if (e % 1 != 0) {
          endDate = addBusinessHalfDays(endDate, 1);
        }
      } else {
        endDate = addBusinessHalfDays(beforeAdjustment, 1);
      }

      setUpdateDueDuration(true);

      /*if (endDate.format("HH:mm") === "02:30" && e % 1 !== 0) {
        endDate = endDate.subtract(480, "minutes");
      } else if (endDate.format("HH:mm") === "21:30" && e % 1 !== 0) {
        endDate = endDate.subtract(420, "minutes");
      } else if (endDate.format("HH:mm") === "21:30" && e % 1 === 0) {
        endDate = endDate.subtract(150, "minutes");
      } else if (endDate.format("HH:mm") === "09:30") {
        if (endDate.day() === 1) {
          endDate = endDate.subtract(3780, "minutes");
        } else {
          endDate = endDate.subtract(900, "minutes");
        }
      }*/

      if (endDate.format("HH:mm") === "09:30") {
        endDate = endDate.subtract(900, "minutes");
      } else if (
        endDate.format("HH:mm") === "19:00" ||
        endDate.format("HH:mm") === "19:30" ||
        endDate.format("HH:mm") === "20:00" ||
        endDate.format("HH:mm") === "20:30" ||
        endDate.format("HH:mm") === "21:00" ||
        endDate.format("HH:mm") === "21:30" ||
        endDate.format("HH:mm") === "22:00" ||
        endDate.format("HH:mm") === "22:30"
      ) {
        endDate = endDate.add(900, "minutes");
      }

      while (
        endDate.day() === 6 ||
        endDate.day() === 0 ||
        holidayData.includes(endDate.format("DD/MM/yyyy"))
      ) {
        endDate.subtract(1440, "minutes");
      }

      let holidayDate = moment(
        moment(startDate, "DD/MM/yyyy").format("DD-MMM-yyyy ") + startTime
      );

      /*if (
        holidayDate.isValid &&
        (holidayDate.day() === 6 ||
          holidayDate.day() === 0 ||
          holidayData.includes(holidayDate.format("DD/MM/yyyy")))
      ) {
        if ((e / 1 === 1 || e / 0.5 === 1) && startTime === "09:30") {
          let caterDate = moment(
            moment(startDate, "DD/MM/yyyy").format("DD-MMM-yyyy ") + startTime
          );

          endDate = caterDate.add(e / 1 === 1 ? 540 : 300, "minutes");
        } else if (e / 0.5 === 1 && startTime === "14:30") {
          let caterDate = moment(
            moment(startDate, "DD/MM/yyyy").format("DD-MMM-yyyy ") + startTime
          );

          endDate = caterDate.add(240, "minutes");
        }
      } */

      if (
        startDateDate.isValid &&
        (startDateDate.day() === 6 ||
          startDateDate.day() === 0 ||
          holidayData.includes(startDateDate.format("DD/MM/yyyy")))
      ) {
        if (parseInt(e) === 1 && startDateDate.format("HH:mm") === "09:30") {
          endDate = startDateDate.add(540, "minutes");
        } else if (parseFloat(e) === 0.5) {
          endDate = startDateDate.add(240, "minutes");
        }
      }

      OnUpdateData(endDate, "duedate", Id, "", liveUpdate);
    }
  }

  function addBusinessHalfDays(newDate, numDaysToAdd) {
    const Sunday = 0;
    const Saturday = 6;
    let daysRemaining = numDaysToAdd;

    while (daysRemaining > 0) {
      newDate.add(240, "minutes");

      if (
        newDate.day() !== Sunday &&
        newDate.day() !== Saturday &&
        !holidayData.includes(newDate.format("DD/MM/yyyy"))
      ) {
        daysRemaining--;
      }
    }

    return newDate;
  }

  function addBusinessDays(newDate, numDaysToAdd) {
    const Sunday = 0;
    const Saturday = 6;
    let daysRemaining = numDaysToAdd;

    while (daysRemaining > 0) {
      newDate.add(1440, "minutes");

      if (
        newDate.day() !== Sunday &&
        newDate.day() !== Saturday &&
        !holidayData.includes(newDate.format("DD/MM/yyyy"))
      ) {
        daysRemaining--;
      }
    }

    return newDate;
  }

  const onItemOnChange = (items, item) => {
    // console.log(items);
    // console.log(item);
    let seq = 1;

    items
      .filter((e) => e.GroupName === item.GroupName)
      .map((element) => {
        element.children.map((c) => {
          // console.log(c.id);
          if (c.id > 0) {
            // console.log(element);
            UpdateTask(
              doNothingCallBack.bind(this),
              {
                id: c.id,
                Section: "sequence",
                Value: seq,
                UpdatedBy: getLocalUsername(),
              },
              getLocalToken()
            );
          }

          seq++;
        });
      });

    let newData = [];
    let tempArrTask = [...items];
    tempArrTask.map((element) => {
      newData.push(element);
    });

    setTaskData(tempArrTask);
  };

  const handleChange = (event) => {
    setDetailsRemark(event.target.value);
  };

  const onItemConfirmChange = (dragItem, destinationParent) => {
    //console.log("dragitem");
    //console.log(dragItem);
    if (getLocalRole() === 3) {
      return false;
    }

    if (
      destinationParent !== null &&
      dragItem.GroupName === destinationParent.GroupName
    ) {
      return true;
    }
    return false;
  };

  function slidingPage() {
    if (!populateData) {
      return (
        <div
          id="mysidenav2"
          class="sidenav2 shadow-lg"
          style={{ width: showSlide ? "50%" : "0", marginTop: "63px" }}
        >
          <div className="flex-containerCenterVerticalHorizontal">
            <div>
              {
                <img
                  src="../assets/images/BES_Loading_White.gif"
                  height="200px"
                  width="200px"
                  alt="Brainy Logo"
                  className="img-responsive logo"
                />
              }
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          id="mysidenav2"
          class="sidenav2 shadow-lg"
          style={{ width: showSlide ? "50%" : "0", marginTop: "63px" }}
        >
          {!populateData ? (
            ""
          ) : (
            <div class="sticky shadow" style={{ height: "60px" }}>
              <div class="">
                <div
                  className="flex-containerSpaceBetweenFullWidth"
                  role="group"
                  aria-label="Button group with nested dropdown"
                  style={{
                    marginBottom: "30px",
                    marginLeft: "18px",
                    marginTop: "11px",
                    marginRight: "0px",
                  }}
                >
                  {getLocalRole() === 3 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "90%",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          padding: "6px",
                          paddingLeft: "2px",
                        }}
                      >
                        {DetailsProjectName.length > 60
                          ? DetailsProjectName.substr(0, 60) + "..."
                          : DetailsProjectName}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "90%",
                      }}
                    >
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-sm btnAddTaskNAv"
                        style={{ minWidth: "100px" }}
                        disabled={detailsProjectStatus === "2" ? true : false}
                        onClick={() =>
                          delayedOnUpdateProjectData(
                            detailsProjectStatus === "1" ? "0" : "1",
                            "status",
                            detailsProjectId,
                            projectData
                          )
                        }
                      >
                        {detailsProjectStatus === "0" ||
                        detailsProjectStatus === "2" ||
                        detailsProjectStatus === "" ||
                        detailsProjectStatus === undefined
                          ? detailsProjectStatus === "2"
                            ? "KIV"
                            : "Mark as complete"
                          : "Completed"}
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split btn-sm btnAddTaskArrowNAv"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span class="sr-only">Toggle Dropdown</span>
                      </button>
                      <div class="dropdown-menu">
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() =>
                            delayedOnUpdateProjectData(
                              detailsProjectStatus === "2" ? "0" : "2",
                              "status",
                              detailsProjectId,
                              projectData
                            )
                          }
                          style={{
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                            paddingTop: "0.25rem",
                            paddingBottom: "0.25rem",
                            fontSize: "16px",
                            color: "black",
                          }}
                        >
                          {detailsProjectStatus === "2"
                            ? "Revert KIV"
                            : "Mark as KIV"}{" "}
                        </a>
                      </div>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          padding: "8px",
                        }}
                      >
                        {DetailsProjectName.length > 40
                          ? DetailsProjectName.substr(0, 40) + "..."
                          : DetailsProjectName}
                      </div>
                    </div>
                  )}
                  <div
                    class=""
                    style={{
                      marginTop: "-20px",
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "10%",
                    }}
                  >
                    <button
                      class="btn MainButtonIconSpace"
                      onClick={() => {
                        setShowSlide(false);
                        setDetailsProjectCode("");
                        setIsRefresh(1);
                      }}
                    >
                      <span
                        class="fa icon-close"
                        style={{ fontSize: "18px" }}
                        data-toggle="tooltip"
                        title="Close Details"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            data-spy="scroll"
            data-target="#navbar-example2"
            data-offset="0"
            class="scrollspy-example"
          >
            <div class="container-fluid" style={{ minWidth: "800px" }}>
              <div class="form-group blue-border-focus">
                <div className="flexLeftToRight">
                  <div style={{ width: "20%" }}>
                    <label
                      style={{ marginTop: "6px" }}
                      className="asanaFontSizeFloatLeft"
                    >
                      {detailsRunningNo}
                    </label>
                  </div>
                  <div style={{ width: "80%" }}>
                    <div
                      class="asanaFontSizeFloatLeft"
                      style={{
                        width: getLocalRole() === 3 ? "100%" : "100%",
                        marginLeft: getLocalRole() === 3 ? "0" : "-28px",
                      }}
                    >
                      {getLocalRole() === 3 ? (
                        <Form.Group
                          controlId="formBasicEmail"
                          style={{ marginBottom: "-10px" }}
                        >
                          <TextareaAutosize
                            class="form-control NavTextDesciption asanaFontSizeFloatLeft"
                            maxRows="3"
                            id="exampleFormControlTextarea5"
                            rows="1"
                            value={DetailsProjectName}
                            style={{
                              width: "70%",
                              marginLeft: "0px",
                              resize: "none",
                            }}
                            disabled={true}
                          ></TextareaAutosize>
                        </Form.Group>
                      ) : (
                        <TextareaAutosize
                          class="form-control NavTextDesciption asanaFontSizeFloatLeft"
                          maxRows="3"
                          id="exampleFormControlTextarea5"
                          rows="1"
                          key={detailsRunningNo}
                          defaultValue={DetailsProjectName}
                          style={{
                            width: "70%",
                            marginLeft: "-10px",
                            resize: "none",
                          }}
                          onBlur={(e) => {
                            delayedOnUpdateProjectData(
                              e.target.value,
                              "title",
                              detailsProjectId,
                              projectData
                            );
                          }}
                        ></TextareaAutosize>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {projectRelatedList.length > 0 ? (
                <div className="flexLeftToRight">
                  <div style={{ width: "20%" }}>
                    <label
                      for="inputStartDate"
                      class="asanaFontSizeFloatLeft"
                      style={{ marginTop: "6px", color: "grey" }}
                      data-toggle="tooltip"
                      title="Due Date"
                    >
                      Linked Project(s)
                    </label>
                  </div>
                  <div style={{ width: "80%" }}>
                    <div
                      class="asanaFontSizeFloatLeft"
                      style={{
                        marginLeft: getLocalRole() === 3 ? "-1px" : "-39px",
                      }}
                    >
                      <Form.Group controlId="formBasicEmail">
                        {renderProjectRelated()}
                      </Form.Group>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="flexLeftToRight">
                <div style={{ width: "20%" }}>
                  <label
                    for="inputStartDate"
                    class="asanaFontSizeFloatLeft"
                    style={{ marginTop: "6px", color: "grey" }}
                    data-toggle="tooltip"
                    title="Due Date"
                  >
                    Target Publish Date
                  </label>
                </div>
                <div style={{ width: "80%" }}>
                  <div
                    class="asanaFontSizeFloatLeft"
                    style={{
                      width: getLocalRole() === 3 ? "100%" : "30%",
                      marginLeft: getLocalRole() === 3 ? "0" : "-26px",
                    }}
                  >
                    {getLocalRole() === 3 ? (
                      <Form.Group
                        controlId="formBasicEmail"
                        style={{ marginBottom: "-10px" }}
                      >
                        <Form.Control
                          className="asanaFontSizeFloatLeft noHover"
                          placeholder=""
                          value={detailsPublishDate}
                          disabled={true}
                        />
                      </Form.Group>
                    ) : (
                      <Datetime
                        defaultValue={
                          detailsPublishDate === ""
                            ? moment()
                            : detailsPublishDate
                        }
                        value={detailsPublishDate}
                        key={moment()}
                        dateFormat="DD/MM/yyyy"
                        closeOnSelect
                        isValidDate={(e) => disableWeekend(e)}
                        timeFormat={false}
                        onChange={(e) => {
                          delayedOnUpdateProjectData(
                            e,
                            "publishdate",
                            detailsProjectId,
                            projectData
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flexLeftToRight">
                <div style={{ width: "20%" }}>
                  <label
                    for="inputEndDate"
                    class="asanaFontSizeFloatLeft"
                    style={{ marginTop: "6px", color: "grey" }}
                    data-toggle="tooltip"
                    title="Project Status"
                  >
                    Status
                  </label>
                </div>
                <div style={{ width: "80%" }}>
                  <div
                    class="asanaFontSizeFloatLeft"
                    style={{
                      marginLeft: getLocalRole() === 3 ? "0" : "-38px",
                      width: "100%",
                    }}
                  >
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        placeholder=""
                        value={detailsStatus.replace("adhoc", "Adhoc")}
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="flexLeftToRight">
                <div style={{ width: "20%" }}>
                  <label
                    for="inputEndDate"
                    class="asanaFontSizeFloatLeft"
                    style={{ color: "grey" }}
                    data-toggle="tooltip"
                    title="Collaborators for this project"
                  >
                    Collaborators
                  </label>
                </div>
                <div style={{ width: "80%" }}>
                  <div
                    class="asanaFontSizeFloatLeft"
                    style={{
                      marginLeft: getLocalRole() === 3 ? "13px" : "-28px",
                    }}
                  >
                    <Form.Group controlId="formCollaborators">
                      <ul
                        class="list-unstyled team-info"
                        style={{
                          textAlign: "left",
                          marginTop: "-8px",
                          paddingLeft: ".555rem",
                          minHeight: "36px",
                        }}
                      >
                        {renderStaffInCharge()}
                      </ul>
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div className="flexLeftToRight">
                <div style={{ width: "20%" }}>
                  <label
                    for="inputEndDate"
                    class="asanaFontSizeFloatLeft"
                    style={{ marginTop: "6px", color: "grey" }}
                    data-toggle="tooltip"
                    title="Project Remark"
                  >
                    Remark
                  </label>
                </div>
                <div style={{ width: "80%" }}>
                  <div
                    class="asanaFontSizeFloatLeft"
                    style={{
                      width: "100%",
                      marginLeft: getLocalRole() === 3 ? "-2px" : "-40px",
                    }}
                  >
                    <TextareaAutosize
                      class="form-control NavTextDesciption asanaFontSizeFloatLeft"
                      id="exampleFormControlTextarea5"
                      maxRows="3"
                      rows="1"
                      key={detailsProjectCode}
                      defaultValue={detailsRemark}
                      style={{
                        width: "70%",
                        marginLeft: "2px",
                        resize: "none",
                      }}
                      onBlur={(e) => {
                        delayedOnUpdateProjectData(
                          e.target.value,
                          "remark",
                          detailsProjectId,
                          projectData
                        );
                      }}
                    ></TextareaAutosize>
                  </div>
                </div>
              </div>
              <hr />
              <div class="form-group row"></div>
              <div class="body">
                <Nestable
                  items={taskData}
                  renderItem={renderItem}
                  onChange={onItemOnChange}
                  confirmChange={onItemConfirmChange}
                  maxDepth="1"
                />
              </div>
              <hr />
              {showLog ? (
                <div
                  style={{
                    marginTop: "-16px",
                    marginLeft: "0px",
                    width: "100%",
                  }}
                >
                  {" "}
                  {renderActivityLog()}{" "}
                </div>
              ) : (
                <a
                  style={{
                    fontSize: "13px",
                    cursor: "pointer",
                    textAlign: "left",
                    marginBottom: "300px",
                  }}
                  onClick={() => PopulateProjectLogs()}
                >
                  Click here to show log
                </a>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  function renderActivityLog() {
    if (projectLogData.length <= 0) {
      return (
        <div
          class="container-fluid SubtaskDescriptionDIV"
          style={{ marginTop: "-1px" }}
        >
          <a
            style={{
              fontSize: "13px",
              textAlign: "left",
              cursor: "pointer",
            }}
            onClick={() => setShowLog(false)}
          >
            Click here to hide log
          </a>
        </div>
      );
    } else {
      return projectLogData.map((logData, index) => {
        let description =
          logData.text.length > 100
            ? logData.text.substring(0, 100) + " ..."
            : logData.text;
        return (
          <div
            class="container-fluid SubtaskDescriptionDIV"
            style={{ marginTop: "-1px" }}
          >
            {index === 0 ? (
              <a
                style={{
                  fontSize: "13px",
                  textAlign: "left",
                  cursor: "pointer",
                }}
                onClick={() => setShowLog(false)}
              >
                Click here to hide log
              </a>
            ) : (
              ""
            )}
            <div class="row no-gutter CreateTaskRowCSS">
              <div class="col-1 buttonSubTaskNameIcon">
                {" "}
                <div
                  class="btn-group hover_drop_down"
                  style={{
                    float: "right",
                    display: "inline",
                  }}
                >
                  <button class="buttonPublicMember btn-sm">
                    {logData.staffPic === "" ? (
                      ""
                    ) : (
                      <img
                        src={FILES_API_URL + logData.staffPic}
                        style={{
                          height: "35px",
                          width: "35px",
                          borderRadius: "50%",
                        }}
                        onError={(e) => (
                          (e.target.onerror = null),
                          (e.target.src = DEFAULT_IMAGE)
                        )}
                      />
                    )}
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    style={{ marginTop: "-260px" }}
                  >
                    <div class="DropDownUSerInfoInamge">
                      {logData.staffPic === "" ? (
                        ""
                      ) : (
                        <img
                          src={FILES_API_URL + logData.staffPic}
                          style={{
                            height: "200px",
                            width: "200px",
                            marginTop: "-8px",
                          }}
                          onError={(e) => (
                            (e.target.onerror = null),
                            (e.target.src = DEFAULT_IMAGE)
                          )}
                        />
                      )}
                    </div>
                    <label
                      class="flex-containerCenterVerticalHorizontal asanaFontSize"
                      style={{ marginTop: "5px", lineHeight: "10px" }}
                    >
                      {logData.shortName}
                    </label>
                  </ul>
                </div>
              </div>
              <div
                class="col-0 SubtaskNameWithBtnCSS"
                style={{ marginTop: "13px" }}
              >
                <span>{logData.shortName}</span>&nbsp;
              </div>
              <div class="col-0 markCompleteCSS" style={{ marginTop: "13px" }}>
                &nbsp;<span>{description}</span>&nbsp;
              </div>
              <div
                class="col-0 mr-auto SubtaskTimeCSS"
                style={{ marginTop: "13px" }}
              >
                &nbsp;&nbsp;
                <span>
                  {logData.actionOn === ""
                    ? ""
                    : GetPeriodString(logData.actionOn)}
                </span>
                &nbsp;
              </div>
            </div>
          </div>
        );
      });
    }
  }

  function handleSelectedProjectStatus(e) {
    setShowSlide(false);
    setDetailsProjectCode("");
    setSelectedProjectStatus(e);

    if (channelCode != "") {
      setIsRefresh(1);
    } else if (
      (selectedUserz !== undefined || selectedUserz !== "undefined") &&
      channelCode != ""
    ) {
      GetProjectListingByUsername(selectedUserz, e);
    } else {
      setIsRefresh(1);
    }
  }

  function onSortID(event, sortKey) {
    let sampleData = [...projectData];

    if (sortById === "asc") {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
      setSortById("dsc");
    } else {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey])).reverse();
      setSortById("asc");
    }

    setProjectData(sampleData);
  }

  function onSortName(event, sortKey) {
    let sampleData = [...projectData];

    if (sortByName === "asc") {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
      setSortByName("dsc");
    } else {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey])).reverse();
      setSortByName("asc");
    }

    setProjectData(sampleData);
  }

  function onSortGroup(event, sortKey) {
    let sampleData = [...projectData];

    if (sortByGroup === "asc") {
      sampleData.sort((a, b) =>
        a[sortKey.split("-")[0]].localeCompare(b[sortKey.split("-")[0]])
      );
      setSortByGroup("dsc");
    } else {
      sampleData
        .sort((a, b) =>
          a[sortKey.split("-")[0]].localeCompare(b[sortKey.split("-")[0]])
        )
        .reverse();
      setSortByGroup("asc");
    }

    setProjectData(sampleData);
  }

  function onSortTask(event, sortKey) {
    let sampleData = [...projectData];

    if (sortByTask === "asc") {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));

      sampleData.sort((a, b) => {
        var aa = a[sortKey];
        var bb = b[sortKey];

        //.split("/") change to .split("-")  IF IT IS KARHONG LOCAL SERVER AND SORTING NOT WORKING
        if (a[sortKey].split("/").length > 1) {
          aa = a[sortKey].split("/")[1].trim();
        }

        if (b[sortKey].split("/").length > 1) {
          bb = b[sortKey].split("/")[1].trim();
        }

        return aa.localeCompare(bb);
      });
      setSortByTask("dsc");
    } else {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));

      sampleData
        .sort((a, b) => {
          var aa = a[sortKey];
          var bb = b[sortKey];

          if (a[sortKey].split("/").length > 1) {
            aa = a[sortKey].split("/")[1].trim();
          }

          if (b[sortKey].split("/").length > 1) {
            bb = b[sortKey].split("/")[1].trim();
          }

          return aa.localeCompare(bb);
        })
        .reverse();
      setSortByTask("asc");
    }

    setProjectData(sampleData);
  }

  function onSortByTaskStatus(event, sortKey) {
    let sampleData = [...projectData];

    if (sortByTaskStatus === "asc") {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
      setSortByTaskStatus("dsc");
    } else {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey])).reverse();
      setSortByTaskStatus("asc");
    }

    setProjectData(sampleData);
  }

  function onSortByAssignee(event, sortKey) {
    let sampleData = [...projectData];

    if (sortByAssignee === "asc") {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
      setSortByAssignee("dsc");
    } else {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey])).reverse();
      setSortByAssignee("asc");
    }

    setProjectData(sampleData);
  }

  function onSortStaffTask(event, sortKey) {
    let sampleData = [...projectData];

    if (sortByStaffTask === "asc") {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));

      sampleData.sort((a, b) => {
        var aa = a[sortKey];
        var bb = b[sortKey];

        //.split("/") change to .split("-")  IF IT IS KARHONG LOCAL SERVER AND SORTING NOT WORKING
        if (a[sortKey].split("/").length > 1) {
          aa = a[sortKey].split("/")[1].trim();
        }

        if (b[sortKey].split("/").length > 1) {
          bb = b[sortKey].split("/")[1].trim();
        }

        return aa.localeCompare(bb);
      });
      setSortByStaffTask("dsc");
    } else {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));

      sampleData
        .sort((a, b) => {
          var aa = a[sortKey];
          var bb = b[sortKey];

          if (a[sortKey].split("/").length > 1) {
            aa = a[sortKey].split("/")[1].trim();
          }

          if (b[sortKey].split("/").length > 1) {
            bb = b[sortKey].split("/")[1].trim();
          }

          return aa.localeCompare(bb);
        })
        .reverse();
      setSortByStaffTask("asc");
    }

    setProjectData(sampleData);
  }

  function onSortProject(event, sortKey) {
    let sampleData = [...projectData];

    if (sortByProject === "asc") {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));

      sampleData.sort((a, b) => {
        var aa = a[sortKey];
        var bb = b[sortKey];

        //.split("/") change to .split("-")  IF IT IS KARHONG LOCAL SERVER AND SORTING NOT WORKING
        if (a[sortKey].split("/").length > 1) {
          aa = a[sortKey].split("/")[1].trim();
        }

        if (b[sortKey].split("/").length > 1) {
          bb = b[sortKey].split("/")[1].trim();
        }

        return aa.localeCompare(bb);
      });
      setSortByProject("dsc");
    } else {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));

      sampleData
        .sort((a, b) => {
          var aa = a[sortKey];
          var bb = b[sortKey];

          if (a[sortKey].split("/").length > 1) {
            aa = a[sortKey].split("/")[1].trim();
          }

          if (b[sortKey].split("/").length > 1) {
            bb = b[sortKey].split("/")[1].trim();
          }

          return aa.localeCompare(bb);
        })
        .reverse();
      setSortByProject("asc");
    }

    setProjectData(sampleData);
  }

  function onSortProjectTaskName(event, sortKey) {
    let sampleData = [...projectData];
    if (sortByProjectTaskName === "asc") {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
      setSortByProjectTaskName("dsc");
    } else {
      sampleData.sort((a, b) => a[sortKey].localeCompare(b[sortKey])).reverse();
      setSortByProjectTaskName("asc");
    }

    setProjectData(sampleData);
  }

  return (
    <div id="main-content" class="profilepage_1">
      {/* {isLoading ? loadingpage() : ""} */}
      {slidingPage()}
      <div
        class="dropdown-menu dropdown-menu-sm rightClickCSS"
        id="context-menu"
      >
        <a
          class="dropdown-item DeleteTaskText"
          href="# "
          style={{ color: "red" }}
        >
          <i class="fa fa-trash-o"></i>&nbsp; Delete Task
        </a>
      </div>
      <div class="container-fluid">
        <div class="block-header">
          <div class="row">
            <div class="col-lg-6 col-md-8 col-sm-12">
              <h2>{channelName}</h2>
            </div>
          </div>
        </div>

        <div class="row clearfix">
          <div class="col-lg-12 col-md-12">
            <div class="mt-4">
              <div class="flex-containerSpaceBetween">
                <div
                  class=""
                  style={{
                    marginTop: "-30px",
                  }}
                >
                  {channelCode === "" || channelCode === undefined ? (
                    ""
                  ) : (
                    <div class="col-0 d-none d-sm-block d-sm-none d-md-block dropleft">
                      <button
                        class="btn btn-sm btnStatusDropDown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{
                          marginLeft: "-35px",
                          marginTop: "20px",
                          width: getLocalRole() === 3 ? "150px" : "150px",
                          boxShadow: "",
                        }}
                      >
                        <span class="fa fa-check-circle-o"></span>&nbsp;{" "}
                        {selectedProjectStatus}{" "}
                      </button>

                      <ul class="ml-auto" style={{ listStyleType: "none" }}>
                        <li class="dropdown">
                          <ul class="dropdown-menu dropdown-menu-right">
                            {arrProjectStatus.map((child) => (
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    handleSelectedProjectStatus(child.label)
                                  }
                                >
                                  {child.label}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </div>
                  )}
                  <div>
                    {getLocalRole() === 3 ||
                    (selectedUserz !== undefined &&
                      selectedUserz !== "undefined") ? (
                      ""
                    ) : (
                      <div
                        style={{
                          marginLeft: "-29px",
                          marginTop: "-15px",
                          marginBottom: "-10px",
                        }}
                      >
                        {parentCode !== "" ? (
                          <div class="container-fluid AddTaskROW">
                            <div class="row WholeRowAddTaskList">
                              <div class="col">
                                <button
                                  type="submit"
                                  class="btn btn-outline-primary"
                                  onClick={() =>
                                    goToCreateProject(channelCode, true)
                                  }
                                >
                                  Adhoc Project
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div class="container-fluid AddTaskROW">
                            <div class="row WholeRowAddTaskList">
                              <div class="col">
                                <div class="btn-group">
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary btnAddTaskNAv"
                                    style={{ width: "150px" }}
                                    onClick={() =>
                                      goToCreateProject(channelCode, false)
                                    }
                                  >
                                    <i class="fa fa-plus"></i>&nbsp; Create
                                    Project
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split btn-sm btnAddTaskArrowNAv"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <span class="sr-only">Toggle Dropdown</span>
                                  </button>
                                  <div class="dropdown-menu">
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        goToCreateProject(channelCode, true)
                                      }
                                    >
                                      Adhoc Project{" "}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div
              class="card"
              style={{
                marginTop: getLocalRole() === 3 ? "-30px" : "-20px",
                borderColor: "transparent",
              }}
            >
              <div class="body" style={{ paddingTop: "0px" }}>
                <div
                  className="table-responsive"
                  style={{ overflowX: "hidden" }}
                >
                  <table
                    class="table table-fixed MainTable overflow-x:auto table-sm"
                    style={{ marginTop: "30px", width: "100%" }}
                  >
                    <thead className="thead-white">
                      <tr>
                        <th
                          style={{
                            width: showSlide ? "120px" : "15%",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={(e) => onSortID(e, "RunningNo")}
                        >
                          ID
                        </th>
                        <th
                          style={{
                            width: showSlide ? "300px" : "40%",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={(e) => onSortName(e, "ProjectName")}
                        >
                          Name
                        </th>
                        {selectedUserz !== "undefined" ? (
                          ""
                        ) : (
                          <th
                            style={{
                              width: showSlide ? "300px" : "10%",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={(e) => onSortGroup(e, "Status")}
                          >
                            Group
                          </th>
                        )}
                        {selectedUserz !== "undefined" ? (
                          <th
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              onSortProjectTaskName(e, "TaskNameAssigned")
                            }
                          >
                            Task Name
                          </th>
                        ) : (
                          <th
                            style={{
                              // width: showSlide ? "300px" : "15%",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={(e) => onSortTask(e, "Status")}
                          >
                            Task Name
                          </th>
                        )}
                        <th
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={(e) =>
                            onSortByTaskStatus(e, "EarliestStatus")
                          }
                        >
                          Status
                        </th>
                        {selectedUserz !== "undefined" ? (
                          ""
                        ) : (
                          <th
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={(e) => onSortByAssignee(e, "Assignee")}
                          >
                            Assignee
                          </th>
                        )}
                        {selectedUserz === "undefined" ? (
                          <th
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              onSortProject(e, "AssigneeFullEndDate")
                            }
                          >
                            End Date
                          </th>
                        ) : (
                          ""
                        )}

                        {selectedUserz === "undefined" ? (
                          ""
                        ) : (
                          <th
                            style={{
                              width: showSlide ? "300px" : "15%",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              onSortStaffTask(e, "TaskEndDateFullString")
                            }
                          >
                            Task End Date
                          </th>
                        )}

                        <th></th>
                      </tr>
                    </thead>
                    <tbody class="BodyTable">{populateProjectTable()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showDialog}
        onHide={handleClose}
        dialogClassName="modal-30w customdialogbox"
        aria-labelledby="example-custom-modal-styling-title"
        style={{
          height: isSmallHeight ? "100%" : "",
          // overflow: "hidden",
        }}
        // style={{ paddingLeft: "0px !important" }}
      >
        {/* <Modal.Header
          style={{
            lineHeight: "1",
            fontWeight: "bold",
            padding: "0.5rem 0.5rem",
            fontSize: "1.1rem",
            marginLeft: "8px",
          }}
        >
          {taskName}
        </Modal.Header> */}
        <div className="flex-containerSpaceBetweenMostLeftMostRight">
          <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
            {taskName} (
            {DetailsProjectName.length > 80
              ? DetailsProjectName.substr(0, 80) + "..."
              : DetailsProjectName}
            )
          </div>
          <div>
            <i
              onClick={handleClose}
              className="icon-close"
              style={{ fontSize: "1.5rem" }}
            ></i>
          </div>
        </div>
        <Modal.Body style={{ padding: "0.5rem" }}>
          <div className="flex-containerDetails">
            <div>
              <div
                class="card"
                style={{
                  marginBottom: "10px",
                  boxShadow: "1px 1px 2px 1px rgba(0,0,0,0.1)",
                }}
              >
                <div class="header" style={{ padding: "5px" }}>
                  <h2>Attachments</h2>
                </div>
                <div
                  class="body"
                  style={{
                    padding: "5px",
                    overflow: "auto",
                    height: isSmallHeight ? "180px" : "230px",
                  }}
                >
                  <AttachmentBox projectTaskID={projectTaskID} />
                  {/* {renderTableData()} */}
                </div>
              </div>
            </div>
            <div>
              <div
                class="card"
                style={{
                  marginBottom: "10px",
                  boxShadow: "1px 1px 2px 1px rgba(0,0,0,0.1)",
                  height: isSmallHeight ? "210px" : "230px",
                }}
              >
                <div class="header" style={{ padding: "5px" }}>
                  <h2>Pinned Message</h2>
                </div>
                <div
                  class="body"
                  style={{
                    padding: "5px",
                    height: isSmallHeight ? "210px" : "230px",
                  }}
                >
                  <PinMessage
                    projectTaskID={projectTaskID}
                    isMinResolution={isSmallHeight}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="card"
            style={{
              marginBottom: "10px",
              boxShadow: "1px 1px 2px 1px rgba(0,0,0,0.1)",
            }}
          >
            <div class="header" style={{ padding: "5px" }}>
              <h2>Comments</h2>
            </div>
            <div class="body" style={{ height: "90%", padding: "5px" }}>
              <SendMessage
                projectTaskID={projectTaskID}
                isMinResolution={isSmallHeight}
              />
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer style={{ justifyContent: "left" }}>
          <button onClick={handleClose} class="btn btn-primary">
            Close
          </button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default ProjectDetails;
