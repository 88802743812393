import { createContext, useContext } from "react";

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function getLocalToken() {
  let token = localStorage.getItem("tokens");
  return token === null || token === undefined || token === "undefined"
    ? token
    : JSON.parse(token);
}

export function setLocalToken(tokenObj) {
  localStorage.setItem("tokens", JSON.stringify(tokenObj));
}

export function getSelectedChannel() {
  let token = localStorage.getItem("selectedchannel");
  return token === null || token === undefined || token === "undefined"
    ? token
    : JSON.parse(token);
}

export function setSelectedChannel(tokenObj) {
  localStorage.setItem("selectedchannel", JSON.stringify(tokenObj));
}

export function getSelectedRegion() {
  let token = localStorage.getItem("selectedregion");
  return token === null || token === undefined || token === "undefined"
    ? token
    : JSON.parse(token);
}

export function setSelectedRegion(tokenObj) {
  localStorage.setItem("selectedregion", JSON.stringify(tokenObj));
}

export function getLocalUsername() {
  let token = localStorage.getItem("username");
  return token === null || token === undefined || token === "undefined"
    ? token
    : JSON.parse(token);
}

export function setLocalUsername(tokenObj) {
  localStorage.setItem("username", JSON.stringify(tokenObj));
}

export function setLocalAlias(tokenObj) {
  localStorage.setItem("alias", JSON.stringify(tokenObj));
}

export function getLocalAlias() {
  let token = localStorage.getItem("alias");
  return token === null || token === undefined || token === "undefined"
    ? token
    : JSON.parse(token);
}

export function getLocalRole() {
  let token = localStorage.getItem("userrole");
  return token === null || token === undefined || token === "undefined"
    ? token
    : JSON.parse(token);
}

export function setLocalRole(tokenObj) {
  localStorage.setItem("userrole", JSON.stringify(tokenObj));
}

export function getLocalIsRefresh() {
  let token = localStorage.getItem("isrefresh");
  return token === null || token === undefined || token === "undefined"
    ? token
    : JSON.parse(token);
}

export function setLocalIsRefresh(tokenObj) {
  localStorage.setItem("isrefresh", JSON.stringify(tokenObj));
}

export function getLocalPhotoPath() {
  let token = localStorage.getItem("photoPath");
  return token === null || token === undefined || token === "undefined"
    ? token
    : JSON.parse(token);
}

export function setLocalPhotoPath(tokenObj) {
  localStorage.setItem("photoPath", JSON.stringify(tokenObj));
}

export function getLocalHolidays() {
  let token = localStorage.getItem("publicholidays");
  return token === null || token === undefined || token === "undefined"
    ? token
    : JSON.parse(token);
}

export function setLocalHolidays(tokenObj) {
  localStorage.setItem("publicholidays", JSON.stringify(tokenObj));
}

//use for navigating to project via notification
export function setLocalViewProjectRefresh(tokenObj) {
  localStorage.setItem("viewProjectRefresh", JSON.stringify(tokenObj));
}

export function getLocalViewProjectRefresh() {
  let token = localStorage.getItem("viewProjectRefresh");
  return token === null || token === undefined || token === "undefined"
    ? token
    : JSON.parse(token);
}
