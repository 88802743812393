import React from "react";
import { mySwalAlertFailed } from "../../../components/Misc";
import { getLocalToken } from "../../../context/AuthContext";
import { GetAllProjectLogByProjectCode } from "../../../api/UserAPI";
import queryString from "query-string";

function ProjectLog(props) {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    let params = queryString.parse(window.location.search);
    let projCode = params.projectCode;

    if (projCode !== undefined) {
      ReloadData(projCode);
    }
  }, []);

  function ReloadData(projCode) {
    GetAllProjectLogByProjectCode(
      doGetDataCB,
      {
        ProjectCode: projCode,
        SelectedPage: 1,
      },
      getLocalToken()
    );
  }

  function doGetDataCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      console.log(temp);
      setData(temp);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function renderData() {
    return data.map((data) => {
      const {
        ProjectCode,
        ProjectDescription,
        ProjectType,
        DataFrom,
        DataTo,
        CreatedOn,
      } = data;

      return (
        <a
          href="#"
          className="list-group-item list-group-item-action flex-column align-items-start"
        >
          <div className="media">
            <div
              className="media-left"
              style={{ marginRight: 50, fontSize: 25 }}
            ></div>
            <div className="media-body">
              <div
                className="text"
                style={{
                  whiteSpace: "normal",
                  width: "100%",
                }}
              >
                <h6>{ProjectDescription}</h6>
              </div>
              <span className="timestamp" style={{ whiteSpace: "normal" }}>
                {CreatedOn}
              </span>
            </div>
          </div>
        </a>
      );
    });
  }

  return (
    <div id="main-content">
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2>
                <a className="btn btn-xs btn-link btn-toggle-fullwidth">
                  <i className="fa fa-arrow-left"></i>
                </a>{" "}
                Project Logs
              </h2>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">
                    <i className="icon-home"></i>
                  </a>
                </li>
                <li className="breadcrumb-item">Project</li>
                <li className="breadcrumb-item active">Project Logs</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="header"></div>
          <div className="body">
            <div className="list-group">{renderData()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectLog;
