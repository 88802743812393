import { REST_API_URL } from "../../components/ConstantAPI";
import { postObj, postObjAuth } from "../PostObject";

export async function GetAllProjectListing(
  callback: any,
  params: any,
  tokenz: any,
  isSearching: any,
  searchText: any,
  allMemberListParam: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/getprojectsearch");
    const response = await fetch(
      REST_API_URL + "/project/getprojectsearch",
      newPostObj
    );
    const json = await response.json();
    callback(json, isSearching, searchText, allMemberListParam);
  } catch (err) {
    // Error(err);
  }
}

export async function GetProjectLogs(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/getprojectlogs");
    const response = await fetch(
      REST_API_URL + "/project/getprojectlogs",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function GetProjectDetails(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/getprojectdetails");
    const response = await fetch(
      REST_API_URL + "/project/getprojectdetails",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function UpdateTask(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/updatetask");
    const response = await fetch(
      REST_API_URL + "/project/updatetask",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function CreateTask(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/addnewtask");
    const response = await fetch(
      REST_API_URL + "/project/addnewtask",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function DeleteTask(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/deletetask");
    const response = await fetch(
      REST_API_URL + "/project/deletetask",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function CreateProject(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/createproject");
    const response = await fetch(
      REST_API_URL + "/project/createproject",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function GetChannelWithProjectsMenu(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/getchannelwithprojectsmenu");
    const response = await fetch(
      REST_API_URL + "/project/getchannelwithprojectsmenu",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function GetChannelWithProjects(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/getchannelwithprojects");
    const response = await fetch(
      REST_API_URL + "/project/getchannelwithprojects",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function UpdateProject(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/updateproject");
    const response = await fetch(
      REST_API_URL + "/project/updateproject",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function GetAllAttachmentByProjectTaskID(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    // console.log(REST_API_URL + "/project/getattachmentbyprojecttaskid");
    const response = await fetch(
      REST_API_URL + "/project/getattachmentbyprojecttaskid",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function DeleteProject(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/deleteproject");
    const response = await fetch(
      REST_API_URL + "/project/deleteproject",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function GetProjectsWithChannelCode(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/getprojectswithchannelcode");
    const response = await fetch(
      REST_API_URL + "/project/getprojectswithchannelcode",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function GetAllTasksWithUsername(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/getuseralltasks");
    const response = await fetch(
      REST_API_URL + "/project/getuseralltasks",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function GetProjectsWithUsername(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/getprojectswithusername");
    const response = await fetch(
      REST_API_URL + "/project/getprojectswithusername",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function OnCloneProject(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/cloneproject");
    const response = await fetch(
      REST_API_URL + "/project/cloneproject",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function GetAllProjectChannelByChildOrParent(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/getprojectchannelbychildorparent");
    const response = await fetch(
      REST_API_URL + "/project/getprojectchannelbychildorparent",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function GetProjectDetailByProjectCodeAPI(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/getprojectbyprojectcode");
    const response = await fetch(
      REST_API_URL + "/project/getprojectbyprojectcode",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function GetStaffWithTasks(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/getstaffwithtasks");
    const response = await fetch(
      REST_API_URL + "/project/getstaffwithtasks",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}

export async function DeleteAttachment(
  callback: any,
  params: any,
  tokenz: any
) {
  const newPostObj = { ...postObjAuth, body: JSON.stringify(params) };
  newPostObj.headers.Authorization = `Bearer ${tokenz}`;

  console.log(REST_API_URL + "/users/deleteattachment");
  const response = await fetch(
    REST_API_URL + "/users/deleteattachment",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function GetProjectStatusByProjectCode(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/project/getprojectstatusbyprojectcode");
    const response = await fetch(
      REST_API_URL + "/project/getprojectstatusbyprojectcode",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    // Error(err);
  }
}
