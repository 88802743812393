import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  useAuth,
  setLocalUsername,
  setLocalRole,
  getLocalRole,
  getLocalToken,
  setLocalPhotoPath,
  getLocalUsername,
  setLocalAlias,
  setSelectedChannel,
} from "../../context/AuthContext";
import { login } from "../../api/UserAPI";
import { mySwalAlertFailed, loadingpageLogin } from "../../components/Misc";
import moment from "moment";
import { GetAllPublicHolidayByYear } from "../../api/AdminAPI";
import { FILES_API_URL } from "../../components/ConstantAPI";
import { useHistory } from "react-router-dom";

const Welcome = (props: any) => {
  const { setAuthTokens, setIsRefresh, setHolidaysMain } = useAuth();
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [directToPassword, setDirectToPassword] = useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  let history = useHistory();

  const LoginButtonClicked = () => {
    setIsLoading(true);

    login(doLoginCB.bind(this), {
      Username: username,
      Password: password,
      RoleId: "1",
    });
  };

  React.useEffect(() => {
    if (getLocalToken() !== "undefined" && getLocalToken() !== null) {
      setSelectedChannel("mytasks");
      goToProjectListing("", "My Tasks", "", getLocalUsername(), "");
    }
  }, []);

  function doLoginCB(jsonObj: any) {
    setIsLoading(false);
    setSelectedChannel("mytasks");
    if (jsonObj.message === "Success") {
      getPublicHoliday(jsonObj.token);
      setIsRefresh(true);
      setLocalUsername(jsonObj.username);
      console.log(jsonObj.roleID);
      setLocalRole(jsonObj.roleID);
      setAuthTokens(jsonObj.token);
      setLocalAlias(jsonObj.alias);
      setLocalPhotoPath(FILES_API_URL + jsonObj.photoPath);

      if (jsonObj.roleID !== 0) {
        goToProjectListing("", "My Tasks", "", jsonObj.username, "");
      } else {
        setIsAdmin(true);
      }
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function getPublicHoliday(token: any) {
    GetAllPublicHolidayByYear(
      doGetDataCB,
      {
        SelectedPage: -1,
        Year: 0,
      },
      token
    );
  }

  function doGetDataCB(jsonObj: any) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      // console.log(temp);
      let tagArr = [];
      for (var i = 0; i < temp.length; i++) {
        var m = moment(temp[i].Date);
        // console.log(m.format("DD/MM/yyyy"));
        tagArr.push(m.format("DD/MM/yyyy"));
      }
      setHolidaysMain(tagArr);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function goToProjectListing(
    code: any,
    name: any,
    parent: any,
    userz: any,
    project: any
  ) {
    history.push({
      pathname: "/projectdetails",
      search:
        "?channelCode=" +
        code +
        "&channelName=" +
        name +
        "&userz=" +
        userz +
        "&parent=" +
        parent +
        "&projectCode=" +
        parent +
        "&projectID=" +
        project,
    });
  }

  const referer = "/dashboard";
  if (
    getLocalToken() !== "undefined" &&
    getLocalToken() !== null &&
    getLocalRole() === 1
  ) {
    return <Redirect to={referer} />;
  } else if (directToPassword) {
    return <Redirect to="/forgetpassword" />;
  } else {
    if (isLoading) {
      return loadingpageLogin();
    } else {
      return (
        <div>
          <div>
            <div className="vertical-align-wrap">
              <div
                className="vertical-align-middle auth-main loginBackgroundCustom"
                style={{
                  backgroundImage:
                    "url(../../../assets/images/BES_Wallpaper_Base_add500pixel.png) ",
                  zIndex: 0,
                  position: "relative",
                  // width: "100%",
                  // height: "100%",
                  top: "0",
                  // objectFit: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              >
                <div className="auth-box">
                  <div className="top">
                    <img src="../assets/images/login.png" alt="Brainy" />
                  </div>
                  <div className="card">
                    <div className="header">
                      <p className="lead">Login to your account</p>
                    </div>
                    <div className="body">
                      <div className="form-group">
                        <label
                          htmlFor="signin-email"
                          className="control-label sr-only"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="signin-email"
                          placeholder="Email"
                          value={username}
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="signin-password"
                          className="control-label sr-only"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="signin-password"
                          placeholder="Password"
                          value={password}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              LoginButtonClicked();
                            }
                          }}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group clearfix">
                        <label className="fancy-checkbox element-left">
                          <input type="checkbox" />
                          <span>Remember me</span>
                        </label>
                      </div>
                      <button
                        className="btn btn-primary btn-lg btn-block"
                        onClick={() => LoginButtonClicked()}
                      >
                        LOGIN
                      </button>
                      <div className="bottom">
                        <span className="helper-text m-b-10">
                          <i className="fa fa-lock"></i>{" "}
                          <a href="#" onClick={() => setDirectToPassword(true)}>
                            Forgot password?
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
};

export default Welcome;
