import React, { useState, useCallback, useRef } from "react";
import { GetAllChannel, GetAllProjectSetting } from "../../../api/AdminAPI";
import {
  GetProjectDetails,
  UpdateTask,
  CreateTask,
  DeleteTask,
  DeleteProject,
  UpdateProject,
  GetProjectsWithChannelCode,
  GetAllAttachmentByProjectTaskID,
  GetProjectsWithUsername,
  GetAllProjectChannelByChildOrParent,
  GetProjectDetailByProjectCodeAPI,
} from "../../../api/ProjectAPI";
import {
  getLocalToken,
  getLocalUsername,
  useAuth,
} from "../../../context/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { textValidation } from "../../../components/Validation";
import { mySwalAlertFailed, getTimeArr } from "../../../components/Misc";
import { Modal, Form } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import queryString from "query-string";
import moment from "moment";
import { REST_API_URL } from "../../../components/ConstantAPI";
import { Button } from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import SendMessage from "../../User/SendMessage";
import "./Details.css";
import { useHistory } from "react-router-dom";
import debounce from "lodash/debounce";
import Nestable from "react-nestable";
import { AiFillMessage, AiTwotoneDelete, AiOutlineDrag } from "react-icons/ai";

const Temp = (props) => {
  let history = useHistory();
  const { holidays, projectTaskID, setProjectTaskIDMain } = useAuth();
  const [projectData, setProjectData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [timeCB, setTimeCB] = useState([]);
  const [relatedStaffData, setRelatedStaffData] = useState([]);
  const [priorityData, setPriorityData] = useState([]);
  const [projectStatus, setProjectStatus] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [channelDataOption, setChannelDataOption] = useState([]);
  const [IsUpdate, setIsUpdate] = useState(false);
  const [data, setData] = React.useState([]);
  const [projectRelatedList, setProjectRelatedList] = React.useState([]);
  const MySwal = withReactContent(Swal);
  const [projectName, setProjectName] = useState("");
  const [dialogHeader, setDialogHeader] = useState("Create Group");
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [channelCode, setChannelCode] = useState("");
  const [selectedChannelData, setSelectedChannelData] = React.useState("");
  const [sequence, setSequence] = useState(0);
  const [nameInvalid, setNameInvalid] = React.useState("");
  const [codeInvalid, setCodeInvalid] = React.useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [detailsAssignee, setDetailsAssignee] = React.useState("");
  const [detailsRunningNo, setDetailsRunningNo] = React.useState("");
  const [selectedId, setSelectedId] = React.useState("");
  const [DetailsProjectName, setDetailsProjectName] = React.useState("");
  const [detailsRemark, setDetailsRemark] = React.useState("");
  const [detailsEndTime, setDetailsEndTime] = React.useState("");
  const [detailsStartTime, setDetailsStartTime] = React.useState("");
  const [detailsStartDate, setDetailsStartDate] = React.useState("");
  const [detailsEndDate, setDetailsEndDate] = React.useState("");
  const [DetailsDuration, setDetailsDuration] = React.useState(0);
  const [detailsStatus, setDetailsStatus] = React.useState("");
  const [testingData, setTestingData] = useState([]);
  const [channelName, setChannelName] = React.useState("");
  const [taskData, setTaskData] = React.useState([]);
  const [arrTaskAttachment, setArrTaskAttachment] = useState([]);
  const [showDialog, setShowDialog] = React.useState(false);
  const [taskName, setTaskName] = React.useState("");
  const [detailsProjectId, setDetailsProjectId] = React.useState(0);
  const [detailsPublishDate, setDetailsPublishDate] = React.useState("");
  const [detailsProjectCode, setDetailsProjectCode] = React.useState("");
  const [parentCode, setParentCode] = React.useState("");
  const [selectedUserz, setSelectedUserz] = React.useState("undefined");
  const disableFutureDt = (current) => {
    const yesterday = moment().subtract(1, "day");

    //disable saturday + sunday + past day
    return (
      current.day() !== 0 &&
      current.day() !== 6 &&
      current.isAfter(yesterday) &&
      holidays !== null &&
      !holidays.includes(current.format("DD/MM/yyyy"))
    );
  };

  //run only once
  React.useEffect(() => {
    let arr = getTimeArr();
    setTimeCB(arr);
  }, []);

  //run everytime a state changes
  React.useEffect(() => {
    let params = queryString.parse(window.location.search);
    let singleChannelCode = params.channelCode;
    let userz = params.userz;
    let projectID = params.projectID;

    setSelectedUserz(userz);

    //cater for case where projectID is passed in. This will first query the data for this project, next load channel and others data
    if (
      projectID !== "undefined" &&
      projectID !== undefined &&
      detailsProjectCode === ""
    ) {
      setDetailsProjectCode(projectID);
      GetProjectDetailByProjectCode(projectID);
    } else if (userz !== "undefined" && selectedUserz === "undefined") {
      //need to check selectedUserz else it will keep run

      // i feel don't need reset since MyTask is not changing between channel
      // resetChannel();
      // resetDetailsPage();
      loadTaskByUsername(userz);
      setChannelCode("");
      setChannelName(params.channelName);
      setParentCode(params.parent);
    } else if (
      channelCode !== singleChannelCode &&
      userz === "undefined" &&
      projectID === "undefined"
    ) {
      //need to check selectedUserz else it will come in here and reset the data
      resetChannel();
      resetDetailsPage();
      loadChannel(singleChannelCode);
      setChannelCode(singleChannelCode);
      setChannelName(params.channelName);
      setParentCode(params.parent);
    }
  });

  function GetProjectDetailByProjectCode(code) {
    GetProjectDetailByProjectCodeAPI(
      doGetProjectDetailByProjectCodeCB.bind(this),
      {
        ProjectCode: code,
      },
      getLocalToken()
    );
  }

  function doGetProjectDetailByProjectCodeCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      resetChannel();
      resetDetailsPage();
      loadChannel(jsonObj.channelCode);
      setChannelCode(jsonObj.channelCode);
      setChannelName(jsonObj.channelName);
      setParentCode(jsonObj.parent);
      populateDetailsPage(
        jsonObj.projectCode,
        jsonObj.projectName,
        jsonObj.runningNo,
        jsonObj.id,
        jsonObj.remark,
        jsonObj.publishDate
      );
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function GetProjectListing(channelCode) {
    GetProjectsWithChannelCode(
      doGetProjectListingCB.bind(this),
      {
        ChannelCode: channelCode,
      },
      getLocalToken()
    );
  }

  function doGetProjectListingCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      setProjectData(temp);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function GetProjectListingByUsername(userz) {
    GetProjectsWithUsername(
      doGetProjectListingByUsernameCB.bind(this),
      {
        Username: userz,
      },
      getLocalToken()
    );
  }

  function doGetProjectListingByUsernameCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      setProjectData(temp);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function loadChannel(channelCode) {
    GetProjectListing(channelCode);
    // GetSettings();//this is to get all channels and store to [data] which i don't think we need this anymore

    PopulatePriorityData();
    PopulateJobStatus();
  }

  function loadTaskByUsername(userz) {
    GetProjectListingByUsername(userz);
    PopulatePriorityData();
    PopulateJobStatus();
  }

  function resetChannel() {
    setProjectData([]);
    setStaffData([]);
    setRelatedStaffData([]);
    setData([]);
    setTestingData([]);
    setProjectRelatedList([]);

    resetTaskData();
  }

  function resetTaskData() {
    setTaskData([]);
    setProjectTaskIDMain(0);
    GetAttachmentByProjectTaskID(0);
    setTaskName("");
  }

  function renderStaffInCharge() {
    return relatedStaffData.map((staffData, index) => {
      let staffPic = REST_API_URL + staffData.PhotoPath;
      if (staffData.InvolveProject === "Yes")
        return (
          <li key={index}>
            <img
              src={staffPic}
              data-toggle="tooltip"
              data-placement="top"
              title={staffData.Name}
              alt="Avatar"
            />
          </li>
        );
    });
  }

  function doNothingCallBack(jsonObj) {
    if (jsonObj.message !== "Success") {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  const delayedOnUpdateProjectData = useCallback(
    debounce(
      (e, section, id, debounceData) =>
        OnUpdateProjectData(e, section, id, debounceData),
      1000
    ),
    []
  );

  function OnUpdateProjectData(e, section, id, debounceData) {
    let dataz = projectData;

    if (debounceData !== undefined) {
      dataz = debounceData;
    }

    if (
      id === "" ||
      (section === "publishdate" &&
        (!moment(e).isValid() || moment(e).year() > 9999))
    ) {
      // skip
      alert("Invalid Date");
    } else {
      UpdateProject(
        doNothingCallBack.bind(this),
        {
          id: id,
          Section: section,
          Value: e,
          UpdatedBy: getLocalUsername(),
        },
        getLocalToken()
      );
    }

    let newData = [];
    let tempArrTask = [...dataz];
    tempArrTask.map((element) => {
      if (element.id == id) {
        if (section === "title") {
          newData.push({
            RunningNo: element.RunningNo,
            ProjectCode: element.ProjectCode,
            id: element.id,
            ProjectName: e,
            Remark: element.Remark,
            PublishDate: element.PublishDate,
          });

          setDetailsProjectName(e);
        } else if (section === "publishdate") {
          newData.push({
            RunningNo: element.RunningNo,
            ProjectCode: element.ProjectCode,
            id: element.id,
            ProjectName: element.ProjectName,
            PublishDate: moment(e, "DD/MM/yyyy").format("DD/MM/yyyy"),
            Remark: element.Remark,
          });

          setDetailsPublishDate(e);
        } else if (section === "remark") {
          newData.push({
            RunningNo: element.RunningNo,
            ProjectCode: element.ProjectCode,
            id: element.id,
            ProjectName: element.ProjectName,
            Remark: e,
          });
        }
      } else {
        newData.push({
          RunningNo: element.RunningNo,
          ProjectCode: element.ProjectCode,
          id: element.id,
          ProjectName: element.ProjectName,
          Remark: element.Remark,
          PublishDate: element.PublishDate,
        });
      }
    });
    setProjectData(newData);
  }

  function OnUpdateData(e, section, taskId) {
    if (
      taskId === "" ||
      ((section === "duedate" || section === "startdate") &&
        !moment(e).isValid())
    ) {
      // skip
    } else {
      UpdateTask(
        doNothingCallBack.bind(this),
        {
          id: taskId,
          Section: section,
          Value: e,
        },
        getLocalToken()
      );
    }

    taskData.forEach((gps) => {
      if (gps === null) {
        console.log("IT IS NULL");
      } else if (gps === undefined) {
        console.log("IT IS UNDEFINED");
      } else {
        if (
          gps !== null &&
          gps !== undefined &&
          gps !== "undefined" &&
          gps !== ""
        ) {
          if (gps.children !== null) {
            var hasChanges = false;
            gps.children
              .filter((e) => e.id === taskId)
              .map((element) => {
                if (section === "assignee") {
                  console.log(e);
                  element.Assignee = e;
                } else if (section === "taskName") {
                  element.TaskName = e;
                } else if (section === "duedate") {
                  element.EndDate = moment(e).format("DD/MM/yyyy");
                } else if (section === "duetime") {
                  element.EndTime = e;
                } else if (section === "startdate") {
                  element.StartDate = moment(e, "DD/MM/yyyy HH:MM");
                } else if (section === "starttime") {
                  element.StartTime = e;
                } else if (section === "priority") {
                  element.Priority = e;
                } else if (section === "status") {
                  element.Status = e;
                }

                hasChanges = true;
              });

            if (hasChanges) {
              setTaskData([...taskData], gps);
            }
          }
        }
      }
    });

    //updating that array, somehow causes the hanging when typing in Task Name. We need to update this for those date/combobox
    // if (section !== "taskName")
    // {
    //   setTaskData((taskData) => [...taskData]);
    // }

    // The structured changed. Keeping below in case we wan to refer how to loop it using old way
    // Structured changed to
    // [Group]
    //    [children] > This is the tasks
    // [Add new]

    // console.log("****");
    // console.log(taskData.filter((e) => e.id === taskId));

    // let newData = [];
    // let tempArrTask = [...taskData];
    // tempArrTask.map((element) => {
    //   if (taskId === element.id) {
    //     if (section === "assignee") {
    //       newData.push({
    //         Assignee: e,
    //         TaskName: element.TaskName,
    //         EndDate: element.EndDate,
    //         StartDate: element.StartDate,
    //         StartTime: element.StartTime,
    //         Status: element.Status,
    //         TaskCode: element.TaskCode,
    //         id: element.id,
    //         Priority: element.Priority,
    //       });
    //     } else if (section === "taskName") {
    //       newData.push({
    //         Assignee: element.Assignee,
    //         TaskName: e,
    //         EndDate: element.EndDate,
    //         StartDate: element.StartDate,
    //         StartTime: element.StartTime,
    //         Status: element.Status,
    //         TaskCode: element.TaskCode,
    //         Priority: element.Priority,
    //         id: element.id,
    //       });
    //     } else if (section === "duedate") {
    //       newData.push({
    //         Assignee: element.Assignee,
    //         TaskName: element.TaskName,
    //         EndDate: moment(e).format("DD/MM/yyyy"),
    //         StartDate: element.StartDate,
    //         StartTime: element.StartTime,
    //         Status: element.Status,
    //         TaskCode: element.TaskCode,
    //         Priority: element.Priority,
    //         id: element.id,
    //       });
    //     } else if (section === "duetime") {
    //       newData.push({
    //         Assignee: element.Assignee,
    //         TaskName: element.TaskName,
    //         EndDate: element.EndDate,
    //         StartDate: element.StartDate,
    //         StartTime: element.StartTime,
    //         EndTime: e,
    //         Status: element.Status,
    //         TaskCode: element.TaskCode,
    //         Priority: element.Priority,
    //         id: element.id,
    //       });
    //     } else if (section === "startdate") {
    //       newData.push({
    //         Assignee: element.Assignee,
    //         TaskName: element.TaskName,
    //         EndDate: element.EndDate,
    //         StartDate: moment(e, "DD/MM/yyyy HH:MM"),
    //         StartTime: element.StartTime,
    //         Status: element.Status,
    //         TaskCode: element.TaskCode,
    //         Priority: element.Priority,
    //         id: element.id,
    //       });
    //     } else if (section === "starttime") {
    //       newData.push({
    //         Assignee: element.Assignee,
    //         TaskName: element.TaskName,
    //         EndDate: element.EndDate,
    //         StartDate: element.StartDate,
    //         StartTime: e,
    //         Status: element.Status,
    //         TaskCode: element.TaskCode,
    //         Priority: element.Priority,
    //         id: element.id,
    //       });
    //     } else if (section === "priority") {
    //       newData.push({
    //         Assignee: element.Assignee,
    //         TaskName: e,
    //         EndDate: element.EndDate,
    //         StartDate: element.StartDate,
    //         StartTime: element.StartTime,
    //         Status: element.Status,
    //         TaskCode: element.TaskCode,
    //         Priority: e,
    //         id: element.id,
    //       });
    //     } else if (section === "status") {
    //       newData.push({
    //         Assignee: element.Assignee,
    //         TaskName: element.TaskName,
    //         EndDate: element.EndDate,
    //         StartDate: element.StartDate,
    //         StartTime: element.StartTime,
    //         Status: e,
    //         TaskCode: element.TaskCode,
    //         Priority: element.Priority,
    //         id: element.id,
    //       });
    //     }
    //   } else {
    //     newData.push(element);
    //   }
    // });

    // setTaskData(tempArrTask);
  }

  function searchElement(array, id) {
    return array.map((element) => {
      return element.id === id ? element : null;
    });
  }

  function doGetProjectData(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      setTaskData(temp);

      let stafftemp = JSON.parse(jsonObj.staffListing);
      let tagArr = [];
      let relatedStaff = [];
      for (var i = 0; i < stafftemp.length; i++) {
        tagArr.push({
          value: stafftemp[i].Username,
          label: stafftemp[i].Name,
        });

        if (stafftemp[i].InvolveProject === "Yes") {
          relatedStaff.push(stafftemp[i]);
        }
      }

      setRelatedStaffData(relatedStaff);
      setStaffData(tagArr);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function PopulateJobStatus() {
    GetAllProjectSetting(
      doGetJobStatusCB.bind(this),
      {
        FilterCode: "",
        SelectedPage: selectedPage,
      },

      getLocalToken()
    );
  }

  function doGetJobStatusCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);

      let tagArr = [];

      tagArr.push({
        value: "",
        label: "Please Select Status",
      });

      for (var i = 0; i < temp.length; i++) {
        tagArr.push({
          value: temp[i].StatusCode,
          label: temp[i].StatusName,
        });
      }

      setProjectStatus(tagArr);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function PopulatePriorityData() {
    let tagArr = [];

    tagArr.push({
      value: "high",
      label: "High",
    });

    tagArr.push({
      value: "medium",
      label: "Medium",
    });

    tagArr.push({
      value: "low",
      label: "Low",
    });

    setPriorityData(tagArr);
  }

  function GetSettings() {
    GetAllChannel(
      doGetDataCB.bind(this),
      {
        FilterCode: "",
        SelectedPage: selectedPage,
      },
      getLocalToken()
    );
  }

  function doGetDataCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      setData(temp);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function populateDetailsPage(
    projectCode,
    projectName,
    runningNo,
    id,
    Remark,
    publishDate,
    status
  ) {
    resetTaskData();
    resetDetailsPage();
    setDetailsRunningNo(runningNo);
    setDetailsProjectName(projectName);
    setDetailsPublishDate(publishDate);
    setDetailsRemark(Remark);
    setDetailsStatus(status);
    setDetailsProjectId(id);
    setDetailsProjectCode(projectCode);
    GetProjectDetails(
      doGetProjectData.bind(this),
      {
        ProjectCode: projectCode,
      },
      getLocalToken()
    );
    GetProjectChannelByChildOrParent(projectCode);
  }

  function GetProjectChannelByChildOrParent(code) {
    GetAllProjectChannelByChildOrParent(
      doGetProjectChannelByChildOrParent.bind(this),
      {
        ProjectCode: code,
      },
      getLocalToken()
    );
  }

  function doGetProjectChannelByChildOrParent(jsonObj) {
    if (jsonObj.message === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      // console.log(temp);
      setProjectRelatedList(temp);
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function resetDetailsPage() {
    setDetailsRunningNo("");
    setDetailsProjectName("");
    setTaskData([]);
    setStaffData([]);
  }

  function doCreateCB(jsonObj) {
    if (jsonObj.message === "Success") {
      setTaskData([]);

      let temp = JSON.parse(jsonObj.listing);
      setTaskData(temp);
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function resetDialogData() {
    setName("");
    setCode("");
    setSequence(0);
    setProjectName("");
  }

  function onAddRow(groupCode) {
    CreateTask(
      doCreateCB.bind(this),
      {
        ProjectCode: detailsProjectCode,
        GroupCode: groupCode,
        UpdatedBy: "admin",
      },
      getLocalToken()
    );
  }

  function removeTaskConfirmation(Id, projectCode, runningNo) {
    MySwal.fire({
      title: "Confirmation",
      text: "Are you sure you want to delete this task " + runningNo + "?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        DeleteTask(
          doCreateCB.bind(this),
          {
            id: Id,
            ProjectCode: projectCode,
            UpdatedBy: "admin",
          },
          getLocalToken()
        );
      }
    });
  }

  function removeProjectConfirmation(Id, runningNo) {
    MySwal.fire({
      title: "Confirmation",
      text: "Are you sure you want to delete this project " + runningNo + "?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        DeleteProject(
          doCreateCB.bind(this),
          {
            id: Id,
            ChannelCode: channelCode,
            UpdatedBy: getLocalUsername(),
          },
          getLocalToken()
        );
      }
    });
  }

  function populateProjectTable() {
    return projectData.map((settings, index) => {
      const {
        id,
        ProjectCode,
        ProjectName,
        RunningNo,
        Remark,
        PublishDate,
        Status,
      } = settings;
      return (
        <tr key={index}>
          <td>
            <span>
              <a
                href="#"
                onClick={() => {
                  populateDetailsPage(
                    ProjectCode,
                    ProjectName,
                    RunningNo,
                    id,
                    Remark,
                    PublishDate,
                    Status
                  );
                }}
              >
                {RunningNo}
              </a>
              <a
                class="icon-menu"
                href="#"
                onClick={() => removeProjectConfirmation(id, RunningNo)}
              >
                <i class="icon-trash"></i>
              </a>
            </span>
          </td>
          <td>
            <span>
              <Form.Control
                placeholder="Name"
                value={ProjectName}
                onChange={(e) => {
                  settings.ProjectName = e.target.value;
                  OnUpdateProjectData(e.target.value, "title", id);
                }}
              />
            </span>
          </td>

          <td>
            <span>
              {" "}
              <DatePicker
                className="form-control"
                style={{ width: "100%" }}
                placeholder="Publish Date"
                dateFormat="DD/MM/yyyy"
                value={PublishDate}
                onChange={(e) => {
                  // settings.PublishDate = moment(e, "DD/MM/yyyy HH:MM");
                  settings.PublishDate = moment(e, "DD/MM/yyyy").format(
                    "DD/MM/yyyy"
                  );
                  // PublishDate: moment(e, "DD/MM/yyyy HH:MM"),
                  OnUpdateProjectData(e, "publishdate", id);
                }}
              />
            </span>
          </td>
        </tr>
      );
    });
  }

  function populateTaskDetailsPage(taskName, Id) {
    setTaskName(taskName);
    setSelectedId(Id);
    setProjectTaskIDMain(Id);
    GetAttachmentByProjectTaskID(Id);
    setShowDialog(true);
  }

  function GetAttachmentByProjectTaskID(Id) {
    GetAllAttachmentByProjectTaskID(
      doGetAttachmentByProjectTaskIDCB.bind(this),
      {
        ProjectTaskID: Id,
      },
      getLocalToken()
    );
  }

  function doGetAttachmentByProjectTaskIDCB(jsonObj) {
    if (jsonObj.message === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      setArrTaskAttachment(temp);
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  const renderItem = ({ item, index }) => {
    // console.log(item);
    const {
      id,
      GroupName,
      GroupCode,
      TaskName,
      TaskCode,
      RunningNo,
      Assignee,
      EndDate,
      StartDate,
      Remark,
      Priority,
      ProjectCode,
      StartTime,
      EndTime,
      Status,
      ProjectName,
    } = item;

    if (GroupCode === "") {
      return (
        <div className="col-lg-3 col-md-6" key={index}>
          <h6>{GroupName}</h6>
        </div>
      );
    } else if (GroupName === "LastItem") {
      return (
        <div className="col-lg-3 col-md-6" key={index}>
          <Button variant="primary" onClick={() => onAddRow(GroupCode)}>
            Add
          </Button>
        </div>
      );
    } else {
      return (
        <div className="row clearfix" key={index}>
          <div className="col-lg-2 col-md-6">
            <span>
              <a draggable="true" style={{ cursor: "grab" }}>
                <AiOutlineDrag size={30} style={{ marginRight: "20px" }} />
                {/* <i className="icon-target" style={{ marginRight: "20px" }}></i> */}
              </a>
              <a
                class="icon-menu"
                href="#"
                onClick={() => populateTaskDetailsPage(TaskName, id)}
              >
                <AiFillMessage size={30} style={{ marginRight: "20px" }} />
              </a>
              <a
                class="icon-menu"
                href="#"
                onClick={() =>
                  removeTaskConfirmation(id, ProjectCode, RunningNo)
                }
              >
                <AiTwotoneDelete size={30} />
              </a>
            </span>
          </div>
          <div className="col-lg-2 col-md-6">
            {/* {id} */}
            <span>
              <Form.Control
                placeholder="Task Name"
                value={TaskName}
                // defaultValue={TaskName} //using value, will cause hanging when typing. But another issue is the drag and drop
                onChange={(e) => {
                  OnUpdateData(e.target.value, "taskName", id);
                }}
              />
            </span>
          </div>
          <div className="col-lg-2 col-md-6">
            <span>
              <Select
                style={{ width: "100px" }}
                placeholder=""
                options={staffData}
                onChange={(e) => {
                  OnUpdateData(e.value, "assignee", id);
                }}
                value={staffData.find((obj) => obj.value === Assignee)}
              />
            </span>
          </div>
          <div className="col-lg-2 col-md-6">
            <span>
              {" "}
              {/* <DatePicker
                  className="form-control"
                  style={{ width: "100%" }}
                  placeholder="Start Date"
                  value={StartDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(e) => {
                    item.StartDate = moment(e).format("DD/MM/yyyy");
                    item.StartTime = moment(e).format("HH:MM");
                    OnUpdateData(e, "startdate", id);
                  }}
                /> */}
              <Datetime
                value={StartDate}
                // initialValue={StartDate}
                dateFormat="DD/MM/yyyy"
                closeOnSelect
                renderView={(mode, renderDefault) =>
                  renderView(mode, renderDefault, StartTime, "starttime", id)
                }
                isValidDate={disableFutureDt}
                timeFormat={false}
                onChange={(e) => {
                  OnUpdateData(e, "startdate", id);
                }}
              />
            </span>
          </div>
          <div className="col-lg-2 col-md-6">
            <span>
              {" "}
              {/* <DatePicker
                  className="form-control"
                  style={{ width: "100%" }}
                  placeholder="Due Date"
                  value={EndDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(e) => {
                    item.EndDate = moment(e).format("DD/MM/yyyy");
                    item.EndTime = moment(e).format("HH:MM");
                    OnUpdateData(e, "duedate", id);
                  }}
                /> */}
              <Datetime
                value={EndDate}
                // initialValue={EndDate}
                dateFormat="DD/MM/yyyy"
                closeOnSelect
                renderView={(mode, renderDefault) =>
                  renderView(mode, renderDefault, EndTime, "duetime", id)
                }
                isValidDate={disableFutureDt}
                timeFormat={false}
                onChange={(e) => {
                  OnUpdateData(e, "duedate", id);
                }}
              />
            </span>
          </div>
          <div className="col-lg-2 col-md-6">
            <span>
              {" "}
              <Select
                style={{ width: "100px" }}
                placeholder=""
                options={projectStatus}
                onChange={(e) => {
                  item.Status = e.value;
                  OnUpdateData(e.value, "status", id);
                }}
                value={projectStatus.find((obj) => obj.value === Status)}
              />{" "}
            </span>
          </div>
        </div>
      );
    }
  };

  function renderTableData() {
    return arrTaskAttachment.map((attach, index) => {
      const { ID, FilePath } = attach;
      return (
        <tr key={index}>
          <td>
            <a href={FilePath} target="_blank">
              {FilePath}
            </a>
          </td>
        </tr>
      );
    });
  }

  function renderProjectRelated() {
    return projectRelatedList.map((input, index) => {
      const { ProjectCode, ChannelCode, ChannelName } = input;
      return (
        <a
          key={index}
          href="#"
          onClick={() => {
            setDetailsProjectCode("");
            history.push({
              pathname: "/projectdetails",
              search:
                "?channelCode=" +
                "" +
                "&channelName=" +
                "" +
                "&userz=" +
                "undefined" +
                "&parent=" +
                "" +
                "&projectID=" +
                ProjectCode,
            });
          }}
        >
          {ProjectCode}
        </a>
      );
    });
  }

  const handleClose = () => {
    setShowDialog(false);
  };

  function goToCreateProject(code, adhoc) {
    history.push({
      pathname: "/newproject",
      search: "?channelCode=" + code + "&adhoc=" + adhoc,
    });
  }

  function renderView(mode, renderDefault, time, section, taskId) {
    // Only for years, months and days view
    if (mode === "time") return renderDefault();

    return (
      <div className="wrapper">
        {renderDefault()}
        <div className="controls">
          <Select
            style={{ width: "100px" }}
            placeholder=""
            options={timeCB}
            defaultValue={timeCB.find((obj) => obj.value === time)} //need to use default value instead of value. Else when you change selection it wont update
            onChange={(e) => {
              OnUpdateData(e.value, section, taskId);
            }}
          />
        </div>
      </div>
    );
  }

  const onItemOnChange = (items, item) => {
    // console.log(items);
    // console.log(item);
    let seq = 1;

    items
      .filter((e) => e.GroupCode === item.GroupCode)
      .map((element) => {
        if (element.id < 1000) {
          // console.log(element);
          UpdateTask(
            doNothingCallBack.bind(this),
            {
              id: element.id,
              Section: "sequence",
              Value: seq,
            },
            getLocalToken()
          );
        }

        seq++;
      });

    let newData = [];
    let tempArrTask = [...items];
    tempArrTask.map((element) => {
      newData.push(element);
    });

    setTaskData(tempArrTask);
  };

  const onItemConfirmChange = (dragItem, destinationParent) => {
    //console.log("dragitem");
    //console.log(dragItem);

    if (
      destinationParent !== null &&
      dragItem.GroupName === destinationParent.GroupName
    ) {
      return true;
    }
    return false;
  };

  return (
    <div id="main-content" class="profilepage_1">
      <div class="container-fluid">
        <div class="block-header">
          <div class="row">
            <div class="col-lg-6 col-md-8 col-sm-12">
              <h2>
                <a
                  href="javascript:void(0);"
                  class="btn btn-xs btn-link btn-toggle-fullwidth"
                >
                  <i class="fa fa-arrow-left"></i>
                </a>{" "}
                {channelName}
              </h2>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="index.html">
                    <i class="icon-home"></i>
                  </a>
                </li>
                <li class="breadcrumb-item">Projects</li>
                <li class="breadcrumb-item active">Listing</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row clearfix">
          <div class="col-lg-4 col-md-12">
            <div class="mt-4">
              {parentCode !== "" ? (
                ""
              ) : (
                <button
                  type="submit"
                  class="btn btn-primary"
                  onClick={() => goToCreateProject(channelCode, false)}
                >
                  Create Project
                </button>
              )}
              &nbsp;
              <button
                type="submit"
                class="btn btn-primary"
                onClick={() => goToCreateProject(channelCode, true)}
              >
                Adhoc Project
              </button>
            </div>
            <br />
            <div class="card">
              <div class="body">
                <div className="table-responsive">
                  <table className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list">
                    <thead className="thead-white">
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Publish Date</th>
                      </tr>
                    </thead>
                    <tbody>{populateProjectTable()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-md-12">
            <div class="card">
              <div class="header">
                <h2>{detailsRunningNo}</h2>
                {renderProjectRelated()}
              </div>
              <div class="body">
                <Form.Label>Project Title</Form.Label>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    placeholder=""
                    value={DetailsProjectName}
                    onChange={(e) => {
                      setDetailsProjectName(e.target.value);
                      delayedOnUpdateProjectData(
                        e.target.value,
                        "title",
                        detailsProjectId,
                        projectData
                      );
                    }}
                  />
                </Form.Group>
                <Form.Label>Channel Name</Form.Label>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    placeholder=""
                    value={channelName}
                    disabled={true}
                  />
                </Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    placeholder=""
                    value={detailsStatus}
                    disabled={true}
                  />
                </Form.Group>
                <Form.Label>Target Publish Date</Form.Label>
                <Form.Group controlId="formBasicEmail">
                  <div className="customDatePickerWidth">
                    <Datetime
                      value={detailsPublishDate}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      isValidDate={disableFutureDt}
                      timeFormat={false}
                      onChange={(e) => {
                        delayedOnUpdateProjectData(
                          e,
                          "publishdate",
                          detailsProjectId,
                          projectData
                        );
                      }}
                    />
                  </div>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Collaborators</Form.Label>
                  <ul class="list-unstyled team-info">
                    {renderStaffInCharge()}
                  </ul>
                </Form.Group>
                <div class="form-group">
                  <Form.Label>Remark</Form.Label>
                  <textarea
                    rows="4"
                    class="form-control"
                    placeholder="Remark"
                    value={detailsRemark}
                    onChange={(e) => {
                      setDetailsRemark(e.target.value);
                      delayedOnUpdateProjectData(
                        e.target.value,
                        "remark",
                        detailsProjectId,
                        projectData
                      );
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="header">
                <h2>Tasks</h2>
              </div>
              <div class="body">
                <Nestable
                  items={taskData}
                  renderItem={renderItem}
                  onChange={onItemOnChange}
                  confirmChange={onItemConfirmChange}
                  maxDepth="1"
                />
                {/* <div className="table-responsive">
                  <table className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list">
                    <thead className="thead-white">
                      <tr>
                        <th>Name</th>
                        <th>Publish Date</th>
                        <th>Assignee</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>{populateTaskTable()}</tbody>
                  </table>
                </div> */}
              </div>
            </div>
            {/* <div class="card">
              <div class="header">
                <h2>Attachment</h2>
              </div>
              <div class="body">
                <table className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list">
                  <tbody>{renderTableData()}</tbody>
                </table>
              </div>
            </div>
            <div class="card">
              <div class="header">
                <h2>MessageBox</h2>
              </div>
              <div class="body">
                <SendMessage projectTaskID={projectTaskID} />
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <Modal
        show={showDialog}
        onHide={handleClose}
        dialogClassName="modal-30w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title>{taskName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="card">
            <div class="header">
              <h2>Attachment</h2>
            </div>
            <div class="body">
              <table className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list">
                <tbody>{renderTableData()}</tbody>
              </table>
            </div>
          </div>
          <div class="card">
            <div class="header">
              <h2>MessageBox</h2>
            </div>
            <div class="body">
              <SendMessage projectTaskID={projectTaskID} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close Modal
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Temp;
