import React, { useState, useRef } from "react";
import { UpdateProfile } from "../../../api/AdminAPI";
import { GetUserInfoByUsername, UploadImage } from "../../../api/UserAPI";
import {
  getLocalToken,
  getLocalUsername,
  setLocalAlias,
  setLocalPhotoPath,
  useAuth,
  setLocalRole,
} from "../../../context/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "react-datepicker/dist/react-datepicker.css";
import "../../../custom.css";
import moment from "moment";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import {
  mySwalAlertFailed,
  mySwalAlertSuccess,
  getArrRoles,
  getArrTeams,
  getArrEmploymentStatus,
  loadingpage,
  massageFileName,
} from "../../../components/Misc";
import {
  textValidation,
  emailValidation,
} from "../../../components/Validation";
import Datetime from "react-datetime";
import {
  REST_API_URL,
  FILES_API_URL,
  FILES_BASE_URL,
  DEFAULT_IMAGE,
} from "../../../components/ConstantAPI";
import "./profileStyles.css";

function Profile(props) {
  const { setAuthTokens } = useAuth();
  const [searchUsername, setSearchUsername] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);
  const [data, setData] = React.useState([]);
  const MySwal = withReactContent(Swal);
  const [username, setUsername] = useState("");
  const [dialogHeader, setDialogHeader] = useState("Update Profile");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [salary, setSalary] = useState(0);
  const [travelAllowance, setTravelAllowance] = useState(0);
  const [onboardingDate, setOnboardingDate] = useState(new Date());
  const [arrTeam, setArrTeam] = React.useState([]);
  const [selectedTeam, setSelectedTeam] = React.useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [arrRoles, setArrRoles] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState("");
  const [nameInvalid, setNameInvalid] = React.useState("");
  const [emailInvalid, setEmailInvalid] = React.useState("");
  const [phoneInvalid, setPhoneInvalid] = React.useState("");
  const [onboardingInvalid, setOnboardingInvalid] = React.useState("");
  const [jobTitleInvalid, setJobTitleInvalid] = React.useState("");
  const [showDialog, setShowDialog] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const [alias, setAlias] = useState("");
  const [course, setCourse] = useState("");
  const [college, setCollege] = useState("");
  const [interest, setInterest] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDOB] = useState("");
  const [activePage, setActivePage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [itemCountPerPage, setItemCountPerPage] = React.useState(50);
  const [picImage, setPicImage] = React.useState(null);
  const [picObject, setPicObject] = React.useState(null);
  const [shortNameInvalid, setShortNameInvalid] = React.useState("");
  const [shortName, setShortName] = React.useState("");
  const hiddenFileInput = React.useRef(null);
  const focusRef = useRef();
  const [selectedEmpStatus, setSelectedEmpStatus] = React.useState("");
  const [arrEmployementStatus, setArrEmployementStatus] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function showImage(e) {
    setPicImage(URL.createObjectURL(e.target.files[0]));
    setPicObject(e.target.files[0]);
  }

  function resetDialogData() {
    setName("");
    setPhone("");
    setEmail("");
    setUsername("");
    setShortName("");
    setUrl("");
    setSalary(0);
    setTravelAllowance(0);
    setOnboardingDate(new Date());
    setAlias("");
    setDOB("");
    setCourse("");
    setCollege("");
    setInterest("");
    setAddress("");
  }

  function OnCreateUser() {
    var nameInv = textValidation("Name", name, 3);
    var emailInv = emailValidation(email);
    var phoneInv = textValidation("Phone", phone, 9);

    setNameInvalid(nameInv);
    setEmailInvalid(emailInv);
    setPhoneInvalid(phoneInv);

    if (nameInv === null && emailInv === null && phoneInv === null) {
      uploadDroppedFilesToServer(picObject, name);
    }
  }

  const handleSelectRole = (e) => {
    setSelectedRole(e);
  };

  function doCreateUserCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      mySwalAlertSuccess("Update Successfully");
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  React.useEffect(() => {
    setIsLoading(true);

    GetUserInfoByUsername(
      doEditUserCB.bind(this),
      {
        Username: getLocalUsername(),
        Admin: getLocalUsername(),
      },
      getLocalToken()
    );

    let arr = getArrRoles();
    if (arr.length !== 0 && setSelectedRole !== "") {
      setSelectedRole(arr[0].label);
    }
    setArrRoles(arr);

    arr = getArrTeams();
    if (arr.length !== 0 && setSelectedTeam !== "") {
      setSelectedTeam(arr[0].label);
    }
    setArrTeam(arr);

    arr = getArrEmploymentStatus();
    if (arr.length !== 0 && setArrEmployementStatus !== "") {
      setSelectedEmpStatus(arr[0].label);
    }
    setArrEmployementStatus(arr);
  }, []);

  function doEditUserCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      setName(jsonObj.name);
      setPhone(jsonObj.phone);
      setEmail(jsonObj.email);
      setUsername(jsonObj.username);
      setSelectedRole(jsonObj.role);
      setSalary(jsonObj.salary);
      setTravelAllowance(jsonObj.travelAllowance);
      setOnboardingDate(moment(jsonObj.onBoardingDate).format("DD/MM/yyyy"));
      setSelectedTeam(jsonObj.team);
      setJobTitle(jsonObj.jobTitle);
      setSelectedEmpStatus(jsonObj.employmentStatus);
      setAlias(jsonObj.alias);
      setDOB(jsonObj.dob === null ? "" : moment(jsonObj.dob).toDate());
      setCourse(jsonObj.course);
      setCollege(jsonObj.college);
      setInterest(jsonObj.interest);
      setAddress(jsonObj.address);

      if(jsonObj.photo !== undefined && jsonObj.photo !== "") {
        setPicImage(FILES_API_URL + jsonObj.photo);
        setLocalPhotoPath(FILES_API_URL + jsonObj.photo);
      }
      else {
        setPicImage("");
      }
      setIsLoading(false);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function uploadDroppedFilesToServer(file) {
    const formData = new FormData();
    formData.append("qqfile", file);
    formData.append("username", username.toLowerCase());
    formData.append("folder", "personal");
    formData.append("subfolder", "");

    if (file != null) {
      fetch(FILES_API_URL + "/users/upload", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          var fullPath =
            "/" +
            FILES_BASE_URL +
            "/" +
            username.toLowerCase() +
            "/personal/" +
            massageFileName(file.name);
          UpdateAccount(fullPath);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      UpdateAccount(null);
    }
  }

  function UpdateAccount(fullPath) {
    setLocalAlias(alias);
    if (fullPath !== null) {
      setLocalPhotoPath(FILES_API_URL + fullPath);
    }
    var dobDT =
      dob === "" || dob === null
        ? new Date("2000-01-01")
        : moment(dob).format("MM/DD/yyyy");
    // : moment(dob).format("DD/MM/yyyy"); //Windows when debugging local needed this
    UpdateProfile(
      doCreateUserCB.bind(this),
      {
        Username: username,
        Name: name,
        Email: email,
        Photo: fullPath,
        Phone: phone,
        Admin: getLocalUsername(),
        Course: course,
        College: college,
        Interest: interest,
        Address: address,
        ShortName: "",
        Alias: alias,
        DOB: dobDT,
      },
      getLocalToken()
    );
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleSelectTeam = (e) => {
    setSelectedTeam(e);
  };

  const handleSelectEmploymentStatus = (e) => {
    setSelectedEmpStatus(e);
  };

  const btnLogOut = () => {
    setAuthTokens();
    setLocalRole("undefined");
  };

  return (
    <div id="main-content">
      {/* {isLoading ? loadingpage() : ""} */}
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2>
                <a className="btn btn-xs btn-link btn-toggle-fullwidth"></a> My
                Profile
              </h2>
              <ul className="breadcrumb"></ul>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="body">
            <div className="row clearfix">
              <div className="col-md-12">
                <div className="form-group">
                  <Form.Label>Photo</Form.Label>
                  <br />

                  <div className="flex-containerProfile">
                    <div>
                      <img
                        src={picImage}
                        style={{ width: 200 }}
                        onError={(e) => (
                          (e.target.onerror = null),
                          (e.target.src = DEFAULT_IMAGE)
                        )}
                      />
                    </div>
                    <div>
                      <Form.Label style={{ fontSize: "12px", color: "grey" }}>
                        Your photo must be in square mode 1:1 aspect ratio with
                        a resolution of 1000×1000.
                      </Form.Label>
                      <br />
                      <Form.Label style={{ fontSize: "12px", color: "grey" }}>
                        Minimum 500x500.
                      </Form.Label>
                      <br />
                      <Button onClick={handleClick}>Upload Photo</Button>
                    </div>
                  </div>

                  <input
                    type="file"
                    name="file"
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                    onChange={(e) => {
                      showImage(e);
                    }}
                  />
                  {/* <img
                  src={picImage}
                  style={{ width: 200 }}
                  onError={(e) => (
                    (e.target.onerror = null),
                    (e.target.src = DEFAULT_IMAGE),
                    (e.target.width = 200)
                  )}
                /> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Form.Label>Full Name</Form.Label>
                  {""}
                  <span style={{ color: "red" }}>*</span>
                  <Form.Control
                    placeholder="Full Name"
                    name="search"
                    autoComplete={"" + Math.random()}
                    ref={focusRef}
                    value={name}
                    onChange={(e) => {
                      setNameInvalid("");
                      setName(e.target.value);
                    }}
                  />
                  <span style={{ color: "red" }}>{nameInvalid}</span>{" "}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Form.Label>Preferred Name</Form.Label>
                  <Form.Control
                    placeholder="Preferred Name"
                    value={alias}
                    disabled={true}
                    onChange={(e) => {
                      setAlias(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Form.Label>Contact No</Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  <Form.Control
                    placeholder="Contact No"
                    value={phone}
                    onChange={(e) => {
                      setPhoneInvalid("");
                      setPhone(e.target.value);
                    }}
                  />
                  <span style={{ color: "red" }}>{phoneInvalid}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Form.Label>Email address</Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  <Form.Control
                    type="email"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => {
                      setEmailInvalid("");
                      setEmail(e.target.value);
                    }}
                  />
                  <span style={{ color: "red" }}>{emailInvalid}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Form.Label>On Boarding Date</Form.Label>
                  <Form.Control
                    placeholder="Boarding Date"
                    value={onboardingDate}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Form.Label>Job Title</Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  <Form.Control
                    placeholder="Job Title"
                    value={jobTitle}
                    disabled={true}
                  />
                  <span style={{ color: "red" }}>{jobTitleInvalid}</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <Form.Label>Team</Form.Label>
                  <Dropdown
                    id="dropdown-menu-align-right"
                    onSelect={handleSelectTeam}
                  >
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="buttonMax"
                    >
                      {selectedTeam}
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <Form.Label>Employment Status</Form.Label>
                  <Dropdown
                    id="dropdown-menu-align-right"
                    onSelect={handleSelectEmploymentStatus}
                  >
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="buttonMax"
                    >
                      {selectedEmpStatus}
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <Form.Label>Role</Form.Label>
                  <Dropdown
                    id="dropdown-menu-align-right"
                    onSelect={handleSelectRole}
                  >
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="buttonMax"
                    >
                      {selectedRole}
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              </div>
              <div className="col-md-6">
                <Form.Label>Birthday</Form.Label>
                <Datetime
                  value={dob}
                  dateFormat="DD/MM/yyyy"
                  closeOnSelect
                  timeFormat={false}
                  onChange={(e) => {
                    setDOB(e);
                  }}
                />
              </div>

              {/* <div className="col-md-6">
              <div className="form-group">
                <Form.Label>Interest</Form.Label>
                <Form.Control
                  placeholder="Interest"
                  value={interest}
                  onChange={(e) => {
                    setInterest(e.target.value);
                  }}
                />
              </div>
            </div> */}
              <div className="col-md-6">
                <div className="form-group">
                  <Form.Label>College/University</Form.Label>
                  <Form.Control
                    placeholder="College/University"
                    value={college}
                    onChange={(e) => {
                      setCollege(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Form.Label>Course of Study</Form.Label>
                  <Form.Control
                    placeholder="Course of Study"
                    value={course}
                    onChange={(e) => {
                      setCourse(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <Form.Label>Residential Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Residential Address"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group" style={{ float: "left" }}>
                  <Button variant="danger" onClick={() => btnLogOut()}>
                    Sign Out
                  </Button>{" "}
                  &nbsp;
                </div>
                <div className="form-group" style={{ float: "right" }}>
                  <Button variant="primary" onClick={() => OnCreateUser()}>
                    Save
                  </Button>{" "}
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
