import React, { Component } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { createEditorStateWithText } from "draft-js-plugins-editor";
import "../SendMessage/editorStyles.css";
import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js-hashtag-plugin/lib/plugin.css";
import "draft-js-emoji-plugin/lib/plugin.css";
import "draft-js-linkify-plugin/lib/plugin.css";
import {
  GetAllAttachmentByProjectTaskID,
  DeleteAttachment,
} from "../../../api/ProjectAPI";
import { getLocalToken, getLocalUsername } from "../../../context/AuthContext";
import { mySwalAlertFailed, GetPeriodString } from "../../../components/Misc";

export default class AttachmentBoxUI extends Component {
  intervalPinID;

  constructor(props) {
    super(props);
  }

  //when using MODAL POP UP
  componentWillMount() {
    clearTimeout(this.intervalPinID);
    this.GetAttachmentByProjectTaskID();
  }

  componentWillUnmount() {
    clearTimeout(this.intervalPinID);

    this.setState = (state, callback) => {
      return;
    };
  }

  GetAttachmentByProjectTaskID() {
    GetAllAttachmentByProjectTaskID(
      this.doGetAttachmentByProjectTaskIDCB.bind(this),
      {
        ProjectTaskID: this.props.projectTaskID,
      },
      getLocalToken()
    );
  }

  doGetAttachmentByProjectTaskIDCB(jsonObj) {
    if (jsonObj.message === "Success") {
      let temp = JSON.parse(jsonObj.listing);

      this.setState({
        data: temp,
      });
    } else {
      mySwalAlertFailed(jsonObj.message);
    }

    // call retrievePinMessages() again in 5 seconds
    this.intervalPinID = setTimeout(
      this.GetAttachmentByProjectTaskID.bind(this),
      1000
    );
  }

  state = {
    data: [],
  };

  onDelete(e, id, filepath) {
    DeleteAttachment(
      this.doDeleteAttachmentCB.bind(this),
      {
        Id: id,
        FilePath: filepath,
        Username: getLocalUsername(),
      },
      getLocalToken()
    );
  }

  doDeleteAttachmentCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  renderTableData() {
    return this.state.data.map((attach, index) => {
      const {
        ID,
        ProjectTaskID,
        FilePath,
        FileName,
        FileType,
        FileIcon,
      } = attach;

      if (FileType === "PNG" || FileType === "JPG" || FileType === "JPEG") {
        return (
          <div
            className="ColDocumentIMGCSS"
            data-toggle="tooltip"
            style={{ marginTop: "0px", marginBottom: "5px" }}
            title=""
            data-original-title="Avatar.JPG"
          >
            <div className="AttachmentimageCSS">
              <a
                href={FilePath}
                target="_blank"
                style={{
                  fontWeight: "bold",
                  paddingLeft: "5px",
                  paddingRight: "20px",
                }}
              >
                <img src={FilePath} style={{ float: "left" }}></img>
              </a>
            </div>
            <div className="buttonDropIconCSS">
              <button
                className="buttonDownIconCSS btn btn-sm btn-light fa fa-angle-down"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              ></button>
              <div className="dropdown-menu">
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => this.onDelete(e, ProjectTaskID, FilePath)}
                >
                  Delete Attachment
                </a>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div
          className="ColDocumentCSS"
          data-toggle="tooltip"
          style={{ maxHeight: "50px", marginTop: "2px" }}
          title={FileName}
          key={index}
        >
          <div className="FAFileIconCSS">
            <span></span>
          </div>
          <div
            className="FATitleCSS"
            style={{
              paddingRight: "20px",
              marginLeft: "20px",
              fontSize: "13px",
            }}
            Only
            if
            enable
            the
            download
            need
            this
            padding
            right
          >
            <a
              href={FilePath}
              target="_blank"
              style={{
                fontWeight: "bold",
                paddingLeft: "0px",
                paddingRight: "20px",
              }}
            >
              {/* <img src={FilePath}></img> */}
              {FileName}
            </a>
            {/* <span>{FileName}</span> */}
          </div>
          <div
            className="FAAttachTitleCSS"
            style={{ marginLeft: "15px", fontSize: "13px" }}
          >
            <span>{FileType}</span>
          </div>
          <div className="downIconCSS">
            {" "}
            <button
              className="buttonDownIconCSS btn btn-sm btn-light fa fa-angle-down"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              // style={{ marginTop: "-40px" }}
            ></button>
            <div className="dropdown-menu dropMenuCSS">
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.onDelete(e, ProjectTaskID, FilePath)}
              >
                Delete Attachment
              </a>
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div
        className="container row WholeRowAttachmentCSS"
        style={{ marginLeft: "0px", marginTop: "0px" }}
      >
        {this.renderTableData()}
      </div>
    );
  }
}
