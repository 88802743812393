import React, { useState, useRef } from "react";
import {
  FILES_API_URL,
  FILES_BASE_URL,
  DEFAULT_IMAGE,
} from "../../components/ConstantAPI";
import queryString from "query-string";
import { GetUserInfoByGUID, UpdateFirstSignup } from "../../api/UserAPI";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../custom.css";
import moment from "moment";
import {
  textValidation,
  passwordValidation,
} from "../../components/Validation";
import {
  mySwalAlertFailed,
  mySwalAlertSuccess,
  massageFileName,
} from "../../components/Misc";
import { useHistory } from "react-router-dom";
import { useAuth, setLocalRole } from "../../context/AuthContext";
import Datetime from "react-datetime";

function FirstSignUp(props) {
  const { setAuthTokens } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [alias, setAlias] = useState("");
  const [course, setCourse] = useState("");
  const [college, setCollege] = useState("");
  const [interest, setInterest] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDOB] = useState("");
  const [team, setTeam] = React.useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [onboardingDate, setOnboardingDate] = useState(new Date());
  const [EmpStatus, setEmpStatus] = React.useState("");
  const [nameInvalid, setNameInvalid] = React.useState("");
  const [passwordInvalid, setPasswordInvalid] = React.useState("");
  const [aliasInvalid, setAliasInvalid] = React.useState("");
  const [dobInvalid, setDOBInvalid] = React.useState("");
  const [courseInvalid, setCourseInvalid] = React.useState("");
  const [collegeInvalid, setCollegeInvalid] = React.useState("");
  const [phoneInvalid, setPhoneInvalid] = React.useState("");
  const [interestInvalid, setInterestInvalid] = React.useState("");
  const [addressInvalid, setAddressInvalid] = React.useState("");
  const [imageInvalid, setImageInvalid] = React.useState("");
  const [picImage, setPicImage] = React.useState(null);
  const [picObject, setPicObject] = React.useState(null);
  let history = useHistory();
  const passRef = useRef();
  const hiddenFileInput = React.useRef(null);

  React.useEffect(() => {
    let params = queryString.parse(window.location.search);
    let guid = params.guid;

    passRef.current.focus(); //to make it auto focus on page load

    GetUserInfoByGUID(doGetUserInfoByGUIDCB.bind(this), {
      guid: guid,
    });
  }, []);

  function doGetUserInfoByGUIDCB(jsonObj) {
    if (jsonObj.message === "Success") {
      if (jsonObj.password !== "") {
        setAuthTokens();
        setLocalRole("undefined");
        mySwalAlertFailed("You have already done the first time sign up.");
        history.push("/");
      }

      // console.log("----");
      // console.log(jsonObj.photo);
      setUsername(jsonObj.username);
      setName(jsonObj.name);
      setEmail(jsonObj.email);
      setPhone(jsonObj.phone);
      setOnboardingDate(moment(jsonObj.onBoardingDate).format("DD/MM/yyyy")); //to display as string, we use this format
      setTeam(jsonObj.team);
      setJobTitle(jsonObj.jobTitle);
      setEmpStatus(jsonObj.employmentStatus);
      setAlias(jsonObj.alias);
      setDOB(jsonObj.dob === null ? "" : moment(jsonObj.dob).toDate());

      setCourse(jsonObj.course);
      setCollege(jsonObj.college);

      setAddress(jsonObj.address);
      setPicImage(
        jsonObj.photo !== undefined && jsonObj.photo !== ""
          ? FILES_API_URL + jsonObj.photo
          : ""
      );
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function OnSave() {
    var dobDT = moment(dob).format("MM/DD/yyyy"); //to set datepicker, need use this format + DB take this format
    var nameInv = textValidation("Name", name, 3);
    var aliasInv = textValidation("Preferred Name", alias, 1);
    var dobInv = dobDT === "Invalid date" ? "Invalid date" : null;
    var courseInv = textValidation("Course", course, 1);
    var collegeInv = textValidation("College", college, 1);
    var addressInv = textValidation("Residential Address", address, 1);
    var passInv = passwordValidation(
      "Password",
      "Confirm Password",
      password,
      confirmPassword,
      1
    );

    if (picObject === null && picImage === "") {
      setImageInvalid("Photo is mandatory.");
    }

    setNameInvalid(nameInv);
    setAliasInvalid(aliasInv);
    setDOBInvalid(dobInv);
    setCourseInvalid(courseInv);
    setCollegeInvalid(collegeInv);
    setAddressInvalid(addressInv);
    setPasswordInvalid(passInv);

    if (
      nameInv === null &&
      aliasInv === null &&
      dobInv === null &&
      courseInv === null &&
      collegeInv === null &&
      addressInv === null &&
      passInv === null
    ) {
      if (picObject !== null) {
        uploadDroppedFilesToServer(picObject, name);
      } else if (picImage !== "") {
        UpdateFirstSignup(doUpdateFirstSignupCB.bind(this), {
          Username: username,
          Name: name,
          Phone: phone,
          Alias: alias,
          DOB: dob,
          Course: course,
          College: college,
          Interest: interest,
          Address: address,
          Password: password,
          Photo: "", //when we pass empty string, means we do not want to update the image
        });
      } else {
        setImageInvalid("Photo is mandatory.");
      }
    }
  }

  function uploadDroppedFilesToServer(file) {
    const formData = new FormData();
    formData.append("qqfile", file);
    formData.append("username", username.toLowerCase());
    formData.append("folder", "personal");
    formData.append("subfolder", "");

    fetch(FILES_API_URL + "/users/upload", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        var fullPath =
          "/" +
		  FILES_BASE_URL +
          "/" +
          username.toLowerCase() +
          "/personal/" +
          massageFileName(file.name);

        UpdateFirstSignup(doUpdateFirstSignupCB.bind(this), {
          Username: username,
          Name: name,
          Phone: phone,
          Alias: alias,
          DOB: dob,
          Course: course,
          College: college,
          Interest: "",
          Address: address,
          Password: password,
          Photo: fullPath,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function doUpdateFirstSignupCB(jsonObj) {
    console.log(jsonObj);
    if (jsonObj.queryMessage === "Success") {
      mySwalAlertSuccess("Update Successfully.").then(() => {
        setAuthTokens();
        setLocalRole("undefined");
        history.push("/");
      });
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  function showImage(e) {
    setPicImage(URL.createObjectURL(e.target.files[0]));
    setPicObject(e.target.files[0]);
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <div className="row clearfix marginleftrightzero">
      <div className="col-md-3"></div>
      <div className="col-md-6">
        <div className="card">
          <div className="header">
            <h1>Getting Started</h1>
            <h2>Welcome to Brainy Entertainment! </h2>
          </div>
          <hr />
          <div className="body">
            <div className="row clearfix">
              <div className="col-md-12">
                <div class="form-group">
                  <Form.Label>Employee ID</Form.Label>
                  <Form.Control
                    placeholder="Employee ID"
                    value={username}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div class="form-group">
                  <Form.Label>Email (*Login)</Form.Label>
                  <Form.Control placeholder="Email" value={email} disabled />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <Form.Label>Profile Photo</Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  <br />
                  <div className="flex-containerProfile">
                    <div>
                      <img
                        src={picImage}
                        style={{ width: 200 }}
                        onError={(e) => (
                          (e.target.onerror = null),
                          (e.target.src = DEFAULT_IMAGE),
                          (e.target.width = 200)
                        )}
                      />
                    </div>
                    <div>
                      <Form.Label style={{ fontSize: "12px", color: "grey" }}>
                        Your photo must be in square mode 1:1 aspect ratio with
                        a resolution of 1000×1000.
                      </Form.Label>
                      <br />
                      <Form.Label style={{ fontSize: "12px", color: "grey" }}>
                        Minimum 500x500.
                      </Form.Label>
                      <br />
                      <Button onClick={handleClick}>Upload Photo</Button>
                    </div>
                  </div>
                  <input
                    type="file"
                    name="file"
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setImageInvalid("");
                      showImage(e);
                    }}
                  />
                </div>
                <span style={{ color: "red" }}>{imageInvalid}</span>
              </div>
              <div className="col-md-12">
                <div class="form-group">
                  <Form.Label>Password</Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  <Form.Control
                    type="password"
                    ref={passRef}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div class="form-group">
                  <Form.Label>Confirm Password</Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                  <span style={{ color: "red" }}>{passwordInvalid}</span>{" "}
                </div>
              </div>
              <div className="col-md-12">
                <div class="form-group">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    placeholder="Name"
                    value={name}
                    onChange={(e) => {
                      setNameInvalid("");
                      setName(e.target.value);
                    }}
                  />
                  <span style={{ color: "red" }}>{nameInvalid}</span>{" "}
                </div>
              </div>
              <div className="col-md-12">
                <div class="form-group">
                  <Form.Label>Team</Form.Label>
                  <Form.Control placeholder="Team" value={team} disabled />
                </div>
              </div>
              <div className="col-md-12">
                <div class="form-group">
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    placeholder="Job Title"
                    value={jobTitle}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div class="form-group">
                  <Form.Label>Preferred Name</Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  <Form.Control
                    placeholder="Preferred Name"
                    value={alias}
                    onChange={(e) => {
                      setAliasInvalid("");
                      setAlias(e.target.value);
                    }}
                  />
                  <span style={{ color: "red" }}>{aliasInvalid}</span>{" "}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <Form.Label>Contact No</Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  <Form.Control
                    placeholder="Contact No"
                    value={phone}
                    onChange={(e) => {
                      setPhoneInvalid("");
                      setPhone(e.target.value);
                    }}
                  />
                  <span style={{ color: "red" }}>{phoneInvalid}</span>
                </div>
              </div>
              <div className="col-md-12">
                <div class="form-group">
                  <Form.Label>Birthday</Form.Label>
                  <Datetime
                    value={dob}
                    dateFormat="DD/MM/yyyy"
                    closeOnSelect
                    placeholderText="eg. 25/11/1985"
                    timeFormat={false}
                    onChange={(e) => {
                      setDOBInvalid();
                      setDOB(e);
                    }}
                  />

                  <span style={{ color: "red" }}>{dobInvalid}</span>
                </div>
              </div>

              <div className="col-md-12">
                <div class="form-group">
                  <Form.Label>College/University</Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  <Form.Control
                    placeholder="College"
                    value={college}
                    onChange={(e) => {
                      setCollegeInvalid("");
                      setCollege(e.target.value);
                    }}
                  />
                  <span style={{ color: "red" }}>{collegeInvalid}</span>{" "}
                </div>
              </div>
              <div className="col-md-12">
                <div class="form-group">
                  <Form.Label>Course of Study</Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  <Form.Control
                    placeholder="Course"
                    value={course}
                    onChange={(e) => {
                      setCourseInvalid("");
                      setCourse(e.target.value);
                    }}
                  />
                  <span style={{ color: "red" }}>{courseInvalid}</span>{" "}
                </div>
              </div>
              <div className="col-md-12">
                <div class="form-group">
                  <Form.Label>Residential Address</Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Residential Address"
                    value={address}
                    onChange={(e) => {
                      setAddressInvalid("");
                      setAddress(e.target.value);
                    }}
                  />
                  <span style={{ color: "red" }}>{addressInvalid}</span>{" "}
                </div>
              </div>
              <div className="col-md-12">
                <div class="form-group">
                  <Form.Label>Onboarding Date</Form.Label>
                  <Form.Control
                    placeholder="Onboarding Date"
                    value={onboardingDate}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div class="form-group">
                  <Form.Label>Employement Status</Form.Label>
                  <Form.Control
                    placeholder="Employement Status"
                    value={EmpStatus}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div class="form-group">
                  <Button
                    className="btn btn-primary"
                    style={{ width: 100 }}
                    onClick={OnSave}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3"></div>
    </div>
  );
}

export default FirstSignUp;
