import { REST_API_URL } from "../../components/ConstantAPI";
import { postObj, postObjAuth } from "../PostObject";

export async function GetAllUsers(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    console.log(REST_API_URL + "/adminsetting/getallusers");
    const response = await fetch(
      REST_API_URL + "/adminsetting/getallusers",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function CreateUser(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/createuser");
    const response = await fetch(
      REST_API_URL + "/adminsetting/createuser",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function UpdateProfile(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/updateprofile");
    const response = await fetch(
      REST_API_URL + "/adminsetting/updateprofile",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function DeleteUser(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/deleteuser");
    const response = await fetch(
      REST_API_URL + "/adminsetting/deleteuser",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function GetAllProjectSetting(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    console.log(REST_API_URL + "/adminsetting/getallprojectstatus");
    const response = await fetch(
      REST_API_URL + "/adminsetting/getallprojectstatus",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function CreateProjectSetting(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/createorupdatestatus");
    const response = await fetch(
      REST_API_URL + "/adminsetting/createorupdatestatus",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function DeleteProjectSetting(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/deletestatus");
    const response = await fetch(
      REST_API_URL + "/adminsetting/deletestatus",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function GetProjectSetting(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/getprojectstatus");
    const response = await fetch(
      REST_API_URL + "/adminsetting/getprojectstatus",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function GetAllTasks(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    console.log(REST_API_URL + "/adminsetting/getalltasks");
    const response = await fetch(
      REST_API_URL + "/adminsetting/getalltasks",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function CreateTask(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/createorupdatetask");
    const response = await fetch(
      REST_API_URL + "/adminsetting/createorupdatetask",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function DeleteTask(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/deletetask");
    const response = await fetch(
      REST_API_URL + "/adminsetting/deletetask",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function GetTask(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/gettask");
    const response = await fetch(
      REST_API_URL + "/adminsetting/gettask",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function GetAllGroups(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    console.log(REST_API_URL + "/adminsetting/getallgroups");
    const response = await fetch(
      REST_API_URL + "/adminsetting/getallgroups",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function CreateGroup(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/createorupdategroup");
    const response = await fetch(
      REST_API_URL + "/adminsetting/createorupdategroup",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function DeleteGroup(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/deletegroup");
    const response = await fetch(
      REST_API_URL + "/adminsetting/deletegroup",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function GetGroup(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/getgroup");
    const response = await fetch(
      REST_API_URL + "/adminsetting/getgroup",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function GetAllChannel(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    console.log(REST_API_URL + "/adminsetting/getallchannel");
    const response = await fetch(
      REST_API_URL + "/adminsetting/getallchannel",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function CreateChannel(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/createorupdatechannel");
    const response = await fetch(
      REST_API_URL + "/adminsetting/createorupdatechannel",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function DeleteChannel(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/deletechannel");
    const response = await fetch(
      REST_API_URL + "/adminsetting/deletechannel",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function GetChannel(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/getchannel");
    const response = await fetch(
      REST_API_URL + "/adminsetting/getchannel",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function ImportPublicHoliday(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/importpublicholiday");
    const response = await fetch(
      REST_API_URL + "/adminsetting/importpublicholiday",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function GetAllPublicHolidayByYear(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    console.log(REST_API_URL + "/adminsetting/getallpublicholidaybyyear");
    const response = await fetch(
      REST_API_URL + "/adminsetting/getallpublicholidaybyyear",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function CreateHoliday(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/createholiday");
    const response = await fetch(
      REST_API_URL + "/adminsetting/createholiday",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function DeleteHoliday(callback: any, params: any, tokenz: any) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    // console.log(newPostObj);
    console.log(REST_API_URL + "/adminsetting/deleteholiday");
    const response = await fetch(
      REST_API_URL + "/adminsetting/deleteholiday",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}
