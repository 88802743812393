// refer here for more information
// https://ej2.syncfusion.com/react/documentation/schedule/editor-template/

import React from "react";
import "./scheduler.css";
import {
  Inject,
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  TimelineViews,
  TimelineMonth,
  DragAndDrop,
  ResourcesDirective,
  ResourceDirective,
  ViewsDirective,
  ViewDirective,
  Resize,
} from "@syncfusion/ej2-react-schedule";
import { UpdateTask } from "../../api/ProjectAPI";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { GetStaffWithTasks } from "../../api/ProjectAPI";
import { getLocalToken, getLocalUsername } from "../../context/AuthContext";
import { mySwalAlertFailed, loadingpage } from "../../components/Misc";
import moment from "moment";

function Scheduler() {
  const [scheduleData, setSchedulerData] = React.useState([]);
  const [employeeData, setEmployeeData] = React.useState([]);
  const [holidayData, setHolidayData] = React.useState([]);
  const [holidayArrayData, setHolidayArrayData] = React.useState([]);
  const [scheduleObject, setScheduleObject] = React.useState();
  const dayAdvance = 3; //this is so that the initial selectedDate is deducted 3 days, so that the timeline will get 3 days before [as roger wanted to see future few days]
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    GetStaffWithTasks(
      doGetStaffWithTaskDetails.bind(this),
      {
        Username: "",
        Status: "",
      },
      getLocalToken()
    );
  }, []);

  //this is to point the schedule to current day. Such a pain to navigate to current date
  React.useEffect(() => {
    const timer = setTimeout(() => {
      // console.log("This will run after 1 second!");
      scheduleObject.selectedDate = new Date(
        moment().subtract(1, "months").subtract(0, "days").format("YYYY"),
        moment().subtract(1, "months").subtract(0, "days").format("MM"),
        moment().subtract(1, "months").subtract(0, "days").format("DD")
      );
    }, 1000);
    return () => clearTimeout(timer);
  }, [scheduleObject]);

  function doGetStaffWithTaskDetails(jsonObj) {
    setIsLoading(false);
    if (jsonObj.message === "Success") {
      let data = [];
      let tempArrTask = JSON.parse(jsonObj.listing);

      tempArrTask.map((element) => {
        let startDate = moment(element.StartDate, "DD/MM/yyyy HH:mm");
        let endDate = moment(element.EndDate, "DD/MM/yyyy HH:mm");

        data.push({
          Id: element.Id,
          Subject:
            element.ProjectRunningNo +
            "-" +
            element.TaskName +
            "(" +
            element.Name +
            ")",
          StartTime: startDate.toDate(),
          EndTime: endDate.toDate(),
          IsAllDay: false,
          Status: "Pending",
          Priority: "High",
          EmployeeId: element.UserId,
        });
      });

      setSchedulerData(data);

      let tempStaffArr = JSON.parse(jsonObj.staffListing);
      let tempData = [];

      tempStaffArr.map((element) => {
        tempData.push({
          Text: element.Name,
          Id: element.Id,
          GroupId: 1,
          Color: "#bbdc00",
          Designation: "",
        });
      });

      setEmployeeData(tempData);

      let tempHoliday = [];
      let tempHolidayDate = [];
      let tempArrHoliday = JSON.parse(jsonObj.holidayListing);

      tempArrHoliday.map((element) => {
        let tempDateString = moment(
          element.HolidayDate,
          "DD/MM/yyyy HH:mm"
        ).toDate();

        tempHolidayDate.push(convertDateToString(tempDateString));

        tempHoliday.push({
          HolidayDate: convertDateToString(tempDateString),
          HolidayName: element.Name,
        });
      });

      setHolidayArrayData(tempHolidayDate);
      setHolidayData(tempHoliday);
    } else {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function convertDateToString(date) {
    return moment(date, "DD/MM/yyyy").toString();
  }

  function editorTemplate(props) {
    return props !== undefined ? (
      <table className="custom-event-editor" style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td className="e-textlabel">Summary</td>
            <td colSpan={4}>
              <input
                id="Summary"
                className="e-field e-input"
                type="text"
                name="Subject"
                style={{ width: "100%" }}
                disabled={true}
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">From</td>
            <td colSpan={4}>
              <DateTimePickerComponent
                format="dd/MM/yy hh:mm a"
                id="StartTime"
                data-name="StartTime"
                value={new Date(props.startTime || props.StartTime)}
                className="e-field"
              ></DateTimePickerComponent>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">To</td>
            <td colSpan={4}>
              <DateTimePickerComponent
                format="dd/MM/yy hh:mm a"
                id="EndTime"
                data-name="EndTime"
                value={new Date(props.endTime || props.EndTime)}
                className="e-field"
              ></DateTimePickerComponent>
            </td>
          </tr>
        </tbody>
      </table>
    ) : (
      ""
    );
  }

  function doNothingCallBack(jsonObj) {
    if (jsonObj.message !== "Success") {
      mySwalAlertFailed(jsonObj.message);
    }
  }

  function UpdateData(taskId, section, value) {
    UpdateTask(
      doNothingCallBack.bind(this),
      {
        id: taskId,
        Section: section,
        Value: value,
        UpdatedBy: getLocalUsername(),
      },
      getLocalToken()
    );
  }

  function onActionBegin(e) {
    if (e.requestType === "eventChange") {
      let record = e.changedRecords[0];

      UpdateData(
        record.Id,
        "startdate",
        moment(record.StartTime).format("MM/DD/yyyy HH:mm")
      );

      UpdateData(
        e.data.Id,
        "duedate",
        moment(record.EndTime).format("MM/DD/yyyy HH:mm")
      );
    }
  }

  function onDragStop(e) {
    UpdateData(
      e.data.Id,
      "startdate",
      moment(e.data.StartTime).format("MM/DD/yyyy HH:mm")
    );

    UpdateData(
      e.data.Id,
      "duedate",
      moment(e.data.EndTime).format("MM/DD/yyyy HH:mm")
    );
  }

  function getEmployeeName(value) {
    return value.resourceData[value.resource.textField];
  }

  function resourceHeaderTemplate(props) {
    return (
      <div className="template-wrap">
        <div className="employee-category">
          <div className="employee-name">{getEmployeeName(props)}</div>
        </div>
      </div>
    );
  }

  function getMonthCellContent(date) {
    let dateObject = searchElement(date);
    if (dateObject === true) {
      let holidayName = holidayData.find((element) => {
        return element.HolidayDate === convertDateToString(date);
      });

      return "<label>" + holidayName.HolidayName + "</label>";
    }

    return "";
  }

  function getWorkCellText(date) {
    let dateObject = searchElement(date);
    if (dateObject === true) {
      let holidayName = holidayData.find((element) => {
        return element.HolidayDate === convertDateToString(date);
      });

      return "<label>" + holidayName.HolidayName + "</label>";
    }

    return "";
  }

  function searchElement(currentDate) {
    return holidayArrayData.indexOf(convertDateToString(currentDate)) > -1;
  }

  function cellTemplate(props) {
    if (props.type === "workCells") {
      return (
        <div
          className="templatewrap"
          dangerouslySetInnerHTML={{ __html: getWorkCellText(props.date) }}
        ></div>
      );
    }
    if (props.type === "monthCells") {
      return (
        <div
          className="templatewrap"
          dangerouslySetInnerHTML={{ __html: getMonthCellContent(props.date) }}
        ></div>
      );
    }
    return <div></div>;
  }

  function onRenderCell(args) {
    let holidayName = holidayData.find((element) => {
      return (
        moment(element.HolidayDate).format("DD/MM/yyyy") ===
        moment(args.date).format("DD/MM/yyyy")
      );
    });

    if (holidayName !== undefined) {
      if (args.elementType === "workCells") {
        args.element.classList.add("e-public-holiday");
      }
    }
  }

  //this doesn't work, it will crash. Unable to do scrollTo
  function onCreated() {
    console.log(new Date());
    // scheduleObject.scrollTo(
    //   this.instance.formatDate(new Date(), { skeleton: "hm" })
    // );
  }

  return (
    <div id="main-content">
      {/* {isLoading ? loadingpage() : ""} */}
      <div className="block-header">
        <div className="row">
          <div
            className="col-lg-6 col-md-8 col-sm-12"
            style={{ marginLeft: "15px" }}
          >
            <h2>Project Management</h2>
          </div>
        </div>
      </div>
      <div className="schedule-control-section">
        <div className="col-lg-12 control-section">
          <div className="control-wrapper drag-sample-wrapper">
            <div className="schedule-container">
              <ScheduleComponent
                height="700px"
                width="100%"
                ref={(schedule) => setScheduleObject(schedule)}
                //used to determine want to minimize the bar or not
                //timeScale={{ enable: true }}
                editorTemplate={(e) => editorTemplate(e)}
                actionBegin={(e) => onActionBegin(e)}
                dragStop={(e) => onDragStop(e)}
                cssClass="block-events"
                eventSettings={{
                  allowAdding: false,
                  //enableTooltip: true,
                  allowEditing: true,
                  allowDeleting: false,
                  dataSource: scheduleData,
                }}
                //just to highlight working hour 9-6pm
                workHours={{
                  highlight: true,
                  start: "9:00",
                  end: "18:30",
                }}
                //doesn't work for now, unable to do 'scrollTo'
                created={onCreated.bind(this)}
                //by default it will default sunday as first day of week. Set 1 to make it monday as first day of week
                firstDayOfWeek={1}
                currentView="Week"
                renderCell={(e) => onRenderCell(e)}
                // show the holiday text
                // cellTemplate={(e) => cellTemplate(e)}
                resourceHeaderTemplate={resourceHeaderTemplate.bind(this)}
                group={{ enableCompactView: false, resources: ["Employee"] }}
                selectedDate={
                  new Date(
                    moment()
                      .subtract(1, "months")
                      .subtract(dayAdvance, "days")
                      .format("YYYY"),
                    moment()
                      .subtract(1, "months")
                      .subtract(dayAdvance, "days")
                      .format("MM"),
                    moment()
                      .subtract(1, "months")
                      .subtract(dayAdvance, "days")
                      .format("DD")
                  )
                }
                // selectedDate={new Date(2017, 5, 5)}//Put this if you want to navigate to certain day. The month is +1, meaning 10 is equivalent to November
              >
                {/* This ViewsDirective will customize the views. But you will still need Inject > services > Day Week etc */}
                <ResourcesDirective>
                  <ResourceDirective
                    field="EmployeeId"
                    title="Employees"
                    name="Employee"
                    allowMultiple={true}
                    dataSource={employeeData}
                    textField="Text"
                    idField="Id"
                    colorField="Color"
                  ></ResourceDirective>
                </ResourcesDirective>
                <ViewsDirective>
                  <ViewDirective
                    option="TimelineWeek"
                    timeScale={{ enable: true, interval: 180, slotCount: 3 }}
                    interval={2}
                  />
                </ViewsDirective>
                <Inject
                  services={[
                    Day,
                    Week,
                    WorkWeek,
                    Month,
                    TimelineViews,
                    TimelineMonth,
                    Resize,
                    DragAndDrop,
                  ]}
                />
              </ScheduleComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Scheduler;
