import React from "react";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import { couldStartTrivia } from "typescript";

function NotificationPopUp(props: any) {
  return (
    <div>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>Notification</Modal.Title>
          {props.icon}
        </Modal.Header>
        <Modal.Body>
          <div className="row clearfix">
            <div className="col-md-12">
              <div className="form-group">
                <Form.Label>{props.notiContent}</Form.Label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NotificationPopUp;
