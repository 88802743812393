import React, { useState, useCallback } from "react";
import { Redirect } from "react-router-dom";
import debounce from "lodash/debounce";
import ResultList from "./resultList";
import { GetAllProjectListing } from "../../../api/ProjectAPI";
import { GetAllUsers } from "../../../api/AdminAPI";
import { getLocalToken } from "../../../context/AuthContext";
import { mySwalAlertFailed } from "../../../components/Misc";

function SearchBar(props) {
  const [search, setSearch] = useState("");
  const [allProjectList, setAllProjectList] = useState([]);
  const [allMemberList, setAllMemberList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [showResult, setShowResult] = React.useState(true);
  const [isSearchingViaAPI, setIsSearchingViaAPI] = React.useState(false);
  const [picPath, setPicPath] = React.useState("");

  function handleShowResult(show) {
    setShowResult(show);
  }

  React.useEffect(() => {
    // GetAllProject(false, "");
    GetAllMember(); //to query the member
  }, []);

  function GetAllProject(isSearching, searchText, allMemberListParam) {
    //only trigger search if not searching
    if (!isSearchingViaAPI) {
      console.log("Searching via API");
      setIsSearchingViaAPI(true);

      GetAllProjectListing(
        doGetDataCB.bind(this),
        {
          SelectedPage: -1,
        },
        getLocalToken(),
        isSearching,
        searchText,
        allMemberListParam
      );
    } else {
      console.log("Previous searching via API already in progress");
    }
  }

  function doGetDataCB(jsonObj, isSearching, searchText, allMemberListParam) {
    if (jsonObj.queryMessage === "Success") {
      console.log("Search via API data retrieved");
      let temp = JSON.parse(jsonObj.listing);
      setAllProjectList(temp);
      if (isSearching && searchText != "") {
        const filtered = temp.filter((query) => {
          return query.ProjectName.toLowerCase().includes(
            searchText.toLowerCase()
          );
        });

        const filteredMember = allMemberListParam.filter((query) => {
          return query.Alias.toLowerCase().includes(searchText.toLowerCase());
        });

        // console.log(filteredMember);

        setProjectList(filtered);
        setMemberList(filteredMember);
      }
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
    setIsSearchingViaAPI(false);
  }

  function GetAllMember() {
    GetAllUsers(
      doGetDataAllMemberCB.bind(this),
      {
        FilterUsername: "",
        SelectedPage: -1,
      },
      getLocalToken()
    );
  }

  function doGetDataAllMemberCB(jsonObj) {
    if (jsonObj.queryMessage === "Success") {
      let temp = JSON.parse(jsonObj.listing);
      setAllMemberList(temp);
    } else {
      mySwalAlertFailed(jsonObj.queryMessage);
    }
  }

  const BarStyling = {
    width: "20rem",
    background: "#F2F1F9",
    border: "none",
    padding: "0.5rem",
  };

  const setKeyword = (text) => {
    setSearch(text);
    delayedQuery(text, allProjectList, allMemberList);
  };

  const delayedQuery = useCallback(
    debounce(
      (q, list, allMemberListParam) => sendQuery(q, list, allMemberListParam),
      1000
    ),
    []
  );

  const sendQuery = (input, list, allMemberListParam) => {
    if (input === "") {
      setProjectList([]);
      setMemberList([]);
    } else if (list !== null && list !== "undefined" && list !== undefined) {
      GetAllProject(true, input, allMemberListParam);
    }
  };

  return (
    <div>
      <input
        // style={BarStyling}
        className="form-control"
        placeholder="Search here..."
        type="text"
        style={{ width: "218px" }}
        key="random1"
        value={search}
        onChange={(e) => setKeyword(e.target.value)}
        onFocus={(e) => setShowResult(true)}
        onBlur={(e) => {
          //last time use onblur, cannot click on the child's <li> to navigate, now duno why can. If future cannot just comment out this. But one problem is that if we click other side, the result still showing
          setShowResult(false);
        }}
      />
      <ResultList
        resultList={projectList}
        showResult={showResult}
        memberList={memberList}
        onShowResult={handleShowResult}
      />
    </div>
  );
}

export default SearchBar;
