import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  AuthContext,
  getLocalToken,
  setLocalToken,
  getLocalRole,
  setLocalRole,
  getLocalIsRefresh,
  setLocalIsRefresh,
  getLocalHolidays,
  setLocalHolidays,
} from "./context/AuthContext";
import PrivateRoute from "./context/PrivateRoute";
import Menu from "./views/User/Menu";
import TopHeader from "./views/User/TopHeader";
import Welcome from "./views/Welcome";
import FirstSignUp from "./views/FirstSignUp";
import Dashboard from "./views/User/Dashboard";
import Profile from "./views/User/Profile";
import Employee from "./views/User/Employee";
import Notification from "./views/User/Notification";
import ProjectStatus from "./views/AdminSettings/ProjectStatus";
import Task from "./views/AdminSettings/Task";
import Group from "./views/AdminSettings/Group";
import Project from "./views/User/Project";
import Channel from "./views/AdminSettings/Channel";
import ProjectLog from "./views/User/ProjectLog";
import SendMessage from "./views/User/SendMessage";
import NewProject from "./views/Projects/New";
import ProjectDetails from "./views/Projects/Details";
import UserProjectDetails from "./views/Projects/UserProjectDetails";
import PublicHoliday from "./views/AdminSettings/PublicHoliday";
import Scheduler from "./views/Scheduler";
import Temp from "./views/Projects/Temp";
import BottomBar from "./views/User/BottomBar";
import ForgetPassword from "./views/ForgetPassword";
import ResetPassword from "./views/ResetPassword";

function App() {
  const [authTokens, setAuthTokens] = useState(getLocalToken());
  const [isRefresh, setIsRefresh] = useState(getLocalIsRefresh());
  const [holidays, setArrHolidays] = useState(getLocalHolidays());
  const [projectTaskID, setProjectTaskID] = useState(0);

  //need create a state for the role so that when we change role, React will change the UI
  const existingRole = getLocalRole();
  const [authRole, setAuthRole] = useState(existingRole); //initialize the state based on the localRole

  const setTokens = (data: any) => {
    setLocalToken(data);
    setAuthTokens(data);
  };

  const setRefresh = (data: any) => {
    setIsRefresh(data);
    setLocalIsRefresh(data);
  };

  const setHolidays = (data: any) => {
    setArrHolidays(data);
    setLocalHolidays(data);
  };

  const setProjectTaskIDFunc = (data: any) => {
    setProjectTaskID(data);
  };

  const setRole = (data: any) => {
    setLocalRole(data);
    setAuthRole(data);
  };

  function isUserLoggedIn() {
    if (
      getLocalRole() !== "undefined" &&
      getLocalRole() !== null &&
      window.location.pathname !== "/firstsignup"
    ) {
      return (
        <div>
          <Menu />
          <TopHeader />
          <BottomBar />
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <AuthContext.Provider
      value={{
        authTokens,
        setAuthTokens: setTokens,
        isRefresh,
        setIsRefresh: setRefresh,
        holidays,
        setHolidaysMain: setHolidays,
        projectTaskID,
        setProjectTaskIDMain: setProjectTaskID,
        authRole,
        setAuthRole: setRole,
      }}
    >
      <Router>
        {isUserLoggedIn()}
        <div>
          <Switch>
            <Route exact path="/" component={Welcome} />
            <Route exact path="/firstsignup" component={FirstSignUp} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/employee" component={Employee} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/task" component={Task} />
            <PrivateRoute exact path="/group" component={Group} />
            <PrivateRoute exact path="/channel" component={Channel} />
            <PrivateRoute exact path="/scheduler" component={Scheduler} />
            <Route exact path="/forgetpassword" component={ForgetPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <PrivateRoute
              exact
              path="/projectstatus"
              component={ProjectStatus}
            />
            <PrivateRoute
              exact
              path="/publicholiday"
              component={PublicHoliday}
            />
            <PrivateRoute exact path="/notification" component={Notification} />
            <PrivateRoute exact path="/project" component={Project} />
            <PrivateRoute exact path="/projectlog" component={ProjectLog} />
            <PrivateRoute exact path="/sendmessage" component={SendMessage} />
            <PrivateRoute exact path="/newproject" component={NewProject} />
            <PrivateRoute exact path="/temp" component={Temp} />
            <PrivateRoute
              exact
              path="/projectdetails"
              component={ProjectDetails}
            />
            <PrivateRoute
              exact
              path="/userprojectdetails"
              component={UserProjectDetails}
            />
          </Switch>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
