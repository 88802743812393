import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SetNotificationReadByUsernameWithCB } from "../api/UserAPI";
import moment from "moment";
import { createNoSubstitutionTemplateLiteral } from "typescript";
import {
  useAuth,
  setLocalRole,
  getLocalRole,
  getLocalToken,
} from "../context/AuthContext";

const MySwal = withReactContent(Swal);

export const mySwalAlertFailed = (fieldMessage) => {
  return MySwal.fire({
    title: "Failed!",
    text: fieldMessage,
    type: "warning",
    confirmButtonText: "OK",
  });
};

export const mySwalAlertSuccess = (fieldMessage) => {
  return MySwal.fire({
    title: "Success.",
    text: fieldMessage,
    type: "success",
    confirmButtonText: "OK",
  });
};

export const baseURL = () => {
  var path = window.location.protocol + "//" + window.location.host;
  return path;
};

export const getArrProjectStatus = () => {
  let arr = [];

  arr.push({ value: "-1", label: "All" });
  arr.push({ value: "1", label: "Incomplete" });
  arr.push({ value: "2", label: "KIV" });
  arr.push({ value: "3", label: "Completed" });

  return arr;
};

export const getArrRoles = () => {
  let arr = [];

  arr.push({ value: "3", label: "Employee" });
  arr.push({ value: "2", label: "Project Manager" });
  arr.push({ value: "1", label: "Admin" });

  return arr;
};

export const getArrTeams = () => {
  let arr = [];

  arr.push({ value: "Management", label: "Management" });
  arr.push({ value: "Illustrator", label: "Illustrator" });
  arr.push({ value: "Video Editor", label: "Video Editor" });
  arr.push({ value: "Script Editor", label: "Script Editor" });
  arr.push({ value: "Voice Actor", label: "Voice Actor" });
  arr.push({ value: "Other", label: "Other" });

  return arr;
};

export const getArrEmploymentStatus = () => {
  let arr = [];

  arr.push({ value: "Under Probation", label: "Under Probation" });
  arr.push({ value: "Confirmed", label: "Confirmed" });
  arr.push({ value: "Resigned", label: "Resigned" });

  return arr;
};

export const getTaskDetail = (field) => {
  if (field === "TAG") return "Tags";

  if (field === "TASKASSIGN") return "Task Assigned";

  if (field === "REMINDER") return "Reminder";
};

export const mySwalNotification = (fieldMessage, field) => {
  return MySwal.fire({
    title: "Notification.",
    text: fieldMessage,
    type: "success",
    confirmButtonText: "OK",
  });
};

export const RenderIcon = (type) => {
  if (type === "TAG") {
    return <i className="icon-tag text-warning"></i>;
  }

  if (type === "TASKASSIGN") {
    return <i className="icon-note text-warning"></i>;
  }

  //REMINDER
  return <i className="icon-info text-warning"></i>;
};

export const SetNotificationReadWithCB = (id, username, token, funcCB) => {
  SetNotificationReadByUsernameWithCB(
    doGetDataCB,
    {
      NotificationID: id,
      Username: username,
    },
    token,
    funcCB
  );

  function doGetDataCB(jsonObj, funcCB) {
    if (jsonObj.queryMessage === "Success") {
      console.log(jsonObj);
      funcCB();
    } else {
      // mySwalAlertFailed(jsonObj.queryMessage);
    }
  }
};

export const GetPeriodString = (dt) => {
  var dtMoment = moment(dt);

  return dtMoment.format("DD-MM-YYYY HH:mm:ss");

  var createdHMS = dtMoment.format("LT"); //dtMoment.format("LTS"); //before reset the hour min sec to 00:00:00, get the time first

  dtMoment = dtMoment.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  var now = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  var createdXDayAgo = now.diff(dtMoment, "days");

  var periodString = `Today at ${createdHMS} `;

  if (now.format("MM-DD-YYYY") !== dtMoment.format("MM-DD-YYYY")) {
    if (createdXDayAgo == 1) {
      periodString = `Yesterday at ${createdHMS} `;
    } else {
      periodString = `${createdXDayAgo} days ago`;
    }
  }

  return periodString === "1 days ago" ? "Yesterday" : periodString;
};

//mainly is for react-datepicker [for disabling certain date]
export const getWeekendDate = () => {
  var start = moment("2020-01-01"),
    end = moment("2022-01-01"),
    day = 0; // Sunday

  let arr = [];
  var current = start.clone();

  //first handle for sunday
  while (current.day(7 + day).isBefore(end)) {
    arr.push(current.clone().valueOf());
  }

  //handle for saturday
  day = 6;
  current = start.clone();
  while (current.day(7 + day).isBefore(end)) {
    arr.push(current.clone().valueOf());
  }

  return arr;
};

export const getTimeArr = () => {
  let arr = [];

  arr.push({ value: "09:30", label: "9:30AM" });
  arr.push({ value: "10:00", label: "10:00AM" });
  arr.push({ value: "10:30", label: "10:30AM" });
  arr.push({ value: "11:00", label: "11:00AM" });
  arr.push({ value: "11:30", label: "11:30AM" });
  arr.push({ value: "12:00", label: "12:00PM" });
  arr.push({ value: "12:30", label: "12:30PM" });
  arr.push({ value: "13:00", label: "1:00PM" });
  arr.push({ value: "13:30", label: "1:30PM" });
  arr.push({ value: "14:00", label: "2:00PM" });
  arr.push({ value: "14:30", label: "2:30PM" });
  arr.push({ value: "15:00", label: "3:00PM" });
  arr.push({ value: "15:30", label: "3:30PM" });
  arr.push({ value: "16:00", label: "4:00PM" });
  arr.push({ value: "16:30", label: "4:30PM" });
  arr.push({ value: "17:00", label: "5:00PM" });
  arr.push({ value: "17:30", label: "5:30PM" });
  arr.push({ value: "18:00", label: "6:00PM" });
  arr.push({ value: "18:30", label: "6:30PM" });

  return arr;
};

export const massageFileName = (field) => {
  field = field.replace(".srt", ".zip");
  return field.replace(/[^0-9a-zA-Z-.,_]/gi, "_");
};

export const loadingpageLogin = () => {
  return (
    <div className="page-loader-wrapper">
      <div className="loader">
        <div className="m-t-30">
          <img
            src="../assets/images/BES_Loading_02.gif"
            width="250"
            height="250"
            alt="Brainy"
          />
        </div>
      </div>
    </div>
  );
};

export const loadingpage = () => {
  return (
    <div class="page-loader-wrapper" style={{ backgroundColor: "transparent" }}>
      <div class="loader">
        <div class="m-t-30">
          <img src="../assets/images/loading.gif" width="120" alt="Lucid" />
        </div>
        <p style={{ fontSize: "30px", color: "red", fontWeight: "bold" }}>
          Please wait...
        </p>
      </div>
    </div>
  );
};
