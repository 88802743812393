export const postObj = {
  method: "POST",
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT",
    "Access-Control-Allow-Headers": "Content-Type",
  },
  body: {},
};

export const postObjAuth = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer `,
    Accept: "application/json",
  },
  body: {},
};
