import { REST_API_URL } from "../../components/ConstantAPI";
import { postObj, postObjAuth } from "../PostObject";

export async function login(callback: any, params: any) {
  const newPostObj = { ...postObj, body: JSON.stringify(params) };

  console.log(REST_API_URL + "/users/login");
  await fetch(REST_API_URL + "/users/login", newPostObj)
    .then(function (res) {
      return res.json(); //need to return this json() then only it will go to the next .then
    })
    .then((json) => {
      callback(json);
    })
    .catch(function (error) {
      callback(error);
    });
}

export async function GetUserInfoByUsername(
  callback: any,
  params: any,
  tokenz: any
) {
  const newPostObj = { ...postObjAuth, body: JSON.stringify(params) };
  newPostObj.headers.Authorization = `Bearer ${tokenz}`;

  console.log(REST_API_URL + "/users/getuserinfobyusername");
  const response = await fetch(
    REST_API_URL + "/users/getuserinfobyusername",
    newPostObj
  );

  const json = await response.json();
  callback(json);
}

export async function GetUserInfoByGUID(callback: any, params: any) {
  const newPostObj = { ...postObj, body: JSON.stringify(params) };

  console.log(REST_API_URL + "/users/getuserinfobyguid");
  const response = await fetch(
    REST_API_URL + "/users/getuserinfobyguid",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function UpdateFirstSignup(callback: any, params: any) {
  const newPostObj = { ...postObj, body: JSON.stringify(params) };

  console.log(REST_API_URL + "/adminsetting/updatefirstsignup");
  const response = await fetch(
    REST_API_URL + "/adminsetting/updatefirstsignup",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function InsertNotification(
  callback: any,
  params: any,
  tokenz: any
) {
  const newPostObj = { ...postObjAuth, body: JSON.stringify(params) };
  newPostObj.headers.Authorization = `Bearer ${tokenz}`;

  console.log(REST_API_URL + "/users/insertnotification");
  const response = await fetch(
    REST_API_URL + "/users/insertnotification",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function GetTop5NotificationByUsername(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    console.log(REST_API_URL + "/users/gettop5notibyusername");
    const response = await fetch(
      REST_API_URL + "/users/gettop5notibyusername",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function DeleteNotification(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    console.log(REST_API_URL + "/adminsetting/deletenotification");
    const response = await fetch(
      REST_API_URL + "/adminsetting/deletenotification",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function GetAllNotificationByUsername(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    console.log(REST_API_URL + "/users/getallnotibyusername");
    const response = await fetch(
      REST_API_URL + "/users/getallnotibyusername",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function SetNotificationReadByUsernameWithCB(
  callback: any,
  params: any,
  tokenz: any,
  funcCB: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;
    console.log(newPostObj);
    console.log(REST_API_URL + "/users/setnotificationread");
    const response = await fetch(
      REST_API_URL + "/users/setnotificationread",
      newPostObj
    );
    const json = await response.json();
    callback(json, funcCB);
  } catch (err) {
    Error(err);
  }
}

export async function InsertProjectLog(
  callback: any,
  params: any,
  tokenz: any
) {
  const newPostObj = { ...postObjAuth, body: JSON.stringify(params) };
  newPostObj.headers.Authorization = `Bearer ${tokenz}`;

  console.log(REST_API_URL + "/users/insertprojectlog");
  const response = await fetch(
    REST_API_URL + "/users/insertprojectlog",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function GetAllProjectLogByProjectCode(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    console.log(REST_API_URL + "/users/getallprojectlogbyprojectcode");
    const response = await fetch(
      REST_API_URL + "/users/getallprojectlogbyprojectcode",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function UploadImage(
  callback: any,
  params: FormData,
  tokenz: any
) {
  const newPostObj = { ...postObj, body: params };
  console.log(REST_API_URL + "/users/upload");
  const response = await fetch(REST_API_URL + "/users/upload", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function GetMentionName(
  callback: any,
  params: any,
  tokenz: any,
  value: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    console.log(REST_API_URL + "/users/getmentionname");
    const response = await fetch(
      REST_API_URL + "/users/getmentionname",
      newPostObj
    );
    const json = await response.json();
    callback(json, value);
  } catch (err) {
    Error(err);
  }
}

export async function InsertMessage(callback: any, params: any, tokenz: any) {
  const newPostObj = { ...postObjAuth, body: JSON.stringify(params) };
  newPostObj.headers.Authorization = `Bearer ${tokenz}`;

  console.log(REST_API_URL + "/users/insertmessage");
  const response = await fetch(
    REST_API_URL + "/users/insertmessage",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function GetAllMessageByProjectID(
  callback: any,
  params: any,
  tokenz: any,
  errorCB: any,
  countz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    // if(countz >= 3){
    //   throw new Error('Purposely');
    // }

    // console.log(REST_API_URL + "/users/getallmessagebyprojectid");
    const response = await fetch(
      REST_API_URL + "/users/getallmessagebyprojectid",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    errorCB();
    Error(err);    
  }
}

export async function GetAllPinMessageByProjectID(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    // console.log(REST_API_URL + "/users/getallpinmessagebyprojectid");
    const response = await fetch(
      REST_API_URL + "/users/getallpinmessagebyprojectid",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function ForgetPasswordMethod(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    console.log(REST_API_URL + "/users/resetpassword");
    const response = await fetch(
      REST_API_URL + "/users/resetpassword",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function ResetPasswordMethod(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    console.log(REST_API_URL + "/users/verifyPasswordCode");
    const response = await fetch(
      REST_API_URL + "/users/verifyPasswordCode",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function UpdateNewPassword(
  callback: any,
  params: any,
  tokenz: any
) {
  try {
    const newPostObj = {
      ...postObjAuth,
      body: JSON.stringify(params),
      // header: headers,
    };
    newPostObj.headers.Authorization = `Bearer ${tokenz}`;

    console.log(REST_API_URL + "/users/UpdateNewPassword");
    const response = await fetch(
      REST_API_URL + "/users/UpdateNewPassword",
      newPostObj
    );
    const json = await response.json();
    callback(json);
  } catch (err) {
    Error(err);
  }
}

export async function PinMessage(callback: any, params: any, tokenz: any) {
  const newPostObj = { ...postObjAuth, body: JSON.stringify(params) };
  newPostObj.headers.Authorization = `Bearer ${tokenz}`;

  console.log(REST_API_URL + "/users/pinmessage");
  const response = await fetch(REST_API_URL + "/users/pinmessage", newPostObj);
  const json = await response.json();
  callback(json);
}

export async function DeleteMessage(callback: any, params: any, tokenz: any) {
  const newPostObj = { ...postObjAuth, body: JSON.stringify(params) };
  newPostObj.headers.Authorization = `Bearer ${tokenz}`;

  console.log(REST_API_URL + "/users/deletemessage");
  const response = await fetch(
    REST_API_URL + "/users/deletemessage",
    newPostObj
  );
  const json = await response.json();
  callback(json);
}

export async function CheckUploadImageNameConflict(
  callback: any,
  params: any,
  tokenz: any,
  fullPath: any,
  formData: any,
  isImage: any
) {
  const newPostObj = { ...postObjAuth, body: JSON.stringify(params) };
  newPostObj.headers.Authorization = `Bearer ${tokenz}`;

  console.log(REST_API_URL + "/users/checkuploadimagenameconflict");
  const response = await fetch(
    REST_API_URL + "/users/checkuploadimagenameconflict",
    newPostObj
  );
  const json = await response.json();
  callback(json, fullPath, formData, isImage);
}
